import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { activateAction, reset } from './activate.reducer';
import { Button, Stack, Box, AlertIcon, Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';
import LoadingSpinner from 'app/shared/components/loading-spinner';

const successAlert = (
  <Alert
    status="success"
    variant="subtle"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    height="200px"
  >
    <AlertIcon boxSize="40px" mr={0} />
    <AlertTitle mt={4} mb={1} fontSize="lg">
      Account activated!
    </AlertTitle>
    <AlertDescription maxWidth="sm">Your account has been activated, please sign in below.</AlertDescription>
  </Alert>
);

const failureAlert = (
  <Alert status="error" variant="subtle">
    <AlertIcon />
    <Box flex="1">
      <AlertTitle>Activation failed!</AlertTitle>
      <AlertDescription>
        If you have already activated your user, click the sign in button below and log in with your username and password.
      </AlertDescription>
    </Box>
  </Alert>
);

function sendAnalytics(activationSuccess: boolean, activationFailure: boolean) {
  if (activationSuccess) {
    ReactGA.event({
      category: 'AccountActivation',
      action: 'Activation success',
      nonInteraction: true,
    });
  }
  if (activationFailure) {
    ReactGA.event({
      category: 'AccountActivation',
      action: 'Activation failure',
      nonInteraction: true,
    });
  }
}

interface IActivateProps {
  loginUrl: string;
}

export const ActivatePage = ({ loginUrl }: IActivateProps) => {
  const dispatch = useAppDispatch();
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (sessionHasBeenFetched) {
      const key = searchParams.get('key');

      dispatch(activateAction(key));
    }
    return () => {
      dispatch(reset());
    };
  }, [sessionHasBeenFetched]);
  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);
  sendAnalytics(activationSuccess, activationFailure);

  if (!sessionHasBeenFetched) {
    return <LoadingSpinner />;
  }

  return (
    <Stack spacing="8">
      {activationSuccess ? successAlert : undefined}
      {activationFailure ? failureAlert : undefined}
      <Button as={Link} type="submit" colorScheme="primary" size="lg" to={loginUrl}>
        Go to login
      </Button>
    </Stack>
  );
};

export default ActivatePage;
