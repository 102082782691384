import React, { useCallback, useMemo } from 'react';
import { Layer, Source, LayerProps } from 'react-map-gl';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { useAppSelector } from 'app/config/store';
import { Expression } from 'mapbox-gl';
import { IColourStop, IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import sortBy from 'lodash/sortBy';

export interface IPrayerRoutesGeoJsonLayerProps {
  // name: string;
  // prayerRoutes: IPrayerRoute[];
  selectedPrayerRoute?: IPrayerRoute;
  beforeLayerId: string;
}

function getLineColour(prayerWalkEvent: IPrayerWalkEvent): Expression {
  if (prayerWalkEvent.settings.mapStyle) {
    const colourStops = sortBy(prayerWalkEvent.settings.mapStyle.colourStops, ['decimalPercentage']);

    const walksRequiredForHighestColour = prayerWalkEvent.settings.mapStyle.numOfWalksRequiredForHighestColour;

    const percentageExpression = prayerWalkEvent.settings.mapStyle.heatmapLineColourIsRelative
      ? ['/', ['get', 'num_times_prayed'], ['max', ['get', 'max_prayer_routes_of_any_segment'], walksRequiredForHighestColour]]
      : ['/', ['min', ['get', 'num_times_prayed'], walksRequiredForHighestColour], walksRequiredForHighestColour];

    const interpolateExpressionStops: Array<string | number> = colourStops.reduce((acc: Array<string | number>, curr: IColourStop) => {
      acc.push(curr.decimalPercentage, curr.colour);
      return acc;
    }, [] as Array<string | number>);

    return ['interpolate', ['linear'], percentageExpression, ...interpolateExpressionStops];
  }

  return [
    'interpolate',
    ['linear'],
    ['/', ['get', 'num_times_prayed'], ['max', ['get', 'max_prayer_routes_of_any_segment'], 5]],

    // Least walked use this colour
    0,
    prayerWalkEvent.settings.colours.heatmapLowColour?.code || '#ebebec',

    // Most walked use this colour
    1,
    prayerWalkEvent.settings.colours.heatmapHighColour?.code || '#2c2c2d',
  ];
}

const AllPrayerRoutesHeatmapVectorLayer = (props: IPrayerRoutesGeoJsonLayerProps) => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);

  const lineColour: Expression = useMemo(() => getLineColour(prayerWalkEvent), [prayerWalkEvent]);

  const blurLayer: LayerProps = {
    id: `highlighted-roads-heatmap-blur`,
    type: 'line',
    source: 'highlighted-roads',
    'source-layer': 'public.prayer_routes_agg_count',
    layout: {
      'line-join': 'miter',
      'line-cap': 'butt',
    },
    paint: {
      'line-width': ['interpolate', ['linear'], ['zoom'], 10, 6, 14, 9, 18, 12],
      'line-opacity': 0.2,
      'line-color': lineColour,
    },
  };

  const solidLineLayer: LayerProps = {
    id: `highlighted-roads-heatmap-solid`,
    type: 'line',
    source: 'highlighted-roads',
    'source-layer': 'public.prayer_routes_agg_count',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 14, 3, 18, 6],
      'line-color': lineColour,
    },
  };

  // eslint-disable-next-line

  return (
    <>
      <Source
        id="highlighted-roads-heatmap"
        type="vector"
        tiles={[`${tileUrl}/public.prayer_routes_agg_count/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`]}
        minzoom={0}
        maxzoom={22}
      >
        <Layer
          {...solidLineLayer}
          id="highlighted-roads-heatmap-solid"
          source-layer="public.prayer_routes_agg_count"
          beforeId={props.beforeLayerId}
        />
        <Layer
          {...blurLayer}
          id="highlighted-roads-heatmap-blur"
          source-layer="public.prayer_routes_agg_count"
          beforeId="highlighted-roads-heatmap-solid"
        />
      </Source>
    </>
  );
};

export default AllPrayerRoutesHeatmapVectorLayer;
