import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  VStack,
  Text,
  HStack,
  Avatar,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { ArrowDownIcon, AtSignIcon, CheckIcon, SearchIcon } from '@chakra-ui/icons';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { Index } from 'flexsearch';
import { IParticipant } from 'app/modules/participants/participants.types';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getParticipants } from 'app/modules/participants/participants.reducer';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { StatBar } from 'app/modules/participants/stats/stat-bar';
import { UserStatBar } from 'app/modules/participants/stats/user-stat-bar';
import { BottomCardScroller } from 'app/shared/components/bottom-card-scroller';
import { Outlet } from 'react-router-dom';
import { MapLinkButton } from 'app/entities/map/layers/map-home-layers-and-outlet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { IPrayerEventChallenge } from 'app/shared/model/prayer-event-challenge.model';
import { completePrayerEventChallenge, getEntitiesForUser } from 'app/entities/prayer-event-challenge/prayer-event-challenge.reducer';
import { useCurrentEvent, useCurrentEventFeatures } from 'app/shared/hooks/useCurrentEvent';
import { useProgressPageTour } from 'app/shared/components/feature-tour';
import { distanceFormat } from 'app/shared/util/distance-format';

type SortDirections = 'ALPHA' | 'POSITION';

interface SearchBarProps extends InputProps {
  onToggleSort: () => void;
  sortBy: SortDirections;
}

const sorts = {
  ALPHA: {
    text: 'Sort by name',
    icon: <ArrowDownIcon />,
    process: (a, b) => (a.firstName ? a.firstName.localeCompare(b.firstName) : 1),
  },
  POSITION: {
    text: 'Sort by distance walked',
    icon: <AtSignIcon />,
    process: (a, b) => b.walkedKms - a.walkedKms,
  },
};

const SearchBar = ({ sortBy, onToggleSort, ...other }: SearchBarProps) => (
  <HStack px={6} py={2}>
    <IconButton aria-label={sorts[sortBy].text} title={sorts[sortBy].text} size="lg" icon={sorts[sortBy].icon} onClick={onToggleSort} />
    <InputGroup size="lg">
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input type="search" placeholder="Search participants" {...other} />
    </InputGroup>
  </HStack>
);

const Participants = ({ participants }: { participants: IParticipant[] }) => {
  const renderRow = ({ index, style }) => {
    const participant = participants[index];
    return (
      <Box style={style} bgColor={index % 2 ? 'gray.50' : 'white'}>
        <ParticipantDetails {...participant} />
      </Box>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List height={height} width={width} itemSize={80} itemCount={participants.length}>
          {renderRow}
        </List>
      )}
    </AutoSizer>
  );
};

const NoParticipants = () => (
  <Flex alignItems="center" justifyContent="center" width="full" grow={1} fontSize="lg">
    No participants found
  </Flex>
);

const ParticipantDetails = ({ position, walkedKms, firstName, name, imageUrl, challengesCompleted }: IParticipant) => {
  const formattedKms: string = distanceFormat({ distanceInKm: walkedKms });
  const features = useCurrentEventFeatures();
  return (
    <Flex px={4} py={4} justifyContent="space-between" width="full" alignItems="center">
      <Flex direction="row" alignItems="center">
        {position}
        <HStack ml={4}>
          <Avatar name={firstName} src={imageUrl} />
          <HStack alignItems="flex-start" spacing={2}>
            <Box fontWeight="medium">{name || ''}</Box>
            <Box>
              {features.prayerEventChallengesEnabled &&
                Array.from(Array(challengesCompleted || 0), (e, i) => {
                  return <FontAwesomeIcon key={i} icon={faShoePrints} />;
                })}
            </Box>
          </HStack>
        </HStack>
      </Flex>
      <Box fontSize="lg" mb={0}>
        {formattedKms}
      </Box>
    </Flex>
  );
};

export const EveryoneProgressPage = () => {
  const dispatch = useAppDispatch();
  const { loading: isLoading, errorMessage, entities: rawParticipants } = useAppSelector(state => state.participants);
  const prayerWalkEventId = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent.id);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<SortDirections>('ALPHA');

  useEffect(() => {
    if (prayerWalkEventId) {
      dispatch(getParticipants(prayerWalkEventId));
    }
  }, [prayerWalkEventId]);

  let participants: IParticipant[] = [...rawParticipants];

  const searchIndex = useMemo(() => {
    const ind = new Index({ tokenize: 'full' });
    participants.forEach((participant, index) => {
      ind.add(index, participant.name);
    });
    return ind;
    //  There is an assumption here that the list will not change without the length of it changing
  }, [participants.length]);

  if (searchQuery) {
    const results = searchIndex.search(searchQuery);
    participants = results.map(resultId => {
      return rawParticipants[resultId];
    });
  }

  participants.sort(sorts[sortBy].process);

  const hasParticipants = !isLoading && participants.length > 0;
  const hasNoParticipants = !isLoading && participants.length === 0;

  return (
    <>
      <Flex direction="column" width="full" bg="gray.50" shadow="md" zIndex="1">
        <StatBar />
        <SearchBar
          sortBy={sortBy}
          onToggleSort={() => {
            setSortBy(sortBy === 'ALPHA' ? 'POSITION' : 'ALPHA');
          }}
          onChange={event => setSearchQuery(event.target.value)}
        />
      </Flex>
      <Flex direction="column" grow={1} id="participants-list">
        {hasParticipants && <Participants participants={participants} />}
        {hasNoParticipants && <NoParticipants />}
        {isLoading && <LoadingSpinner />}
      </Flex>
    </>
  );
};

export const MarkCompleteButton = ({ prayerEventChallenge }: { prayerEventChallenge: IPrayerEventChallenge }) => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const [updating, setUpdating] = useState(false);
  const [completed, setCompleted] = useState(false);

  const toggleCompleted = useCallback(() => {
    setUpdating(true);
    dispatch(completePrayerEventChallenge({ prayerWalkEventId: prayerWalkEvent.id, id: prayerEventChallenge.id }));
  }, []);

  useEffect(() => {
    if (updating && prayerEventChallenge.completed !== completed) {
      setUpdating(false);
    }
    setCompleted(prayerEventChallenge.completed);
  }, [prayerEventChallenge]);

  return (
    <Button
      size="sm"
      colorScheme="blue"
      variant={prayerEventChallenge.completed ? 'solid' : 'outline'}
      leftIcon={<CheckIcon />}
      onClick={toggleCompleted}
      isLoading={updating}
    >
      Completed
    </Button>
  );
};

export const Challenges = ({ challenges }: { challenges: readonly IPrayerEventChallenge[] }) => {
  return (
    <Box bg="transparent" pos="absolute" w="full" overflowY="scroll" mt={2} pb={16}>
      <Box w="full" p={2}>
        <Box fontSize="lg">Your prayer steps</Box>
        <Box color="gray.500">
          Try take these steps, mark each as complete when you&apos;ve done it. Each completed one puts a step next to your name.
        </Box>
      </Box>
      <VStack spacing={0} alignItems="end" w="full">
        {challenges.map((challenge, index) => {
          return (
            <Box key={challenge.id} bgColor={index % 2 ? 'gray.50' : 'white'} w="full">
              <Flex px={4} py={4} justifyContent="space-between" width="full" alignItems="center">
                <Flex mx={2} direction="row" alignItems="center">
                  <HStack>
                    <FontAwesomeIcon icon={faShoePrints} />
                    <VStack direction="column" alignItems="flex-start" spacing={0}>
                      <Box fontWeight="medium">{challenge.title || ''}</Box>
                    </VStack>
                  </HStack>
                </Flex>
                <Box fontSize="lg" mb={0}>
                  <MarkCompleteButton prayerEventChallenge={challenge} />
                </Box>
              </Flex>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export const MyselfProgressPage = () => {
  const dispatch = useAppDispatch();
  const { loading: isLoading, errorMessage, entities: myChallenges } = useAppSelector(state => state.prayerEventChallenge);
  const prayerWalkEventId = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent.id);

  useEffect(() => {
    if (prayerWalkEventId) {
      dispatch(getEntitiesForUser({ prayerWalkEventId }));
    }
  }, [prayerWalkEventId]);

  return (
    <>
      <Flex direction="column" width="full" bg="gray.50" shadow="md" zIndex="1">
        <UserStatBar />
      </Flex>
      <Flex grow={1} position="relative">
        <Box height="100%" overflowY="scroll" left={0} right={0} position="absolute">
          {isLoading && <LoadingSpinner />}
          {myChallenges && <Challenges challenges={myChallenges} />}
        </Box>
      </Flex>
    </>
  );
};

export const ProgressPage = () => {
  const prayerWalkEvent = useCurrentEvent();
  useProgressPageTour();

  return (
    <Flex direction="column" width="full" position="relative" height="auto">
      <Outlet />
      {prayerWalkEvent.settings.features.prayerEventChallengesEnabled && (
        <BottomCardScroller>
          <MapLinkButton id="everyone-progress-link" icon={<FontAwesomeIcon icon={faUsers} />} url={`everyone`}>
            Everyone
          </MapLinkButton>
          <MapLinkButton id="myself-progress-link" icon={<FontAwesomeIcon icon={faUser} />} url={`myself`}>
            Myself
          </MapLinkButton>
        </BottomCardScroller>
      )}
    </Flex>
  );
};
