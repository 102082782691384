import {
  Avatar,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  useBreakpointValue,
  VStack,
  Text,
  Flex,
  Divider,
  Wrap,
  Alert,
  AlertIcon,
  AlertTitle,
  Badge,
} from '@chakra-ui/react';
import { faPersonWalking, faRightFromBracket, faRotateRight, faUserShield, faWallet } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link, Outlet, useMatch, useResolvedPath } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getPortalSession, reset } from 'app/modules/organisation/reducers/portal-session.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route-2';
import { AUTHORITIES } from 'app/config/constants';
import {
  isAdminForOrganisation,
  isEventManagerInUsersOrganisation,
  isModeratorForOrganisationState,
} from 'app/modules/organisation/auth/permissions';
import {
  useOrganisationHasCancelledSubscription,
  useOrganisationHasPastDueSubscription,
  useOrganisationSubscriptionIsExpired,
  useOrganisationSubscriptionIsSelfManaged,
} from 'app/shared/hooks/useUsersOrganisation';

const NavMenuItemLink = (props: { icon: IconProp; text: string; to: string }) => {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Button
      as={Link}
      bg={match && 'gray.100'}
      size="lg"
      to={props.to}
      justifyContent="start"
      colorScheme="gray"
      variant="ghost"
      leftIcon={<FontAwesomeIcon icon={props.icon} />}
      w="full"
    >
      {props.text}
    </Button>
  );
};
const NavMenuItem = (props: { icon: IconProp; text: string; onClick: () => void; isLoading: boolean }) => {
  return (
    <Button
      size="lg"
      onClick={props.onClick}
      justifyContent="start"
      colorScheme="gray"
      variant="ghost"
      leftIcon={<FontAwesomeIcon icon={props.icon} />}
      w="full"
      isLoading={props.isLoading}
    >
      {props.text}
    </Button>
  );
};

const BillingBanner = props => {
  return (
    <Box w="full">
      <Alert status="error" id="billingBanner">
        <AlertIcon />
        <AlertTitle>{props.title}</AlertTitle>
        {props.children}
      </Alert>
    </Box>
  );
};
const BillingBannerContent = () => {
  const cancelledSubscription = useOrganisationHasCancelledSubscription();
  const pastDueSubscription = useOrganisationHasPastDueSubscription();
  const subscriptionIsSelfManaged = useOrganisationSubscriptionIsSelfManaged();
  const subscriptionIsExpired = useOrganisationSubscriptionIsExpired();
  const isOrgAdmin = useAppSelector(state => isAdminForOrganisation(state.authentication, state.usersOrganisation));

  if (!subscriptionIsSelfManaged && subscriptionIsExpired) {
    return (
      <BillingBanner title="Your subscription has expired">
        <Box>Get in touch with your Prayer Walk App contact to extend your subscription</Box>
      </BillingBanner>
    );
  }

  if (pastDueSubscription) {
    return (
      <BillingBanner title="Your last payment failed">
        {isOrgAdmin ? (
          <Link to="billing">Click here to update your payment details</Link>
        ) : (
          <Box>Ask your organisation admin to login and update the payment details</Box>
        )}
      </BillingBanner>
    );
  }

  if (cancelledSubscription) {
    return (
      <BillingBanner title="Your subscription has expired">
        {isOrgAdmin ? (
          <Link to="renew-subscription">Click here to renew</Link>
        ) : (
          <Box>Ask your organisation admin to login and renew your subscription</Box>
        )}
      </BillingBanner>
    );
  }

  return null;
};

export const OrganisationMainLayout = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const billingPortalUrl = useAppSelector(state => state.portalSession.portalSession.url);
  const billingPortalUrlLoading = useAppSelector(state => state.portalSession.loading);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isOrgAdmin = useAppSelector(state => isAdminForOrganisation(state.authentication, state.usersOrganisation));
  const isOrgEventManager = useAppSelector(state => isEventManagerInUsersOrganisation(state.authentication, state.usersOrganisation));
  const isOrgModerator = useAppSelector(state => isModeratorForOrganisationState(state.authentication, state.usersOrganisation));
  const cancelledSubscription = useOrganisationHasCancelledSubscription();
  const subscriptionIsSelfManaged = useOrganisationSubscriptionIsSelfManaged();

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  }, [billingPortalUrl]);

  const getBillingPortalUrl = useCallback(() => {
    dispatch(getPortalSession(`${window.location.href}`));
  }, []);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Flex direction="column" w="full" h="full">
      <BillingBannerContent />
      <Grid
        templateAreas={`"nav main"`}
        gridTemplateRows={'1fr'}
        gridTemplateColumns={'320px 1fr'}
        color="blackAlpha.700"
        fontWeight="bold"
        w="full"
        h="full"
      >
        <GridItem p="8" area={'nav'} boxShadow="md" bg="white" h="full">
          <Flex direction="column" h="full">
            <VStack spacing={6} flexGrow={1}>
              <Box w="full">
                <Image src="content/images/SVG-PrayerWalkApp-Horizontal_Colour.svg" />
              </Box>
              <VStack w="full" id="navMenu">
                <NavMenuItemLink icon={faHome} text="Home" to="/organisation" />
                {(isOrgEventManager || isAdmin || isOrgModerator) && (
                  <NavMenuItemLink icon={faPersonWalking} text="Prayer walk events" to="prayer-walk-events" />
                )}
                {(isOrgAdmin || isAdmin) && <NavMenuItemLink icon={faUserShield} text="User Permissions" to="user-permissions" />}
                {(isOrgAdmin || isAdmin) && subscriptionIsSelfManaged && (
                  <NavMenuItem
                    icon={faWallet}
                    text="Subscription and Billing"
                    onClick={getBillingPortalUrl}
                    isLoading={billingPortalUrlLoading}
                  />
                )}
                {(isOrgAdmin || isAdmin) && subscriptionIsSelfManaged && cancelledSubscription && (
                  <NavMenuItemLink icon={faRotateRight} text="Renew Subscription" to={'renew-subscription'} />
                )}
              </VStack>
              <VStack w="full">
                {/*<NavMenuItem icon={faQuestionCircle} text="Help" to="help" />*/}
                {/*<NavMenuItem icon={faCog} text="Settings" to="settings" />*/}
                <NavMenuItemLink icon={faRightFromBracket} text="Log out" to="logout" />
              </VStack>
            </VStack>
            <Divider />
            <HStack pt={4}>
              <Avatar mr={2} name={account.firstName} src={account.imageUrl} />
              <VStack spacing={2} alignItems="flex-start">
                <Box>
                  {account.firstName} {account.lastName}
                </Box>
                <Box color="gray.500" fontWeight="light">
                  {account.email}
                </Box>
                <Wrap>
                  {isOrgAdmin && <Badge colorScheme="gray">Admin</Badge>}
                  {isOrgEventManager && <Badge colorScheme="gray">Event Manager</Badge>}
                  {isOrgModerator && <Badge colorScheme="gray">Moderator</Badge>}
                </Wrap>
              </VStack>
            </HStack>
          </Flex>
        </GridItem>
        <GridItem py="8" px={6} area={'main'}>
          <Outlet />
        </GridItem>
      </Grid>
    </Flex>
  );
};
