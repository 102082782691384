import React, { useState, useEffect } from 'react'
import _ from 'lodash'

const useRestorableFormState = (formName: string): [Record<string, any>, (formValues: Record<string, any>) => void, () => void] => {
  const restoredFormState = JSON.parse(sessionStorage.getItem(formName) || "{}");
  const [formValues, setFormValues] = useState(restoredFormState);

  useEffect(
    () => {
      sessionStorage.setItem(formName, JSON.stringify(formValues));
    }, [formValues]);

  const onFormChange = (formCurrentValues: Record<string, any>) => {
    setFormValues(_.pickBy(formCurrentValues, (value, name) => !name.toLowerCase().includes('password')));
  }

  const clearStoredValues = () => {
    sessionStorage.removeItem(formName);
  }

  return [formValues, onFormChange, clearStoredValues]
}

export default useRestorableFormState
