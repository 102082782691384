import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { createEntity, getEntitiesForUser, getEntity, reset, updateEntity } from 'app/entities/prayer-route/prayer-route.reducer';
import { HelpPopover } from 'app/shared/components/help-popover';
import { FloatingActionButton } from 'app/shared/components/floating-action-button';
import React, { useEffect, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { DistanceMonitor, DistanceProblemModal } from 'app/modules/map/distance-monitor';
import Navbar from 'app/shared/route/actions-navbar';
import { newFeatureCollection } from 'app/modules/map/utils';
import { useNavigate, useParams } from 'react-router-dom';
import length from '@turf/length';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { ActionCard } from 'app/shared/components/action-card';
import { PrayerRouteSingleMapLayers } from 'app/entities/map/layers/prayer-route-single-map-layers';

export const PrayerRouteUncompleteMapOverlay = () => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const params = useParams();
  const navigate = useNavigate();
  const prayerRouteEntity = useAppSelector(state => state.prayerRoute.entity);
  const updating = useAppSelector(state => state.prayerRoute.updating);
  const updateSuccess = useAppSelector(state => state.prayerRoute.updateSuccess);

  useEffect(() => {
    dispatch(getEntity({ prayerWalkEventId: prayerWalkEvent.id, id: params.id }));
  }, []);

  const handleClose = () => {
    navigate('/map');
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const uncompletePrayerRoute = () => {
    const entity = {
      ...prayerRouteEntity,
      completedAt: null,
      completed: false,
    };

    dispatch(updateEntity({ prayerWalkEventId: prayerWalkEvent.id, entity }));
  };

  return (
    <>
      <ActionCard title="Uncomplete a Prayer Walk" handleClose={handleClose}>
        <Box fontSize="lg">Are you sure you want to mark this this route as uncompleted?</Box>
        <Button
          w="full"
          data-cy="submit"
          type="submit"
          bg="gray.100"
          size="lg"
          leftIcon={<FontAwesomeIcon icon={faCheck} />}
          onClick={uncompletePrayerRoute}
          isLoading={updating}
        >
          Yes, mark it as uncomplete
        </Button>
      </ActionCard>
      <PrayerRouteSingleMapLayers />
    </>
  );
};
