import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

export type AvailableDTO = { available?: string | undefined };
const initialState: {
  loading: boolean;
  availableSubdomain: AvailableDTO;
} = {
  loading: false,
  availableSubdomain: {},
};

export const checkAvailableSubdomain = createAsyncThunk(
  'availableSubdomain/fetch',
  async ({ subdomain, prayerWalkEventId }: { subdomain: string; prayerWalkEventId: number }) => {
    const requestUrl = `/api/prayer-walk-events/available-subdomain`;
    return axios.post<AvailableDTO>(requestUrl, { subdomain, prayerWalkEventId });
  },
  { serializeError: serializeAxiosError }
);

export const AvailableSubdomainSlice = createSlice({
  name: 'availableSubdomain',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(checkAvailableSubdomain), state => {
        state.loading = true;
      })
      .addMatcher(isFulfilled(checkAvailableSubdomain), (state, action) => {
        return {
          ...state,
          loading: false,
          availableSubdomain: action.payload.data,
        };
      });
  },
});

// Reducer
export default AvailableSubdomainSlice.reducer;
