import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

import {defaultValue, IPrayerWalkEvent} from 'app/shared/model/prayer-walk-event.model';
import {
  createEntity,
  partialUpdateEntity,
  updateEntity
} from "app/entities/prayer-walk-event/prayer-walk-event.reducer";

const initialState = {
  loading: false,
  errorMessage: null,
  currentPrayerWalkEvent: defaultValue,
};

export type CurrentPrayerWalkEventState = Readonly<typeof initialState>;

export const getPrayerWalkEventFromDomain = createAsyncThunk(
  'currentPrayerWalkEvent/fetch_from_domain',
  async () => axios.get<IPrayerWalkEvent>('api/prayer-walk-event'),
  {
    serializeError: serializeAxiosError,
  }
);

export const CurrentPrayerWalkEventSlice = createSlice({
  name: 'currentPrayerWalkEvent',
  initialState: initialState as CurrentPrayerWalkEventState,
  reducers: {
    changeCurrentPrayerWalkEvent(state, data) {
      state.currentPrayerWalkEvent = data.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPrayerWalkEventFromDomain.pending, state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addCase(getPrayerWalkEventFromDomain.rejected, (state, payload) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(getPrayerWalkEventFromDomain.fulfilled, (state, payload) => {
        state.loading = false;
        state.currentPrayerWalkEvent = payload.payload.data;
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        if (action.payload.data.id === state.currentPrayerWalkEvent.id) {
          state.loading = false;
          state.currentPrayerWalkEvent = action.payload.data;
        }
      });
  },
});

export const { reset, changeCurrentPrayerWalkEvent } = CurrentPrayerWalkEventSlice.actions;

// Reducer
export default CurrentPrayerWalkEventSlice.reducer;
