import React, { useCallback, useEffect, useState } from 'react';
import { useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Stack, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { useInterval } from 'app/shared/hooks/useInterval';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { getUsersOrganisation } from 'app/modules/organisation/reducers/users-organisation.reducer';
import * as Sentry from '@sentry/browser';
import { usePolling } from 'app/shared/hooks/usePolling';

export const SubscriptionSuccess = () => {
  const dispatch = useAppDispatch();
  const organisation = useUsersOrganisation();
  const subscriptionIsSetUp = useCallback(() => {
    return organisation.paymentSubscription && organisation.paymentSubscription.expiresAt !== null;
  }, [organisation]);
  const { startPolling, isPolling, hasTimedOut } = usePolling(
    () => {
      dispatch(getUsersOrganisation());
    },
    subscriptionIsSetUp,
    'Timed out waiting for subscription to provision'
  );

  useEffect(() => {
    startPolling();
  }, []);

  // polling
  if (isPolling) {
    return <LoadingSpinner />;
  }
  if (hasTimedOut) {
    return (
      <Stack spacing="8">
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          minH="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            There&apos;s been a problem setting up your subscription
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Your subscription wasn&apos;t set up within a reasonable time, our team have been notified and are looking into it. You can
            refresh the page to try waiting longer. Feel free to email{' '}
            <ChakraLink href="mailto:support@prayerwalk.app">support@prayerwalk.app</ChakraLink> for support.
          </AlertDescription>
        </Alert>
      </Stack>
    );
  }

  return (
    <Stack spacing="8">
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Your subscription is now active!
        </AlertTitle>
        <AlertDescription maxWidth="sm">You can start using The Prayer Walk App now.</AlertDescription>
      </Alert>
      <Button as={Link} type="submit" colorScheme="primary" size="lg" to="/organisation">
        Go to your dashboard
      </Button>
    </Stack>
  );
};
