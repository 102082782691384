import React, { useState, useEffect } from 'react';

import { Link, Navigate } from 'react-router-dom';

import { getEntityForCurrentUser, createEntity, reset, createEntityForCurrentUser } from 'app/entities/user-profile/user-profile.reducer';
import useRestorableFormState from 'app/shared/hooks/useRestorableFormState';
import { defaultValue, IUserProfile } from 'app/shared/model/user-profile.model';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { hideNav, showNav } from 'app/shared/reducers/app-layout';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useForm } from 'react-hook-form';
import {
  Avatar,
  Button,
  Box,
  Flex,
  VStack,
  IconButton,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { FormCheckbox, FormField } from 'app/shared/components/form-field';

export const FinishOauth2RegistrationPage = () => {
  const dispatch = useAppDispatch();
  const [formValues, onFormValuesChange, clearRestorableFormState] = useRestorableFormState('finish-registration');
  const emailUsed = useAppSelector(state => state.register.emailUsedToSignUp);
  const registrationSuccess = useAppSelector(state => state.register.registrationSuccess);
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const loading = useAppSelector(state => state.userProfile.loading);
  const userProfile = useAppSelector(state => state.userProfile.entity);

  useEffect(() => {
    dispatch(hideNav());
    dispatch(getEntityForCurrentUser());
    return () => {
      dispatch(showNav());
      dispatch(reset());
    };
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset: formReset,
  } = useForm({
    defaultValues: {
      church: userProfile.church,
      privacyAgreement: false,
      consentToContactAboutPrayerWalkEvent: userProfile.consentToContactAboutPrayerWalkEvent,
      consentToContactFromOrganisation: userProfile.consentToContactFromOrganisation,
      consentToProvidingAnonymousAnalytics: userProfile.consentToProvidingAnonymousAnalytics,
      publiclyViewable: userProfile.publiclyViewable,
      ...formValues,
    },
  });

  const handleValidSubmit = handleSubmit(values => {
    const updatedUserProfile: IUserProfile = {
      church: values.church,
      consentToContactAboutPrayerWalkEvent: values.consentToContactAboutPrayerWalkEvent,
      consentToContactFromOrganisation: values.consentToContactFromOrganisation,
      consentToProvidingAnonymousAnalytics: values.consentToProvidingAnonymousAnalytics,
      eventSignedUpFor: prayerWalkEvent,
      publiclyViewable: values.publiclyViewable,
    };
    dispatch(createEntityForCurrentUser(updatedUserProfile));
  });

  useEffect(() => {
    const subscription = watch(value => onFormValuesChange(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  if (registrationSuccess) {
    clearRestorableFormState();
    return <Navigate to="/map" />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!loading && userProfile !== defaultValue && userProfile !== '') {
    // todo need to find out how to redirect proper
    return <Navigate to="/map" />;
  }

  return (
    <Flex direction="column" grow={1}>
      <Box p={[2, 4]}>
        <Box rounded="lg" bg="white" maxW="3xl" width="full" mx="auto" shadow="base" px={[4, 8]} py={4}>
          <form onSubmit={handleValidSubmit}>
            <VStack spacing={4}>
              <FormField
                label="Church"
                errors={errors}
                placeholder={'The church you attend'}
                name={'church'}
                {...register('church', {
                  maxLength: { value: 254, message: 'Church cannot be longer than 254 characters.' },
                })}
                data-cy="church"
              />
              <VStack width="full" alignItems="flex-start" spacing={3}>
                <FormCheckbox
                  errors={errors}
                  {...register('privacyAgreement', {
                    required: { value: true, message: 'You must agree to the privacy policy.' },
                  })}
                >
                  I agree to the{' '}
                  <a
                    id="privacyPolicyLink"
                    target="_blank"
                    rel="noreferrer"
                    href="https://prayerwalk.app/privacy-policy"
                    style={{ zIndex: 10 }}
                  >
                    privacy policy
                  </a>{' '}
                  and the{' '}
                  <a
                    id="termsOfService"
                    target="_blank"
                    rel="noreferrer"
                    href="https://prayerwalk.app/terms-of-service"
                    style={{ zIndex: 10 }}
                  >
                    terms of service
                  </a>
                </FormCheckbox>
                <FormCheckbox errors={errors} {...register('consentToContactAboutPrayerWalkEvent')}>
                  Please keep me informed via email about {prayerWalkEvent.name} news and updates
                </FormCheckbox>
                <FormCheckbox errors={errors} {...register('consentToContactFromOrganisation')}>
                  I would like the organisers to let me know about similar future events so I don&apos;t miss out
                </FormCheckbox>
                <FormCheckbox errors={errors} {...register('consentToProvidingAnonymousAnalytics')}>
                  I am happy to make this app better by providing anonymous analytics
                </FormCheckbox>
                <FormCheckbox errors={errors} {...register('publiclyViewable')}>
                  I am happy for my profile to be publicly viewable
                </FormCheckbox>
                <Box fontSize="md">You can change your contact preferences anytime in app from the settings page</Box>
              </VStack>
              <Button type="submit" width="full" size="lg" className="bg-primary text-white">
                Finish Registration
              </Button>
            </VStack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default FinishOauth2RegistrationPage;
