import _ from 'lodash';

export interface ITogglingSet<T> {
  addOrRemove: (T) => T[];
}

export class MultipleFieldEqualityTogglingSet<T> implements ITogglingSet<T> {
  items: T[];
  getFieldsToFindTheSameObject: (t1: T) => Record<string, unknown>;
  getFieldsToFindObjectsToRemove: (t1: T) => Record<string, unknown>;

  constructor(list: T[], fieldsToDetermineEquality: (t1: T) => Record<string, unknown>, fieldsToDetermineWhatToRemove?: (t1: T) => Record<string, unknown>) {
    this.items = list;
    this.getFieldsToFindTheSameObject = fieldsToDetermineEquality;
    this.getFieldsToFindObjectsToRemove = fieldsToDetermineWhatToRemove ? fieldsToDetermineWhatToRemove : fieldsToDetermineEquality;
  }

  addOrRemove(item: T): T[] {
    if (_.find(this.items, this.getFieldsToFindTheSameObject(item))) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return _.reject<T>(this.items, this.getFieldsToFindObjectsToRemove(item));
    } else {
      return [...this.items, item];
    }
  }
}
