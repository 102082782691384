import React from 'react';
import ReactGA from 'react-ga';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  HStack,
  Image,
  Box,
  Flex,
  Center,
  CloseButton,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import useIosInstallPrompt from 'app/shared/hooks/useIosInstallPrompt';
import useWebInstallPrompt from 'app/shared/hooks/useWebInstallPrompt';
import EventAwareInstallLogo from 'app/modules/prayer-walk-event/event-aware-install-logo';
import { useHotkeys } from 'react-hotkeys-hook';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';

function tracking(iosInstallPrompt: boolean, handleWebInstallAccepted: () => void, handleWebInstallDeclined: () => void) {
  ReactGA.event({
    category: 'Install',
    action: 'Install prompt shown',
    label: iosInstallPrompt ? 'iOS' : 'Web',
    nonInteraction: true,
  });
  const trackAndHandleWebInstallAccepted = () => {
    handleWebInstallAccepted();
    ReactGA.event({
      category: 'Install',
      action: 'Install accepted',
      label: 'Web',
    });
  };
  const trackAndHandleWebInstallDeclined = () => {
    handleWebInstallDeclined();
    ReactGA.event({
      category: 'Install',
      action: 'Install declined',
      label: 'Web',
    });
  };
  return { trackAndHandleWebInstallAccepted, trackAndHandleWebInstallDeclined };
}

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();
  const prayerWalkEvent = useCurrentEvent();
  useHotkeys('ctrl+k, command+k', () => {
    handleIOSInstallDeclined();
    handleWebInstallDeclined();
  });
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  if ((!iosInstallPrompt && !webInstallPrompt) || isDesktop) {
    return null;
  }
  const { trackAndHandleWebInstallAccepted, trackAndHandleWebInstallDeclined } = tracking(
    iosInstallPrompt,
    handleWebInstallAccepted,
    handleWebInstallDeclined
  );

  return (
    <Modal
      isCentered
      isOpen={true}
      onClose={() => {
        if (iosInstallPrompt) {
          handleIOSInstallDeclined();
        }
        if (webInstallPrompt) {
          handleWebInstallDeclined();
        }
      }}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(90deg)" />
      <ModalContent>
        <EventAwareInstallLogo />
        <ModalHeader>
          {iosInstallPrompt && <Box textAlign="center">Easy access to {prayerWalkEvent.name}</Box>}
          {webInstallPrompt && <Box textAlign="center">Install {prayerWalkEvent.name} as an App</Box>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody w="full">
          <Flex grow={1} w="full">
            {iosInstallPrompt && (
              <Center w="full">
                <VStack>
                  <Box textAlign="center">Open in Safari,</Box>
                  <HStack spacing={2}>
                    <Box textAlign="center">tap</Box>
                    <Box as="span" w={5} h={5}>
                      <Image src="content/images/Navigation_Action_2x.png" alt="Add to homescreen" width="20" />
                    </Box>
                    <Box>then &quot;Add to Home Screen&quot;</Box>
                  </HStack>
                </VStack>
              </Center>
            )}
            {webInstallPrompt && (
              <Box textAlign="center">
                This will add the {prayerWalkEvent.name} icon to your apps screen so you can easily access it without using a browser.
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          {webInstallPrompt && (
            <HStack spacing={2} w="full">
              <Button colorScheme="primary" size="lg" w="full" onClick={trackAndHandleWebInstallAccepted}>
                Install
              </Button>
              <Button w="full" size="lg" onClick={trackAndHandleWebInstallDeclined}>
                Close
              </Button>
            </HStack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
