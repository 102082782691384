import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import { FormField } from 'app/shared/components/form-field';
import { PasswordField } from 'app/shared/layout/password/password-field';
import { DividerWithText } from 'app/shared/layout/DividerWithText';
import { Button, chakra, Stack, SimpleGrid, VisuallyHidden, AlertIcon, Box, AlertTitle, AlertDescription, Alert } from '@chakra-ui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

interface ILoginFormProps {
  socialLoginEnabled: boolean;
}

export const LoginForm = (props: ILoginFormProps) => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(state => state.authentication.loginError);
  const handleLogin = (username, password, rememberMe = false) => dispatch(login(username.trim(), password, rememberMe));

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    handleLogin(data.username, data.password, true);
  });

  return (
    <chakra.form onSubmit={onSubmit} noValidate>
      <Stack spacing="8">
        {loginError && (
          <Alert status="error" variant="subtle">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Login failed!</AlertTitle>
              <AlertDescription>
                Please check your credentials and try again.
                <br />
                Please make sure you have activated your account by clicking on the link in the email we sent you.
              </AlertDescription>
            </Box>
          </Alert>
        )}
        <FormField
          label="Username or Email"
          errors={errors}
          {...register('username', {
            required: 'username/email cannot be empty',
            maxLength: { value: 255, message: 'Your username/email cannot be longer than 255 characters.' },
            validate(value) {
              if (!value) return true;
              return /^[\s]*[_.@A-Za-z0-9-+]*[\s]*$/.test(value) ? true : 'Your username/email should not contain spaces';
            },
          })}
        />
        <PasswordField
          {...register('password', {
            required: 'Password cannot be empty',
          })}
        />
        <Stack spacing="4">
          <Button type="submit" colorScheme="primary" size="lg" isLoading={isSubmitting}>
            Sign in
          </Button>
          <Button id="register" as={Link} to="/register" size="lg">
            Register
          </Button>
        </Stack>
        {props.socialLoginEnabled && (
          <>
            <DividerWithText>or continue with</DividerWithText>
            <SimpleGrid columns={2} spacing="3">
              <Button as="a" color="currentColor" variant="outline" size="lg" href={'/oauth2/authorization/facebook'}>
                <VisuallyHidden>Login with Facebook</VisuallyHidden>
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </Button>
              <Button as="a" color="currentColor" variant="outline" size="lg" href={'/oauth2/authorization/google'}>
                <VisuallyHidden>Login with Google</VisuallyHidden>
                <FontAwesomeIcon icon={['fab', 'google']} />
              </Button>
            </SimpleGrid>
          </>
        )}
      </Stack>
    </chakra.form>
  );
};
