import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Pagination } from 'app/shared/components/pagination';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IPaginationBaseState } from 'react-jhipster';
import React from 'react';
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { IPrayerNeedDownload } from 'app/shared/model/prayer-need-download.model';
import { getPrayerNeedTable } from 'app/modules/organisation/reducers/prayer-need-table.reducer';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { TableHeader } from 'app/modules/organisation/main/prayer-walk-event-overview/table-header';

export const PrayerNeedsHeaderAndTable = ({
  prayerWalkEvent,
  inactiveSubscription,
}: {
  prayerWalkEvent: IPrayerWalkEvent;
  inactiveSubscription: boolean;
}) => {
  return (
    <TableHeader
      heading="Prayer Needs"
      subheading="All the approved prayer needs"
      inactiveSubscription={inactiveSubscription}
      url={`/api/prayer-walk-events/${prayerWalkEvent.id}/prayer-needs.csv`}
    >
      <PrayerNeedsTable />
    </TableHeader>
  );
};

export const PrayerNeedsTable = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const prayerNeedsTableIsLoading: boolean = useAppSelector(state => state.prayerNeedTable.loading);
  const prayerNeeds: IPrayerNeedDownload[] = useAppSelector(state => state.prayerNeedTable.prayerNeeds);
  const totalItems = useAppSelector(state => state.prayerNeedTable.totalItems);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: 10,
    sort: 'id',
    order: 'asc',
    activePage: 1,
  });

  useEffect(() => {
    dispatch(
      getPrayerNeedTable({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        id,
      })
    );
  }, [paginationState]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  return (
    <VStack spacing={2} w="full">
      <Table __css={{ 'table-layout': 'auto', width: 'full' }}>
        <Thead>
          <Tr>
            <Th>Prayer need text</Th>
            <Th>Category</Th>
            <Th>Author</Th>
            <Th>Location</Th>
            <Th isNumeric>Prayed for</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prayerNeeds.map(prayerNeed => (
            <Tr key={prayerNeed.id}>
              <Td>{prayerNeed.prayerNeedText}</Td>
              <Td>{prayerNeed.category}</Td>
              <Td>{prayerNeed.author}</Td>
              <Td>{prayerNeed.location}</Td>
              <Td isNumeric>{prayerNeed.numberOfTimesPrayedFor}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        activePage={paginationState.activePage}
        onSelect={handlePagination}
        maxButtons={5}
        itemsPerPage={paginationState.itemsPerPage}
        totalItems={totalItems}
      />
    </VStack>
  );
};
