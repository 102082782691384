import React from "react"
import { Button, ButtonProps, IconButton, IconButtonProps } from "@chakra-ui/react"
import { Link, LinkProps } from "react-router-dom"

export const ButtonLink = ({isDisabled, to, children, ...props}: {isDisabled: boolean, to: string, children: React.ReactNode} & ButtonProps & LinkProps ) => {
  return <Button isDisabled={isDisabled} as={Link} to={to} {...props}
          pointerEvents={isDisabled ? 'none': 'auto'}>
    {children}
  </Button>
}
export const IconButtonLink = ({isDisabled, to, ...props}: {isDisabled: boolean, to: string} & IconButtonProps & LinkProps ) => {
  return <IconButton isDisabled={isDisabled} as={Link} to={to} {...props}
  pointerEvents={isDisabled ? 'none' : 'auto'}/>
}