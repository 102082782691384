import React from 'react';
import { useAppSelector } from 'app/config/store';
import { Image, ImageProps } from '@chakra-ui/react';

export interface IInstallLogoProps {
  logoUrl: string;
  name: string;
}

export const InstallLogo = (props: IInstallLogoProps & ImageProps) => (
  <Image mx="auto" my={2} bg="white" width="100px" src={props.logoUrl} alt={`${props.name} Icon`} />
);

export const EventAwareInstallLogo = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  return (
    <InstallLogo
      logoUrl={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Fandroid-chrome-512x512.png?alt=media`}
      name={prayerWalkEvent.name}
    />
  );
};

export default EventAwareInstallLogo;
