import { createSlice } from '@reduxjs/toolkit';

import { defaultValue } from 'app/shared/model/map-state.model';

const initialState = {
  mapPosition: defaultValue,
};

export type MapState = Readonly<typeof initialState>;

export const MapStateSlice = createSlice({
  name: 'mapState',
  initialState: initialState as MapState,
  reducers: {
    saveMapPosition(state, data) {
      state.mapPosition = {
        id: data.payload.id,
        latitude: data.payload.latitude,
        longitude: data.payload.longitude,
        zoom: data.payload.zoom,
        bearing: data.payload.bearing,
        pitch: data.payload.pitch,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const { reset, saveMapPosition } = MapStateSlice.actions;

// Reducer
export default MapStateSlice.reducer;
