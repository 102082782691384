import React from 'react';
import { getAuthToken } from 'app/config/axios-interceptor';
import { Box, Button, Flex, Spacer, VStack } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

export interface ITableHeaderProps {
  heading: string;
  subheading: string;
  url: string;
  inactiveSubscription: boolean;
  children: React.ReactNode;
}

export const TableHeader = (props: ITableHeaderProps) => {
  return (
    <Box position="relative" w="full" mb={4}>
      <VStack bg="white" p={4} w="full" spacing={2}>
        <Flex direction="row" w="full">
          <Box color="gray.400" fontWeight="medium" px={2}>
            {props.subheading}
          </Box>
          <Spacer />
          <form method="post" target="_blank" action={props.url}>
            <input type="hidden" name="Authorization" value={`Bearer ${getAuthToken()}`} />
            <Button isDisabled={props.inactiveSubscription} type="submit" leftIcon={<DownloadIcon />}>
              Download CSV
            </Button>
          </form>
        </Flex>
        {props.children}
      </VStack>
    </Box>
  );
};
