import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { createEntity, getEntitiesForUser, getEntity, reset, updateEntity } from 'app/entities/prayer-route/prayer-route.reducer';
import { HelpPopover } from 'app/shared/components/help-popover';
import { FloatingActionButton } from 'app/shared/components/floating-action-button';
import React, { useEffect, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { DistanceMonitor, DistanceProblemModal } from 'app/modules/map/distance-monitor';
import Navbar from 'app/shared/route/actions-navbar';
import { newFeatureCollection } from 'app/modules/map/utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import length from '@turf/length';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { PrayerRouteSingleMapLayers } from 'app/entities/map/layers/prayer-route-single-map-layers';
import { ActionCard } from 'app/shared/components/action-card';

const maxDistance = 20;
export const PrayerRouteCompleteMapOverlay = () => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const params = useParams();
  const navigate = useNavigate();
  const prayerRouteEntity = useAppSelector(state => state.prayerRoute.entity);
  const updating = useAppSelector(state => state.prayerRoute.updating);
  const updateSuccess = useAppSelector(state => state.prayerRoute.updateSuccess);

  useEffect(() => {
    dispatch(getEntity({ prayerWalkEventId: prayerWalkEvent.id, id: params.id }));
  }, []);

  const handleClose = () => {
    navigate('/map');
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const completePrayerRoute = () => {
    const entity = {
      ...prayerRouteEntity,
      completedAt: dayjs().toDate().toISOString(),
      completed: true,
    };

    dispatch(updateEntity({ prayerWalkEventId: prayerWalkEvent.id, entity }));
  };

  return (
    <>
      <ActionCard title="Mark your prayer walk as completed" handleClose={handleClose}>
        <Box fontSize="lg">
          Did you complete the whole of this prayer walk? If not, tap the cross in the corner to go back, then edit the walk to only have
          the roads that you prayed for and then complete it.
        </Box>
        <Button
          w="full"
          data-cy="submit"
          type="submit"
          colorScheme="primary"
          size="lg"
          leftIcon={<FontAwesomeIcon icon={faCheck} />}
          onClick={completePrayerRoute}
          isLoading={updating}
        >
          Yes, it&apos;s been prayed for
        </Button>
      </ActionCard>
      <PrayerRouteSingleMapLayers />
    </>
  );
};
