import React from 'react';
import { connect } from 'react-redux';
import { Route, Navigate, PathRouteProps } from 'react-router-dom';

import { PrivateRoute, hasAnyAuthority } from 'app/shared/auth/private-route-2';
import _ from 'lodash';
import { useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { Translate } from 'react-jhipster';
import { IUserAuthorityResource } from 'app/shared/model/user-authority-resource.model';

interface IOwnProps extends PathRouteProps {
  hasAnyAuthorities: string[];
  hasPermissions: (IRootState) => IResourceAuthority[];
  children: React.ReactNode;
}

export interface IResourceAuthority {
  resourceId: number;
  resourceType: string;
  authorityName: string;
}

const flatMap = (xs, f) => xs.reduce((acc, x) => acc.concat(f(x)), []);

const getResourceAuthoritiesFromToken = (idToken): IResourceAuthority[] => {
  return (
    idToken['https://prayerwalk.app/permissions'] &&
    flatMap(Object.entries(idToken['https://prayerwalk.app/permissions']), resourceType => {
      return flatMap(Object.entries(resourceType[1]), resourceId => {
        return resourceId[1].map(authority => {
          return {
            resourceId: parseInt(resourceId[0], 10),
            resourceType: resourceType[0],
            authority,
          };
        });
      });
    })
  );
};

export const hasResourceAuthority = (
  resourceAuthorities: IUserAuthorityResource[],
  requiredResourceAuthorities: IResourceAuthority[]
): boolean => {
  if (resourceAuthorities && resourceAuthorities.length !== 0) {
    if (requiredResourceAuthorities.length === 0) {
      return true;
    }
    return requiredResourceAuthorities.some(auth => _.some(resourceAuthorities, auth));
  }
  return false;
};

// can deduplicate here
export const ResourceAuthorityPrivateRouteComponent = ({ hasAnyAuthorities = [], hasPermissions, children, ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  let requiredResourceAuthorities: IResourceAuthority[] = [];
  if (hasPermissions) {
    requiredResourceAuthorities = useAppSelector(state => hasPermissions(state));
  }
  const isAuthorized =
    hasAnyAuthority(account.authorities, hasAnyAuthorities) ||
    hasResourceAuthority(account.resourceAuthorities, requiredResourceAuthorities);

  if (!children) {
    throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  }

  if (!sessionHasBeenFetched) {
    return <div></div>;
  }

  if (isAuthenticated) {
    if (isAuthorized) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/',
        search: location.search,
      }}
      replace
      state={{ from: location }}
    />
  );
};

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export default ResourceAuthorityPrivateRouteComponent;
