import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

import { getEntities } from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import { changeCurrentPrayerWalkEvent } from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import Navbar from 'app/shared/route/actions-navbar';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ValidatedField, ValidatedForm } from 'react-jhipster';

export const SelectPrayerWalkEventPage = () => {
  const dispatch = useAppDispatch();
  const currentPrayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const prayerWalkEvents = useAppSelector(state => state.prayerWalkEvent.entities);
  const loading = useAppSelector(state => state.prayerWalkEvent.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleValidSubmit = (event, values) => {
    const chosenPrayerWalkEvent = prayerWalkEvents.find(
      prayerWalkEvent => prayerWalkEvent.id === parseInt(values.chosenPrayerWalkEventId, 10)
    );
    dispatch(changeCurrentPrayerWalkEvent(chosenPrayerWalkEvent));
    event.persist();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex-grow-1">
      <Navbar navigateBackTo="/account" title="" />
      <div className="p-3">
        <h2 className="mt-2" id="settings-title">
          Change prayer walk event
        </h2>
        <ValidatedForm id="settings-form" onSubmit={handleValidSubmit}>
          <ValidatedField type="select" name="chosenPrayerWalkEventId" label="Event" value={currentPrayerWalkEvent.id}>
            {prayerWalkEvents.map(prayerWalkEvent => {
              return (
                <option key={prayerWalkEvent.id} value={prayerWalkEvent.id}>
                  {prayerWalkEvent.name}
                </option>
              );
            })}
          </ValidatedField>
          <Button className="bg-primary" type="submit">
            Save
          </Button>
        </ValidatedForm>
      </div>
    </div>
  );
};

export default SelectPrayerWalkEventPage;
