import React, { PropsWithChildren, useMemo } from 'react';
import { Layer, LineLayer, Source } from 'react-map-gl';
import ROAD_STYLE from './road-style.json';
import { IPrayerFeature } from 'app/shared/model/prayer-feature.model';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import { Expression, SymbolLayout } from 'mapbox-gl';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';

export interface IPlacesProps {
  tileUrl: string;
}

export const PlacesVectorSource = (props: PropsWithChildren<IPlacesProps>) => {
  return (
    <Source id="places" type="vector" tiles={[props.tileUrl]} minzoom={0} maxzoom={22}>
      {props.children}
    </Source>
  );
};

export const Places = (props: IPlacesProps) => {
  return (
    <PlacesVectorSource {...props}>
      <PlacesNamesLayer />
    </PlacesVectorSource>
  );
};

export const PlacesNamesLayer = props => {
  const layout: SymbolLayout = useMemo(
    () => ({
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Regular'],
      'text-max-width': 7,
      'text-padding': 3,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.5, 0, 1, 1],
        ['zoom'],
        11,
        ['match', ['get', 'place'], 'city', 14, 'town', 13, 'village', 11, 'suburb', 11, 10.5],
        15,
        ['match', ['get', 'place'], 'city', 20, 'town', 19, 'village', 17, 'suburb', 17, 16],
      ],
      'symbol-sort-key': [
        'match',
        ['get', 'place'],
        'city',
        1,
        'town',
        2,
        'village',
        4,
        'suburb',
        4,
        'quarter',
        5,
        'neighbourhood',
        6,
        'locality',
        7,
        8,
      ],
    }),
    []
  );
  const filter = useMemo(() => props.filter || ['literal', true], [props.filter]);
  const paint = useMemo(
    () => ({
      'text-color': 'hsl(220, 30%, 0%)',
      'text-halo-color': 'hsl(20, 25%, 100%)',
      'text-halo-width': 1,
      'text-halo-blur': 1,
      ...props.paint,
    }),
    [props.paint]
  );
  return (
    <Layer
      source="places"
      source-layer="public.prayer_need_places"
      type={'symbol'}
      id={'places'}
      layout={layout}
      filter={filter}
      paint={paint}
      beforeId="z4"
    />
  );
};

export const SelectedPlace = (props: IPlacesProps & { selectedPlacePrayerFeature?: IPrayerFeature }) => {
  const layout: SymbolLayout = useMemo(
    () => ({
      'icon-image': 'marker',
      'text-field': ['get', 'name'],
      'icon-offset': [0, -3.75],
      'icon-anchor': 'bottom',
      'icon-size': 2,
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Regular'],
      'text-max-width': 7,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.5, 0, 1, 1],
        ['zoom'],
        11,
        ['match', ['get', 'place'], 'city', 17, 'town', 16, 'village', 14, 'suburb', 14, 13.5],
        15,
        ['match', ['get', 'place'], 'city', 24, 'town', 23, 'village', 21, 'suburb', 21, 20],
      ],
    }),
    []
  );
  const filter = useMemo(
    () => (props.selectedPlacePrayerFeature ? ['==', props.selectedPlacePrayerFeature.osmId, ['get', 'osm_id']] : ['literal', false]),
    [props.selectedPlacePrayerFeature]
  );
  const paint = useMemo(
    () => ({
      'text-color': 'hsl(220, 30%, 0%)',
      'text-halo-color': 'hsl(20, 25%, 100%)',
      'text-halo-width': 1,
      'text-halo-blur': 1,
    }),
    []
  );
  return (
    <PlacesVectorSource {...props}>
      {props.selectedPlacePrayerFeature && props.selectedPlacePrayerFeature.osmId && (
        <Layer
          source="places"
          source-layer="public.prayer_need_places"
          type={'symbol'}
          id={'selected-place'}
          layout={layout}
          filter={filter}
          paint={paint}
        />
      )}
    </PlacesVectorSource>
  );
};

export type PrayerNeedsGroupedByOsmId = { [osmId: string]: IPrayerNeed[] };
export interface PlacesWithPrayerNeedsProps {
  prayerNeeds: PrayerNeedsGroupedByOsmId;
  selectedPrayerNeedPlaceOsmId: string | undefined;
}

export const PlacesWithPrayerNeeds = (props: IPlacesProps & PlacesWithPrayerNeedsProps) => {
  // const textOpacityExpression: number | Expression = props.selectedPrayerNeedPlaceOsmId
  //   ? ['case', ['==', props.selectedPrayerNeedPlaceOsmId, ['get', 'osm_id']], 1, 0.2]
  //   : 1;

  const layout: SymbolLayout = useMemo(
    () => ({
      'text-field': [
        'concat',
        ['to-string', ['length', ['get', ['to-string', ['get', 'osm_id']], ['literal', props.prayerNeeds]]]],
        '\n',
        ['get', 'name'],
      ],
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
      'text-max-width': 7,
      'text-padding': 3,
      'text-size': [
        'interpolate',
        ['cubic-bezier', 0.5, 0, 1, 1],
        ['zoom'],
        11,
        ['match', ['get', 'place'], 'city', 14, 'town', 13, 'village', 11, 'suburb', 11, 10.5],
        15,
        ['match', ['get', 'place'], 'city', 20, 'town', 19, 'village', 17, 'suburb', 17, 16],
      ],
      'symbol-sort-key': [
        'match',
        ['get', 'place'],
        'city',
        1,
        'town',
        2,
        'village',
        4,
        'suburb',
        4,
        'quarter',
        5,
        'neighbourhood',
        6,
        'locality',
        7,
        8,
      ],
    }),
    [props.prayerNeeds]
  );
  const filter = useMemo(() => ['has', ['to-string', ['get', 'osm_id']], ['literal', props.prayerNeeds]], [props.prayerNeeds]);
  const paint = useMemo(
    () => ({
      'text-color': 'hsl(220, 30%, 0%)',
      'text-halo-color': 'hsl(20, 25%, 100%)',
      // 'text-halo-color': 'hsla(35, 19%, 100%, 0.75)',
      'text-halo-width': 1,
      // 'text-color': 'hsl(26,100%,56%)',
      'text-halo-blur': 1,
    }),
    []
  );
  return (
    <PlacesVectorSource {...props}>
      {/* <Layer */}
      {/*   source="places" */}
      {/*   source-layer="public.prayer_need_places" */}
      {/*   type={'symbol'} */}
      {/*   id={'prayer-need-count'} */}
      {/*   filter={['has', ['to-string', ['get', 'osm_id']], ["literal", props.prayerNeeds]]} */}
      {/*   layout={{ */}
      {/*     'text-field': ['to-string', ['length', ['get', ['to-string', ['get', 'osm_id']], ["literal", props.prayerNeeds]]]], */}
      {/*     "text-font": ["DIN Pro Regular", "Arial Unicode MS Regular"], */}
      {/*     'icon-image': 'circle-white-2', */}
      {/*     'icon-text-fit': 'height', */}
      {/*     "text-size": [ */}
      {/*       "interpolate", */}
      {/*       ["cubic-bezier", 0.5, 0, 1, 1], */}
      {/*       ["zoom"], */}
      {/*       11, */}
      {/*       ["match", ["get", "place"], "suburb", 11, 10.5], */}
      {/*       15, */}
      {/*       ["match", ["get", "place"], "suburb", 17, 16] */}
      {/*     ] */}
      {/*   }} */}
      {/*   paint={{'icon-translate': [0, -23], 'text-translate': [0, -23]}} */}
      {/* /> */}
      <PlacesNamesLayer filter={['!', ['has', ['to-string', ['get', 'osm_id']], ['literal', props.prayerNeeds]]]} />
      <Layer
        source="places"
        source-layer="public.prayer_need_places"
        type={'symbol'}
        id={'prayer-need-place-and-count'}
        layout={layout}
        filter={filter}
        paint={paint}
        beforeId="z5"
      />
    </PlacesVectorSource>
  );
};
