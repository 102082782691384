import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import {serializeAxiosError} from "app/shared/reducers/reducer.utils";
import {IParticipant} from "app/modules/participants/participants.types";

const initialState: {
  loading: boolean,
  errorMessage?: string,
  entities
} = {
  loading: true,
  errorMessage: null,
  entities: [],
};

export const getParticipants = createAsyncThunk(
  'participants/fetch',
  async (id: string | number) => {
    const requestUrl = `/api/prayer-walk-events/${id}/participants`;
    return axios.get<{participants: IParticipant[]}>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


export const ParticipantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getParticipants), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getParticipants), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data.participants,
        };
      })
  }
});


// export const {  } = ParticipantsSlice.actions;

// Reducer
export default ParticipantsSlice.reducer;
