import React from 'react';
import { Box } from '@chakra-ui/react'

interface ILegendItemProps {
  colour: string;
  description: string;
}

export const LegendItem = (props: ILegendItemProps) => (
  <span className="d-flex align-items-center">
    <hr
      style={{
        width: '30px',
        borderTop: 'solid 5px ' + props.colour,
        margin: 0,
        marginRight: '5px',
        marginLeft: 0,
        opacity: 1,
      }}
    />
    <Box fontSize='md'>{props.description}</Box>
  </span>
);
