import './home.scss';

import React, { useEffect, useState } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'app/config/store';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { EventAwareLogo } from 'app/modules/home/event-aware-logo';
import HomeScreenBackground from 'app/modules/home/home-screen-background';
import { Box, Flex } from '@chakra-ui/react';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import Activate from 'app/modules/account/activate/activate';
import { ForgottenPasswordFinishForm, ForgottenPasswordForm } from 'app/modules/account/password-reset/password-reset-init';
import { RegisterForm, RegistrationSuccess } from 'app/modules/account/register/register';
import { LoginForm } from '../login/login-form';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);
  const isLoading = useAppSelector(state => state.authentication.loading);
  const prayerWalkEvent = useCurrentEvent();
  const location = useLocation();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { from } = location.state || { from: { pathname: '/map', search: location.search } };
  if (account && account.login) {
    return <Navigate to={from} replace />;
  }

  return (
    <>
      {account && account.login ? (
        <Navigate to="/map" />
      ) : (
        <>
          <HomeScreenBackground {...prayerWalkEvent.settings.content.homeScreenBackground} />
          <Flex id="anonymous-home-container" flexGrow={1} flexDirection="column" alignItems="center" px={4}>
            <Box py={[4, 8]} width="full" maxW="xl">
              <EventAwareLogo transparentBackground prayerWalkEvent={prayerWalkEvent} />
            </Box>
            <Flex width="full" justifyContent="center">
              <Box width="full" maxW="xl" bg={'white'} py={{ base: '8', md: '12' }} px={{ base: '6', md: '12' }} shadow="md" rounded="lg">
                <ErrorBoundaryRoutes>
                  <Route path="/forgotten-password/finish" element={<ForgottenPasswordFinishForm />} />
                  <Route path="/forgotten-password" element={<ForgottenPasswordForm />} />
                  <Route path="/registration-success" element={<RegistrationSuccess />} />
                  <Route path="/register" element={<RegisterForm />} />
                  <Route path="/account-activate" element={<Activate loginUrl="/" />} />
                  <Route path="/" element={<LoginForm socialLoginEnabled={prayerWalkEvent.settings.features.socialLoginEnabled} />} />
                </ErrorBoundaryRoutes>
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default Home;
