import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { Places, PlacesWithPrayerNeeds, PrayerNeedsGroupedByOsmId, SelectedPlace } from 'app/modules/map/layers/places';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import { getEntities } from 'app/entities/prayer-need/prayer-need.reducer';
import { Card, CardBody, VStack, Text, CloseButton, Box, CardHeader, Spacer, Avatar, Flex, Button, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { PrayedForButton, PrayedForCount } from 'app/shared/components/prayed-for-button';

export const PrayerNeedCardsForPlace = () => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const prayerNeeds = useAppSelector(state => state.prayerNeed.entities) as IPrayerNeed[];
  const currentUserLogin = useAppSelector(state => state.authentication.account.login);
  const params = useParams();
  const navigate = useNavigate();
  const selectedPlaceOsmId = params.id;

  useEffect(() => {
    dispatch(getEntities({ prayerWalkEventId: prayerWalkEvent.id }));
  }, []);

  const handleClosePopup = useCallback(() => {
    navigate('/map');
  }, []);

  const prayerNeedsGroupedByOsmId: PrayerNeedsGroupedByOsmId = useMemo(() => {
    return prayerNeeds.reduce((acc: PrayerNeedsGroupedByOsmId, curr: IPrayerNeed) => {
      if (acc[curr.prayerFeature.osmId]) {
        acc[curr.prayerFeature.osmId].push(curr);
      } else {
        acc[curr.prayerFeature.osmId] = [curr];
      }
      return acc;
    }, {});
  }, [prayerNeeds]);

  return (
    <>
      {prayerNeedsGroupedByOsmId[selectedPlaceOsmId] &&
        prayerNeedsGroupedByOsmId[selectedPlaceOsmId].map((prayerNeed: IPrayerNeed) => {
          return (
            <Card bg="white" key={prayerNeed.id} minWidth="300px" data-testid="prayerNeedCard">
              <CardBody>
                <VStack alignItems="start" spacing={3}>
                  <Flex direction="row" alignItems="center" w="full">
                    <Avatar name={prayerNeed.user.firstName} src={prayerNeed.user.imageUrl} mx={2} />
                    <VStack spacing={0} alignItems="start">
                      <HStack spacing={2}>
                        <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
                          {prayerNeed.category || 'general'}
                        </Box>
                        <PrayedForCount prayerNeed={prayerNeed} />
                      </HStack>
                      <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs">
                        {dayjs(prayerNeed.createdDate).fromNow()}
                      </Box>
                    </VStack>
                    <Spacer />
                    <CloseButton onClick={handleClosePopup} />
                  </Flex>
                  <Box fontSize="lg" data-testid="prayerNeedText">
                    {prayerNeed.prayerNeedText}
                  </Box>
                  <HStack spacing={2}>
                    <PrayedForButton prayerNeed={prayerNeed} />
                    {prayerNeed.user.login === currentUserLogin && (
                      <Button
                        leftIcon={<EditIcon />}
                        bgColor="gray.100"
                        size="sm"
                        onClick={() => navigate(`/map/prayer-need/${prayerNeed.id}/edit`)}
                      >
                        Edit
                      </Button>
                    )}
                  </HStack>
                </VStack>
              </CardBody>
            </Card>
          );
        })}
    </>
  );
};
