import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Box, Button, Heading, VStack, HStack, useRadioGroup, useRadio, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { getCheckoutSession } from 'app/modules/organisation/reducers/checkout-session.reducer';

type BillingPeriod = 'Monthly' | 'Yearly';

export const RenewSubscription = () => {
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('Monthly');

  return (
    <VStack spacing={8}>
      <PricingHeader
        onBillingPeriodChange={newBillingPeriod => {
          setBillingPeriod(newBillingPeriod);
        }}
      />
      <PricingTable billingPeriod={billingPeriod} />
    </VStack>
  );
};

interface IPricingHeaderProps {
  onBillingPeriodChange: (newBillingPeriod: BillingPeriod) => void;
}

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="md"
        fontWeight="medium"
        _checked={{
          bg: 'primary.500',
          color: 'white',
          boxShadow: 'md',
        }}
        px={8}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

const PricingHeader = ({ onBillingPeriodChange }: IPricingHeaderProps) => {
  const options: Array<BillingPeriod> = ['Monthly', 'Yearly'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'billingPeriod',
    defaultValue: 'Monthly',
    onChange: onBillingPeriodChange,
  });

  const group = getRootProps();

  return (
    <VStack alignItems="center" spacing={8}>
      <Heading as="h1">Choose your plan</Heading>
      <Box display="inline-flex" borderRadius="md" bg="gray.200" alignItems="center" p={1} {...group}>
        {options.map(value => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </Box>
    </VStack>
  );
};

interface IPricingCardProps {
  billingPeriod: BillingPeriod;
  loading: boolean;
  onClickSubscribe: () => void;
  planName: string;
  features: string[];
  monthlyPrice: string;
  yearlyPrice: string;
}

const PricingCard = ({ billingPeriod, loading, onClickSubscribe, planName, features, monthlyPrice, yearlyPrice }: IPricingCardProps) => {
  return (
    <VStack p={8} alignItems="flex-start" spacing={3}>
      <Box fontWeight="bold" fontSize="xl" mb={8}>
        {planName}
      </Box>
      <HStack alignItems="flex-end">
        <Box fontWeight="bold" fontSize="4xl" lineHeight={1}>
          {billingPeriod === 'Monthly' ? monthlyPrice : yearlyPrice}
        </Box>
        <Box color="gray.400">per {billingPeriod === 'Monthly' ? 'month' : 'year'}</Box>
      </HStack>
      <Button
        id={`btn-subscribe-${planName}`}
        borderRadius="md"
        w="full"
        colorScheme="primary"
        color="white"
        isLoading={loading}
        onClick={onClickSubscribe}
      >
        Subscribe
      </Button>
      <List spacing={2} py={2} fontWeight="medium" fontSize="sm">
        <ListItem>This includes:</ListItem>
        {features.map((feature, index) => (
          <ListItem key={index}>
            <ListIcon as={CheckCircleIcon} color="gray.500" />
            {feature}
          </ListItem>
        ))}
      </List>
    </VStack>
  );
};

interface IPricingTableProps {
  billingPeriod: BillingPeriod;
}

export const PricingTable = ({ billingPeriod }: IPricingTableProps) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.checkoutSession.loading);
  const checkoutSessionUrl = useAppSelector(state => state.checkoutSession.checkoutSession.url);

  useEffect(() => {
    if (checkoutSessionUrl) {
      window.location.href = checkoutSessionUrl;
    }
  }, [checkoutSessionUrl]);

  const onClickSubscribe = useCallback(
    subscriptionLevel => () => {
      dispatch(getCheckoutSession({ billingPeriod, subscriptionLevel }));
    },
    [billingPeriod]
  );

  return (
    <HStack alignItems="start" bg="white" borderRadius="md" id="pricingTable">
      <PricingCard
        billingPeriod={billingPeriod}
        loading={loading}
        onClickSubscribe={onClickSubscribe('PREMIUM')}
        planName="Premium"
        features={['Heatmap of prayer walks', 'Prayer needs', 'Progress and participants page', 'One prayer walk event active at a time']}
        monthlyPrice="£50"
        yearlyPrice="£500"
      />
      <PricingCard
        billingPeriod={billingPeriod}
        loading={loading}
        onClickSubscribe={onClickSubscribe('UNLIMITED')}
        planName="Unlimited"
        features={['Everything included in Premium', 'Unlimited active prayer walk events']}
        monthlyPrice="£150"
        yearlyPrice="£1500"
      />
    </HStack>
  );
};
