import React from 'react';
import { Helmet } from 'react-helmet';
import { useAppSelector } from 'app/config/store';

export const EventAwareFavicon = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  return (
    <Helmet>
      <title>{prayerWalkEvent.name}</title>
      <meta name="description" content={`Prayer walking for ${prayerWalkEvent.name}`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Fapple-touch-icon.png?alt=media`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Ffavicon-32x32.png?alt=media`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Ffavicon-16x16.png?alt=media`}
      />
      <link rel="mask-icon" href={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Fsafari-pinned-tab.svg?alt=media`} color="#4fada0" />
      <link rel="shortcut icon" href={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Ffavicon.ico?alt=media`} />
      <meta name="msapplication-TileColor" content={prayerWalkEvent.settings.logos.colour} />
      <meta name="msapplication-config" content={`${prayerWalkEvent.settings.logos.faviconFolderUrl}%2Fbrowserconfig.xml?alt=media`} />
      <meta name="theme-color" content={prayerWalkEvent.settings.logos.backgroundColour} />
    </Helmet>
  );
};

export default EventAwareFavicon;
