import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'app/config/store';
import { Box, Flex, VStack, Button, Select, chakra, FormControl, FormErrorMessage, Heading, Link } from '@chakra-ui/react';
import { AUTHORITIES } from 'app/config/constants';
import {
  createEntity,
  getEntity,
  reset as resetUserAuthorityResource,
  updateEntity,
} from 'app/entities/user-authority-resource/user-authority-resource.reducer';
import { useForm } from 'react-hook-form';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { FormField } from 'app/shared/components/form-field';
import { useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import { IUserAuthorityResource } from 'app/shared/model/user-authority-resource.model';
import {
  createUserAndInviteToOrganisation,
  reset as resetCreateUserAndInviteToOrganisation,
} from 'app/modules/organisation/reducers/create-user-and-invite-organisation.reducer';

interface FormValues {
  userEmail?: string | null;
  login?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  authorityName?: string | null;
}

export const OrganisationUserPermissionUpdate = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const userAuthorityResource: IUserAuthorityResource = useAppSelector(state => state.userAuthorityResource.entity);
  const organisation = useUsersOrganisation();
  const navigate = useNavigate();
  const [isCreatingUser, setCreatingUser] = useState(false);

  const updateSuccess = useAppSelector(state => state.userAuthorityResource.updateSuccess);
  const updateSuccessForCreateUserAndInvite = useAppSelector(state => state.createUserAndInviteToOrganisation.updateSuccess);
  const errorMessage = useAppSelector(state => state.userAuthorityResource.errorMessage);

  const handleClose = () => {
    navigate('/organisation/user-permissions' + location.search);
  };

  useEffect(() => {
    dispatch(resetCreateUserAndInviteToOrganisation());
    if (isNew) {
      dispatch(resetUserAuthorityResource());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateSuccessForCreateUserAndInvite) {
      handleClose();
    }
  }, [updateSuccessForCreateUserAndInvite]);

  const useFormMethods = useForm({ mode: 'onTouched' });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useFormMethods;

  useEffect(() => {
    if (errorMessage === 'User not found') {
      setError('userEmail', { type: 'custom', message: 'User not found' });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!isNew) {
      reset({
        ...userAuthorityResource,
      });
    }
  }, [userAuthorityResource]);

  const saveEntity = handleSubmit((values: FormValues) => {
    if (isCreatingUser) {
      dispatch(
        createUserAndInviteToOrganisation({
          user: {
            login: values.login,
            email: values.userEmail,
            firstName: values.firstName,
            lastName: values.lastName,
            authorities: [AUTHORITIES.USER],
          },
          authorityName: values.authorityName,
        })
      );
    } else {
      const entity: IUserAuthorityResource = {
        ...userAuthorityResource,
        ...values,
        resourceType: 'organisation',
        resourceId: organisation.id,
      };

      if (isNew) {
        dispatch(createEntity(entity));
      } else {
        dispatch(updateEntity(entity));
      }
    }
  });

  return (
    <VStack w="full" alignItems="flex-start" spacing={4}>
      <Heading>Grant permission to a user</Heading>
      <Box p={8} bg="white" borderRadius="md" w="full">
        <chakra.form w="full">
          <Flex rowGap={6} direction="column" alignItems="center" w="full">
            <FormField
              label="User email"
              errors={errors}
              {...register('userEmail', {
                required: 'Email cannot be empty',
              })}
            />
            {errors['userEmail'] && errors['userEmail'].message && (
              <>
                <Link onClick={() => setCreatingUser(true)}>Create user and invite them to this organisation?</Link>
              </>
            )}
            {isCreatingUser && (
              <>
                <FormField
                  label="Username"
                  errors={errors}
                  placeholder="Their username"
                  isRequired={true}
                  {...register('login', {
                    required: { value: true, message: 'Your username is required.' },
                    pattern: {
                      value: /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/, // email address or username
                      message:
                        "Their username can be an email address (if they're ok for that to be publicly viewable) or any combination of letters, numbers, - _ . or @. Your username should not contain any spaces.",
                    },
                    minLength: { value: 1, message: 'Your username is required to be at least 1 character.' },
                    maxLength: { value: 50, message: 'Your username cannot be longer than 50 characters.' },
                  })}
                  helperText="This will be publicly visible"
                  data-cy="username"
                />

                <FormField
                  label="First name"
                  errors={errors}
                  isRequired={true}
                  placeholder="Their first name"
                  {...register('firstName', {
                    required: { value: true, message: 'Their first name is required.' },
                    maxLength: { value: 50, message: 'Their first name cannot be longer than 50 characters.' },
                  })}
                  data-cy="firstName"
                />

                <FormField
                  label="Last name"
                  errors={errors}
                  isRequired={false}
                  placeholder="Their last name"
                  {...register('lastName', {
                    maxLength: {
                      value: 50,
                      message: 'Their last name cannot be longer than 50 characters.',
                    },
                  })}
                  data-cy="lastName"
                />
              </>
            )}
            <FormControl isInvalid={errors.authorityName !== undefined}>
              <Select
                placeholder="Permission"
                id="authorityName"
                {...register('authorityName', {
                  required: 'You must select a permission',
                })}
              >
                <option value={AUTHORITIES.ORG_MODERATOR}>Moderator - approves or rejects user content</option>
                <option value={AUTHORITIES.ORG_EVENT_MANAGER}>
                  Prayer walk event manager - manages prayer walk events and prayer walks
                </option>
                <option value={AUTHORITIES.ORG_ADMIN}>Organisation Admin - manages billing and user permissions</option>
              </Select>
              <FormErrorMessage>{errors.authorityName && errors.authorityName.message.toString()}</FormErrorMessage>
            </FormControl>
            <Button type="submit" w="full" colorScheme="primary" size="lg" onClick={saveEntity}>
              Save
            </Button>
          </Flex>
        </chakra.form>
      </Box>
    </VStack>
  );
};
