import useShouldShowPrompt from 'app/shared/hooks/useShouldShowPrompt';

const iosInstallPromptedAt = 'iosInstallPromptedAt';

const isIOS = (): boolean => {
  if ((navigator as any).standalone) {
    return false;
  }
  const ua = window.navigator.userAgent;
  const isIPad = !!/iPad/i.exec(ua);
  const isIPhone = !!/iPhone/i.exec(ua);
  return isIPad || isIPhone;
};

const useIosInstallPrompt = (): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt);

  return [isIOS() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
export default useIosInstallPrompt;
