import { IUser } from 'app/shared/model/user.model';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { IPrayerFeature } from 'app/shared/model/prayer-feature.model';

export interface IPrayerNeed {
  id?: number;
  prayerNeedText?: string | null;
  category?: string | null;
  moderationStatus?: string | null;
  user?: IUser | null;
  prayerWalkEvent?: IPrayerWalkEvent | null;
  prayerFeature?: IPrayerFeature | null;
  prayedForCount?: number | null;
  prayedForByCurrentUser?: boolean | null;
  createdDate?: Date | null;
  lastModifiedDate?: Date | null;
}

export const defaultValue: Readonly<IPrayerNeed> = {};
