import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPrayerEventChallenge, defaultValue } from 'app/shared/model/prayer-event-challenge.model';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';

export type PrayerEventChallengeState = EntityState<IPrayerEventChallenge> & {
  byId: Record<number, { updating: boolean; updateSuccess: boolean }>;
};
const initialState: PrayerEventChallengeState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  byId: {
    '0': {
      updating: false,
      updateSuccess: false,
    },
  },
};

const apiUrl = prayerWalkEventId => `api/prayer-walk-events/${prayerWalkEventId}/prayer-event-challenges`;
const userSpecificApiUrl = prayerWalkEventId => `api/user/prayer-walk-events/${prayerWalkEventId}/prayer-event-challenges`;

// Actions

export const getEntities = createAsyncThunk(
  'prayerEventChallenge/fetch_entity_list',
  async ({ prayerWalkEventId, page, size, sort }: IQueryParams & { prayerWalkEventId: string | number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}?${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IPrayerEventChallenge[]>(requestUrl);
  }
);

export const getEntitiesForUser = createAsyncThunk(
  'prayerEventChallenge/fetch_entity_list_for_user',
  async ({ prayerWalkEventId }: { prayerWalkEventId: string | number }) => {
    const requestUrl = `${userSpecificApiUrl(prayerWalkEventId)}`;
    return axios.get<IPrayerRoute[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'prayerEventChallenge/fetch_entity',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: string | number; id: string | number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}/${id}`;
    return axios.get<IPrayerEventChallenge>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'prayerEventChallenge/create_entity',
  async ({ prayerWalkEventId, entity }: { prayerWalkEventId: string | number; entity: IPrayerEventChallenge }, thunkAPI) => {
    const result = await axios.post<IPrayerEventChallenge>(apiUrl(prayerWalkEventId), cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'prayerEventChallenge/update_entity',
  async ({ prayerWalkEventId, entity }: { prayerWalkEventId: string | number; entity: IPrayerEventChallenge }, thunkAPI) => {
    const result = await axios.put<IPrayerEventChallenge>(`${apiUrl(prayerWalkEventId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'prayerEventChallenge/partial_update_entity',
  async ({ prayerWalkEventId, entity }: { prayerWalkEventId: string | number; entity: IPrayerEventChallenge }, thunkAPI) => {
    const result = await axios.patch<IPrayerEventChallenge>(`${apiUrl(prayerWalkEventId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const completePrayerEventChallenge = createAsyncThunk(
  'prayerEventChallenge/complete_entity',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: string | number; id: string | number }, thunkAPI) => {
    const result = await axios.post<IPrayerEventChallenge>(`${apiUrl(prayerWalkEventId)}/${id}/completed`);
    thunkAPI.dispatch(getEntitiesForUser({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'prayerEventChallenge/delete_entity',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: string | number; id: string | number }, thunkAPI) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}/${id}`;
    const result = await axios.delete<IPrayerEventChallenge>(requestUrl);
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PrayerEventChallengeSlice = createEntitySlice({
  name: 'prayerEventChallenge',
  initialState,
  reducers: {
    resetById(state, action) {
      state.byId = {
        ...state.byId,
        [action.payload]: {
          updating: false,
          updateSuccess: false,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesForUser), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, completePrayerEventChallenge), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntitiesForUser), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(partialUpdateEntity, completePrayerEventChallenge), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isPending(createEntity), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.byId = {
          ...state.byId,
          0: {
            updating: true,
            updateSuccess: false,
          },
        };
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.byId = {
          ...state.byId,
          0: {
            updating: false,
            updateSuccess: true,
          },
        };
      })
      .addMatcher(isPending(updateEntity), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.byId = {
          ...state.byId,
          [action.meta.arg.entity.id]: {
            updating: true,
            updateSuccess: false,
          },
        };
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.byId = {
          ...state.byId,
          [action.meta.arg.entity.id]: {
            updating: false,
            updateSuccess: true,
          },
        };
      })
      .addMatcher(isPending(deleteEntity), (state, action) => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.byId = {
          ...state.byId,
          [action.meta.arg.id]: {
            updating: true,
            updateSuccess: false,
          },
        };
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
        state.byId = {
          ...state.byId,
          [action.meta.arg.id]: {
            updating: false,
            updateSuccess: true,
          },
        };
      });
  },
});

export const { reset, resetById } = PrayerEventChallengeSlice.actions;

// Reducer
export default PrayerEventChallengeSlice.reducer;
