import React from 'react';
import { Outlet, useMatch, useResolvedPath } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';
import OrganisationLogin from 'app/modules/organisation/initial/initial-page';
import { IStepConfigs, PrayerWalkEventStepper } from 'app/shared/components/prayerWalkEventStepper';

const onboardStepConfigs: IStepConfigs = {
  configs: [
    {
      title: 'Create user',
      url: 'create-account',
    },
    {
      title: 'Verify email',
      url: 'create-account-success',
    },
    {
      title: 'Organisation details',
      url: 'organisation-details',
    },
    {
      title: 'Choose subscription',
      url: 'choose-subscription',
    },
  ],
  terminalStepUrl: 'subscription-success',
};

export const OrganisationOnboard = () => {
  const chooseSubscriptionPath = useResolvedPath('choose-subscription');
  const chooseSubscriptionPathMatch = useMatch({ path: chooseSubscriptionPath.pathname, end: true });

  return (
    <Flex id="anonymous-home-container" flexGrow={1} flexDirection="column" alignItems="center">
      <PrayerWalkEventStepper stepConfigs={onboardStepConfigs} />
      <Flex width="full" justifyContent="center" px={4}>
        <Box
          width="full"
          maxW={chooseSubscriptionPathMatch ? '4xl' : '2xl'}
          bg={'white'}
          py="8"
          px={{ base: '6', md: '8' }}
          mb={8}
          shadow="md"
          rounded="lg"
        >
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default OrganisationLogin;
