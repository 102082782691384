import { Feature, FeatureCollection } from 'geojson';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { IHighlightedRoad } from 'app/modules/map/types';

export const newFeatureCollection = (features: Array<Feature> = []): FeatureCollection => {
  return {
    features,
    type: 'FeatureCollection'
  };
}

export const createNewHighlightedRoad = (id = 'new', colour, isOtherRouteSelected = false): IHighlightedRoad => ({
  id,
  colour,
  geoJson: newFeatureCollection(),
  opacity: isOtherRouteSelected ? 0.2 : 1
});

export const transformToHighlightedRoad = (prayerRoute: IPrayerRoute, completedColour: string,
                                           earmarkedColour: string):
  IHighlightedRoad => ({
    id: `${prayerRoute.id}`,
    colour: prayerRoute.completed ? completedColour : earmarkedColour,
    geoJson: prayerRoute.route,
    opacity: 1
  });

export const combineFeaturesIntoTwoSetsOfHighlightedRoutes = (routes: IPrayerRoute[], completedColour: string, earmarkedColour: string, isOtherRouteSelected = false): IHighlightedRoad[] =>
  routes.reduce<IHighlightedRoad[]>(
    (acc, next) => {
      const earmarkedRoutes = acc[0];
      const completedRoutes = acc[1];
      const route = next.route;
      let features: Feature[];
      if (route.type === 'FeatureCollection') {
        features = route.features;
      }
      // if (route.type === 'Feature') {
      //   features = [route];
      // }
      if (!features || features.some(feature => !feature.geometry)) {
        return [earmarkedRoutes, completedRoutes];
      }
      if (next.completed) {
        completedRoutes.geoJson.features = completedRoutes.geoJson.features.concat(features);
        return [earmarkedRoutes, completedRoutes];
      } else {
        earmarkedRoutes.geoJson.features = earmarkedRoutes.geoJson.features.concat(features);
        return [earmarkedRoutes, completedRoutes];
      }
    },
    [createNewHighlightedRoad('earmarked', earmarkedColour, isOtherRouteSelected), createNewHighlightedRoad('completed', completedColour, isOtherRouteSelected)]
  );

export const combineFeaturesIntoOneFeatureCollection = (routes: IPrayerRoute[]): FeatureCollection =>
  routes.reduce<FeatureCollection>(
    (featureCollection, next) => {
      const route = next.route;
      let features: Feature[];
      if (route.type === 'FeatureCollection') {
        features = route.features;
      }
      // if (route.type === 'Feature') {
      //   features = [route];
      // }
      features = features.map(feature => {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            completed: next.completed,
            completed_at: next.completedAt,
            prayer_route_id: next.id,
          }
        };
      });
      if (!features || features.some(feature => !feature.geometry)) {
        return featureCollection;
      }
      return newFeatureCollection([].concat(featureCollection.features, features))
    },
    newFeatureCollection()
  );
