import './header.scss';

import React, { useState } from 'react';

import { Navbar, Nav } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home } from './header-components';
import { MoreMenu, AdminMenu, HowToMenu, PrayerRouteMenu } from '../menus';
import { faQuestionCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressMenu } from 'app/shared/layout/menus/participants';
import { SimpleGrid } from '@chakra-ui/react';
import { IPrayerWalkEventSettings } from 'app/shared/model/prayer-walk-event.model';
import { PrayerNeedsMenu } from 'app/shared/layout/menus/prayer-needs';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isEventManager: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  isVisible: boolean;
  prayerWalkEventSettings: IPrayerWalkEventSettings;
}

const Header = (props: IHeaderProps) => {
  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  if (!props.isAuthenticated || !props.isVisible) {
    return null;
  }
  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <nav data-cy="navbar">
        <SimpleGrid id="header-tabs" minChildWidth="60px" bg="gray.800" boxShadow="md">
          {props.prayerWalkEventSettings.features.prayerNeedsEnabled ? <PrayerNeedsMenu /> : <HowToMenu />}
          <PrayerRouteMenu />
          {props.prayerWalkEventSettings.features.participantsListEnabled && <ProgressMenu />}
          {props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
          {props.isAuthenticated && <MoreMenu isAuthenticated={props.isAuthenticated} />}
        </SimpleGrid>
      </nav>
    </div>
  );
};

const HeaderNavItem = ({ title, href, icon }: { title: string; href: string; icon: any }) => (
  <NavItem>
    <NavLink id="about" tag={Link} to={href} activeClassName="active" className="d-flex align-items-center flex-column" replace>
      <FontAwesomeIcon icon={icon} size="lg" />
      <span>{title}</span>
    </NavLink>
  </NavItem>
);

export default Header;
