import React, { useState } from 'react';
import { Box, Button, Flex, HStack, Spacer, Text } from '@chakra-ui/react';

export interface IJhiItemCountProps {
  page: number;
  total: number;
  itemsPerPage: number;
  i18nEnabled?: boolean;
}

export const ItemCount = (props: IJhiItemCountProps) => {
  const { page, total, itemsPerPage } = props;
  if (total === 0) {
    return <Box fontSize="sm" color="gray.400">
      Showing 0 items.
    </Box>
  }
  return (
    <Box fontSize="sm" color="gray.400">
      Showing {(page - 1) * itemsPerPage === 0 ? 1 : (page - 1) * itemsPerPage + 1} -{' '}
      {page * itemsPerPage < total ? page * itemsPerPage : total} of {total} items.
    </Box>
  );
};

export interface IPaginationProps {
  activePage: number;
  onSelect: (page: number) => void;
  maxButtons: number;
  totalItems: number;
  itemsPerPage: number;
}

export const Pagination = (props: IPaginationProps) => {
  const previousPage = () => {
    props.onSelect(props.activePage - 1);
  };

  const nextPage = () => {
    props.onSelect(props.activePage + 1);
  };

  return (
    <Flex w="full" justifyContent="center">
      <HStack spacing={4} alignItems="center">
        <ItemCount page={props.activePage} total={props.totalItems} itemsPerPage={props.itemsPerPage} />
        <Button isDisabled={props.activePage === 1} onClick={previousPage}>
          Previous
        </Button>
        <Button isDisabled={props.activePage * props.itemsPerPage >= props.totalItems} onClick={nextPage}>
          Next
        </Button>
      </HStack>
    </Flex>
  );
};
