import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { FeatureCollection } from "geojson";

export interface IPrayerRoute {
  id?: number;
  route?: FeatureCollection | null;
  completed?: boolean | null;
  completedAt?: string | null;
  walkerLogin?: string | null;
  walkerId?: number | null;
  prayerWalkEventId?: number | null;
}

export const defaultValue: Readonly<IPrayerRoute> = {
  completed: false,
  route: {type: 'FeatureCollection', features: []},
};
