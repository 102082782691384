import React from 'react';
import { hasResourceAuthority, IResourceAuthority } from 'app/shared/auth/private-route-per-resource';
import { CurrentPrayerWalkEventState } from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import { AuthenticationState } from 'app/shared/reducers/authentication';
import { UsersOrganisationState } from 'app/modules/organisation/reducers/users-organisation.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { IOrganisation } from 'app/shared/model/organisation.model';

export const authorityToUIFriendlyName = {
  [AUTHORITIES.ORG_ADMIN]: 'Organisation Admin',
  [AUTHORITIES.ORG_EVENT_MANAGER]: 'Event Manager',
  [AUTHORITIES.ORG_MODERATOR]: 'Moderator',
};

export const permissionInOrganisation = (organisation: IOrganisation, authority: string): IResourceAuthority[] => {
  return [{ resourceType: 'organisation', resourceId: organisation.id, authorityName: authority }];
};

export const isEventManagerInUsersOrganisation = (
  authentication: AuthenticationState,
  usersOrganisationState: UsersOrganisationState
): boolean => {
  return hasResourceAuthority(
    authentication.account.resourceAuthorities,
    permissionInOrganisation(usersOrganisationState.usersOrganisation, AUTHORITIES.ORG_EVENT_MANAGER)
  );
};

export const isEventManagerForOrganisation = (authentication: AuthenticationState, organisation: IOrganisation | null): boolean => {
  if (organisation === null) {
    return false;
  }
  return hasResourceAuthority(
    authentication.account.resourceAuthorities,
    permissionInOrganisation(organisation, AUTHORITIES.ORG_EVENT_MANAGER)
  );
};

export const isAdminForOrganisation = (authentication: AuthenticationState, usersOrganisationState: UsersOrganisationState): boolean => {
  return hasResourceAuthority(
    authentication.account.resourceAuthorities,
    permissionInOrganisation(usersOrganisationState.usersOrganisation, AUTHORITIES.ORG_ADMIN)
  );
};

export const isModeratorForOrganisationState = (
  authentication: AuthenticationState,
  usersOrganisationState: UsersOrganisationState
): boolean => {
  return hasResourceAuthority(
    authentication.account.resourceAuthorities,
    permissionInOrganisation(usersOrganisationState.usersOrganisation, AUTHORITIES.ORG_MODERATOR)
  );
};

export const isModeratorForOrganisation = (authentication: AuthenticationState, organisation: IOrganisation): boolean => {
  return hasResourceAuthority(
    authentication.account.resourceAuthorities,
    permissionInOrganisation(organisation, AUTHORITIES.ORG_MODERATOR)
  );
};
