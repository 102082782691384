import React from 'react';
import Navbar from "app/shared/route/actions-navbar";

const DataDeletionInstructions = () => {
  return <div>
    <Navbar title=""/>
    <div className="p-3 d-flex flex-column flex-grow-1">
      <h1>How to delete your Data from The Prayer Walk App</h1>
      <p>Please contact us at <a href="mailto:support@prayerwalk.app">support@prayerwalk.app</a> with
        the subject line &apos;Please delete my data&apos; and the username or email you use to login to The Prayer Walk App
        and we will make sure all personal data and prayer routes are deleted.</p>
    </div>
  </div>
};

export default DataDeletionInstructions
