import React from 'react';
import './video.scss'
import {IHomeScreenBackground} from "app/shared/model/prayer-walk-event.model";

const getVideoSrc = (videoUrl: string, width: number) => {
  if (width >= 1080) return videoUrl;
  if (width >= 400) return videoUrl.replace(/\.mp4/g, '720p.mp4');
  return videoUrl.replace(/\.mp4/g, '720pPortrait.mp4');
};

export default (props: IHomeScreenBackground) => {
  if (props.type === 'IMAGE') {
    return <div className="bg-still" style={{
      backgroundImage: `url("${props.url}")`
    }} />
  }
  return <video autoPlay playsInline muted loop className="bg-video"
                style={{opacity: 0.3}}>
    <source src={getVideoSrc(props.url, window.innerWidth)} type="video/mp4"/>
  </video>;
}
