import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'app/shared/hooks/useInterval';
import * as Sentry from '@sentry/browser';

type UsePollingReturn = {
  startPolling: () => void;
  isPolling: boolean;
  hasTimedOut: boolean;
};

export const usePolling = (
  pollingCall: () => void,
  checkIfDone: () => boolean,
  alertMessage: string,
  pollingInterval = 1000,
  maxPolls = 30
): UsePollingReturn => {
  const [delay, setDelay] = useState<number | null>(null);
  const [currentPollCount, setPollCount] = useState<number>(0);

  // polling
  useInterval(() => {
    pollingCall();
    setPollCount(count => count + 1);
  }, delay);

  const hasTimedOut = useCallback(() => {
    return currentPollCount >= maxPolls;
  }, [currentPollCount, maxPolls]);

  // cancel
  useEffect(() => {
    if (checkIfDone()) {
      setDelay(null);
    }
  }, [checkIfDone]);

  useEffect(() => {
    if (hasTimedOut()) {
      Sentry.captureException(alertMessage, {
        level: 'error',
        tags: {
          alert: 'always',
        },
      });
      setDelay(null);
    }
  }, [hasTimedOut]);

  return {
    startPolling() {
      setDelay(pollingInterval);
    },
    isPolling: delay !== null,
    hasTimedOut: currentPollCount >= maxPolls,
  };
};
