import React, { useCallback, useMemo } from 'react';
import { Layer, Source, LayerProps } from 'react-map-gl';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { combineFeaturesIntoOneFeatureCollection } from 'app/modules/map/utils';

export interface IEarmarkedPrayerRoutesGeoJsonLayerProps {
  prayerRoutes: IPrayerRoute[];
  earmarkedRouteColour: string;
  selectedPrayerRouteId?: string;
  beforeLayerId: string;
}

const EarmarkedPrayerRoutesGeoJsonLayer = (props: IEarmarkedPrayerRoutesGeoJsonLayerProps) => {
  const routes = props.prayerRoutes.filter(route => route.completed === false);
  const featureCollection = combineFeaturesIntoOneFeatureCollection(routes);
  const getLayerProps = useCallback((earmarkedRouteColour: string, selectedPrayerRouteId?: string): LayerProps => {
    return {
      id: `highlighted-roads-my-earmarked-routes`,
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': earmarkedRouteColour, // default,
        'line-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 14, 3, 18, 6],
        'line-opacity': selectedPrayerRouteId
          ? ['case', ['==', parseInt(selectedPrayerRouteId, 10), ['get', 'prayer_route_id']], 1, 0.2]
          : 1,
      },
      beforeId: props.beforeLayerId,
    };
  }, []);
  // const getTappableLayerProps = useCallback((earmarkedRouteColour: string, selectedPrayerRoute?: IPrayerRoute): LayerProps => {
  //   return {
  //     id: `highlighted-roads-my-earmarked-routes-tappable`,
  //     type: 'line',
  //     layout: {
  //       'line-join': 'round',
  //       'line-cap': 'round',
  //     },
  //     paint: {
  //       'line-color': earmarkedRouteColour, // default,
  //       'line-width': ['interpolate', ['linear'], ['zoom'], 10, 12, 14, 16, 18, 20],
  //       'line-opacity': 0.1,
  //     },
  //     beforeId: props.beforeLayerId,
  //   };
  // }, []);

  return (
    <Source id={`highlighted-roads-my-earmarked-routes`} type="geojson" data={featureCollection}>
      {/*<Layer {...getTappableLayerProps(props.earmarkedRouteColour, props.selectedPrayerRoute)} />*/}
      <Layer {...getLayerProps(props.earmarkedRouteColour, props.selectedPrayerRouteId)} />
    </Source>
  );
};

export default EarmarkedPrayerRoutesGeoJsonLayer;
