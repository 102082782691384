import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FormCheckbox, FormField, FormPassword } from 'app/shared/components/form-field';
import { useEffect, useState } from 'react';
import React from 'react';
import { Button, chakra, HStack, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  saveUsersOrganisation,
  reset as resetUsersOrganisation,
  getUsersOrganisation,
} from 'app/modules/organisation/reducers/users-organisation.reducer';
import { useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';

export const CreateOrganisationForm = () => {
  const dispatch = useAppDispatch();
  const updateSuccess = useAppSelector(state => state.usersOrganisation.updateSuccess);
  const navigate = useNavigate();
  const organisation = useUsersOrganisation();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleValidSubmit = handleSubmit(values => {
    dispatch(
      saveUsersOrganisation({
        name: values.organisationName,
      })
    );
  });

  useEffect(() => {
    dispatch(getUsersOrganisation());
    return () => {
      dispatch(resetUsersOrganisation());
    };
  }, []);

  useEffect(() => {
    reset({
      organisationName: organisation ? organisation.name : '',
    });
  }, [organisation]);

  useEffect(() => {
    if (updateSuccess) {
      navigate('../choose-subscription');
    }
  }, [updateSuccess, navigate]);

  return (
    <chakra.form onSubmit={handleValidSubmit} noValidate>
      <Stack spacing="8">
        <FormField
          label="The name of your organisation or church"
          errors={errors}
          placeholder="Organisation name"
          isRequired={true}
          {...register('organisationName', {
            required: { value: true, message: 'Organisation name is required.' },
            minLength: { value: 1, message: 'Organisation name is required to be at least 1 character.' },
            maxLength: { value: 255, message: 'Organisation name cannot be longer than 50 characters.' },
          })}
          data-cy="organisationName"
        />
        <HStack>
          <Button size="lg" type="button" as={Link} to={'../logout'}>
            Exit
          </Button>
          <Button flexGrow={1} data-cy="submit" type="submit" colorScheme="primary" size="lg" isLoading={isSubmitting}>
            Save organisation details
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  );
};
