import * as React from 'react';
import { useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import { useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { Button, Stack, Box } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { getEnv } from 'app/config/env-detector';
import { Environments } from 'app/config/constants';

export const ChooseSubscription = () => {
  const organisation = useUsersOrganisation();
  const email = useAppSelector(state => state.authentication.account.email);
  const env = getEnv();
  return (
    <Box>
      <Button id="back" alignSelf="start" variant="link" as={Link} to={'../organisation-details'} leftIcon={<ChevronLeftIcon />}>
        Back
      </Button>
      <Box>
        {env === Environments.LOCAL && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <stripe-pricing-table
            pricing-table-id="prctbl_1MfrfvLAH0LLz4pAGEN0YJ5r"
            client-reference-id={organisation.id}
            customer-email={email}
            publishable-key="pk_test_51MfrS6LAH0LLz4pAcEbl9zivXwU9NNYKLgbg3KMLJCFI7lOpIADSu71wN9cj8KcHQXhqpjASBtmJSAgFi65FEiDd0058Jqgriz"
          />
        )}
        {env === Environments.STAGING && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <stripe-pricing-table
            pricing-table-id="prctbl_1NdwUBLAH0LLz4pAqEjxVNzR"
            client-reference-id={organisation.id}
            customer-email={email}
            publishable-key="pk_test_51MfrS6LAH0LLz4pAcEbl9zivXwU9NNYKLgbg3KMLJCFI7lOpIADSu71wN9cj8KcHQXhqpjASBtmJSAgFi65FEiDd0058Jqgriz"
          />
        )}
        {env === Environments.PRODUCTION && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <stripe-pricing-table
            pricing-table-id="prctbl_1Mr0ZhLAH0LLz4pAvltlACpb"
            client-reference-id={organisation.id}
            customer-email={email}
            publishable-key="pk_live_51MfrS6LAH0LLz4pAqaM9TgtpDUs0S0Emuloam6kxAX2q0KjaWdlCSFlg2peyeTeuoZFCFjwJ6zfDzYmZjz90fmFi000iiGCoek"
          />
        )}
      </Box>
    </Box>
  );
};
