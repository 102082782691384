import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const PrayerWalkingRouter = ({ children }: React.PropsWithChildren<object>) => {
  return (
    <BrowserRouter basename={baseHref}>
      <div
        className="d-flex flex-column"
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {children}
      </div>
    </BrowserRouter>
  );
};
