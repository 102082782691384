import React from 'react';
// import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavDropdown } from './menu-components';
import { Menu, MenuItem as ChakraMenuItem, MenuList, MenuButton, Box, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const MenuItem = ({ icon, children, to }) => {
  return (
    <Link to={to}>
      <ChakraMenuItem icon={<FontAwesomeIcon icon={icon} fixedWidth />}>{children}</ChakraMenuItem>
    </Link>
  );
};

const adminMenuItems = () => (
  <>
    <MenuItem icon="map" to="/admin/prayer-route">
      Prayer Route management
    </MenuItem>
    <MenuItem icon="map" to="/admin/user-profile">
      User Profile management
    </MenuItem>
    <MenuItem icon="pray" to="/admin/prayer-need">
      Prayer need management
    </MenuItem>
    <MenuItem icon="map" to="/admin/prayer-walk-event">
      Prayer Walk Event management
    </MenuItem>
    <MenuItem icon="user-tag" to="/admin/user-authority-resource">
      User Permissions management
    </MenuItem>
    <MenuItem icon="users" to="/admin/user-management">
      User management
    </MenuItem>
    <MenuItem icon="users" to="/admin/organisation">
      Organisation
    </MenuItem>
    <MenuItem icon="users" to="/admin/payment-subscription">
      Payment Subscription
    </MenuItem>
    <MenuItem icon="users" to="/admin/prayer-event-challenge">
      Prayer event challenge
    </MenuItem>
    <MenuItem icon="tachometer-alt" to="/admin/metrics">
      Metrics
    </MenuItem>
    <MenuItem icon="heart" to="/admin/health">
      Health
    </MenuItem>
    <MenuItem icon="cogs" to="/admin/configuration">
      Configuration
    </MenuItem>
    <MenuItem icon="tasks" to="/admin/logs">
      Logs
    </MenuItem>
    {/* jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here */}
  </>
);

const openAPIItem = () => (
  <MenuItem icon="book" to="/admin/docs">
    API
  </MenuItem>
);

export const AdminMenu = ({ showOpenAPI }) => (
  <Menu id="admin-menu" data-cy="adminMenu">
    <MenuButton>
      {' '}
      <VStack color="gray.400" p={4}>
        <FontAwesomeIcon icon="users-cog" size="lg" />
        <Box fontWeight="semibold">Administration</Box>
      </VStack>
    </MenuButton>
    <MenuList>
      {adminMenuItems()}
      {showOpenAPI && openAPIItem()}
    </MenuList>
  </Menu>
);

export default AdminMenu;
