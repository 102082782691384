import React from 'react';
import { hasResourceAuthority, IResourceAuthority } from 'app/shared/auth/private-route-per-resource';
import { CurrentPrayerWalkEventState } from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import { AuthenticationState } from 'app/shared/reducers/authentication';

export const eventManagerPermissionForCurrentEvent = (currentPrayerWalkEvent: CurrentPrayerWalkEventState): IResourceAuthority[] => {
  return [
    { resourceType: 'prayerWalkEvent', resourceId: currentPrayerWalkEvent.currentPrayerWalkEvent.id, authorityName: 'ROLE_EVENT_MANAGER' },
  ];
};

export const isEventManagerForCurrentEvent = (
  authentication: AuthenticationState,
  currentPrayerWalkEvent: CurrentPrayerWalkEventState
): boolean => {
  return hasResourceAuthority(authentication.account.resourceAuthorities, eventManagerPermissionForCurrentEvent(currentPrayerWalkEvent));
};
