export enum SubscriptionStatus {
  TRIALING = 'TRIALING',

  ACTIVE = 'ACTIVE',

  PAST_DUE = 'PAST_DUE',

  CANCEL_REQUESTED = 'CANCEL_REQUESTED',

  CANCELLED = 'CANCELLED',
}
