import { CheckIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Hide, Stack, Step, StepDescription, StepIndicator,
  StepNumber, Stepper, StepSeparator, StepStatus, StepTitle, Tab, TabList, Tabs, Text, useSteps } from '@chakra-ui/react';
import React from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

export interface IStepConfig {
  title: string;
  url: string;
}
export interface IStepConfigs {
  configs: IStepConfig[];
  terminalStepUrl?: string;
}

const StepIcon = props => {
  let borderColor: string;
  if (props.isFirst) {
    borderColor = 'transparent';
  } else {
    if (props.state === 'complete' || props.state === 'current') {
      borderColor = 'primary.500';
    } else {
      borderColor = 'gray.300';
    }
  }
  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <Divider w="full" borderColor={borderColor} opacity={1} borderWidth="2px" />
      <Flex
        lineHeight={0}
        flexGrow={0}
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        w={8}
        h={8}
        borderRadius="full"
        bg={props.state === 'complete' ? 'primary.500' : 'transparent'}
        borderWidth={2}
        borderColor={props.state === 'complete' || props.state === 'current' ? 'primary.500' : 'gray.300'}
      >
        {props.state === 'complete' ? (
          <CheckIcon boxSize={4} color="white" flexShrink={0} />
        ) : (
          <Box fontWeight="bold" color={props.state === 'current' ? 'primary.500' : 'gray.300'}>
            {props.index + 1}
          </Box>
        )}
      </Flex>
      <Divider
        w="full"
        borderColor={props.isLast ? 'transparent' : props.state === 'complete' ? 'primary.500' : 'gray.300'}
        opacity={1}
        borderWidth="2px"
      />
    </Stack>
  );
};

const StepText = props => {
  return (
    <Hide below="sm">
      <Box textAlign="center" color="emphasised" fontWeight="medium">
        {props.title}
      </Box>
    </Hide>
  );
};

export const PrayerWalkEventStepper = ({ stepConfigs }: { stepConfigs: IStepConfigs }) => {
  let currentStepIndex;
  let urls: string[];
  if (stepConfigs.terminalStepUrl) {
    urls = [...stepConfigs.configs.map(stepConfig => stepConfig.url), stepConfigs.terminalStepUrl];
  } else {
    urls = stepConfigs.configs.map(stepConfig => stepConfig.url);
  }
  urls.map((url, index) => {
    const resolved = useResolvedPath(`${url}`);
    const match = useMatch({ path: resolved.pathname, end: true });
    if (match) {
      currentStepIndex = index;
    }
  });
  // const { activeStep } = useSteps({
  //   index: currentStepIndex,
  //   count: stepConfigs.configs.length,
  // })

  return (
    <Box p={8} w="full">
      {/*<Stepper colorScheme="primary" index={currentStepIndex}>*/}
      {/*  {stepConfigs.configs.map((step, index) => (*/}
      {/*    <Step key={index}>*/}
      {/*      <Stack direction="column" alignItems="center">*/}
      {/*        <StepIndicator>*/}
      {/*          <StepStatus*/}
      {/*            complete={<StepIcon />}*/}
      {/*            incomplete={<StepNumber />}*/}
      {/*            active={<StepNumber />}*/}
      {/*          />*/}
      {/*        </StepIndicator>*/}

      {/*        <Hide below="sm">*/}
      {/*          <Box flexShrink='0'>*/}
      {/*            <StepTitle>{step.title}</StepTitle>*/}
      {/*          </Box>*/}
      {/*        </Hide>*/}
      {/*      </Stack>*/}

      {/*      <StepSeparator />*/}
      {/*    </Step>*/}
      {/*  ))}*/}
      {/*</Stepper>*/}
      <Stack direction="row" spacing={0}>
        {stepConfigs.configs.map((stepConfig, index) => {
          return (
            <Stack key={stepConfig.title} direction="column" flex={1}>
              <StepIcon
                index={index}
                isFirst={index === 0}
                isLast={index === stepConfigs.configs.length - 1}
                state={index === currentStepIndex ? 'current' : index < currentStepIndex ? 'complete' : 'incomplete'}
              />
              <StepText title={stepConfig.title} />
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export const StepsAsTabs = ({ stepConfigs }: { stepConfigs: IStepConfig[] }) => {
  const navigate = useNavigate();
  let currentStepIndex;
  stepConfigs.map((stepConfig, index) => {
    const resolved = useResolvedPath(`${stepConfig.url}`);
    const match = useMatch({ path: resolved.pathname, end: true });
    if (match) {
      currentStepIndex = index;
    }
  });

  const handleTabsChange = index => {
    navigate(`${stepConfigs[index].url}`);
  };

  return (
    <Box pt={8} pb={2} w="full">
      <Tabs index={currentStepIndex} onChange={handleTabsChange}>
        <TabList>
          {stepConfigs.map(stepConfig => {
            return <Tab key={stepConfig.url}>{stepConfig.title}</Tab>;
          })}
        </TabList>
      </Tabs>
    </Box>
  );
};
