import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingSpinner = props => (
  <div className="flex-grow-1 d-flex justify-content-center align-items-center">
    <Spinner color="dark" />
  </div>
);

export default LoadingSpinner;
