import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { Places, PlacesWithPrayerNeeds, PrayerNeedsGroupedByOsmId, SelectedPlace } from 'app/modules/map/layers/places';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import { getEntities } from 'app/entities/prayer-need/prayer-need.reducer';
import {
  Card,
  CardBody,
  VStack,
  Text,
  CloseButton,
  Box,
  CardHeader,
  Spacer,
  Avatar,
  Flex,
  Button,
  HStack,
  Collapse,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faTimes, faQuestionCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { getEntity } from 'app/entities/prayer-route/prayer-route.reducer';
import { isEventManagerForOrganisation } from 'app/modules/organisation/auth/permissions';

export interface IPrayerRouteSelectedInfo {
  longitude: number;
  latitude: number;
  roadName: string;
}

const ShowWhoOwnsThisRoute = (props: { prayerRoute: IPrayerRoute }) => {
  return props.prayerRoute.walkerLogin ? (
    <Box letterSpacing="wide" fontSize="sm">
      {props.prayerRoute.completed ? 'Prayed for by' : 'Earmarked by'} <Box fontWeight="semibold">{props.prayerRoute.walkerLogin}</Box>
    </Box>
  ) : null;
};

export const PrayerRouteCard = () => {
  const prayerWalkEvent = useCurrentEvent();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const account = useAppSelector(state => state.authentication.account);
  const { prayerRouteId } = useParams();
  const prayerRoute = useAppSelector(state => state.prayerRoute.entity);
  const prayerRouteIsLoading = useAppSelector(state => state.prayerRoute.loading);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const isOrgEventManager = useAppSelector(state =>
    isEventManagerForOrganisation(state.authentication, state.currentPrayerWalkEvent.currentPrayerWalkEvent.organisation)
  );
  const currentUserCanModifyThisRoute = useCallback(
    (route: IPrayerRoute) => {
      return account.id === route.walkerId || isOrgEventManager;
    },
    [account, isOrgEventManager]
  );
  const prayerRouteSelectedInfo: IPrayerRouteSelectedInfo = location.state as IPrayerRouteSelectedInfo;

  useEffect(() => {
    dispatch(getEntity({ prayerWalkEventId: prayerWalkEvent.id, id: prayerRouteId }));
  }, [prayerWalkEvent, prayerRouteId]);

  useEffect(() => {
    if (prayerRouteSelectedInfo === null) {
      navigate('/map');
    }
  }, [prayerRouteSelectedInfo]);

  const prayerWalkEventIsDisabled = !prayerWalkEvent.enabled;

  const handleUncompletePrayerRoute = useCallback(() => {
    navigate(`/map/prayer-route/${prayerRoute.id}/uncomplete`);
  }, [prayerRoute]);

  const handleDeletePrayerRoute = useCallback(() => {
    navigate(`/map/prayer-route/${prayerRoute.id}/delete`);
  }, [prayerRoute]);

  const handleCompletePrayerRoute = useCallback(() => {
    navigate(`/map/prayer-route/${prayerRoute.id}/complete`);
  }, [prayerRoute]);

  const handleEditPrayerRoute = useCallback(() => {
    navigate(`/map/prayer-route/${prayerRoute.id}/edit`);
  }, [prayerRoute]);

  const handleClosePopup = useCallback(() => {
    navigate('/map');
  }, []);

  if (prayerRouteSelectedInfo === null || prayerRouteIsLoading) {
    return null;
  }

  return (
    <Card bg="white" minWidth="300px">
      <CardBody>
        <VStack alignItems="start" spacing={2}>
          <Flex direction="row" alignItems="center" w="full">
            <Box fontSize="lg">Prayer walk on {prayerRouteSelectedInfo.roadName}</Box>
            <Spacer />
            <CloseButton onClick={handleClosePopup} />
          </Flex>
          {prayerRoute.completed ? (
            <>
              <Box letterSpacing="wide" fontSize="sm">
                Completed at <Box fontWeight="semibold">{dayjs(prayerRoute.completedAt).format('LLL')}</Box>
              </Box>
              <ShowWhoOwnsThisRoute prayerRoute={prayerRoute} />
              {currentUserCanModifyThisRoute(prayerRoute) ? (
                <Button
                  w="full"
                  id="uncomplete"
                  colorScheme="yellow"
                  onClick={handleUncompletePrayerRoute}
                  disabled={prayerWalkEventIsDisabled}
                  leftIcon={<FontAwesomeIcon icon={faTimes} size="lg" />}
                >
                  Uncomplete
                </Button>
              ) : (
                <>
                  <Collapse in={isOpen} animateOpacity={true}>
                    <Box>
                      <Box fontSize="md">
                        Another user has completed this route.
                        <br />
                        However, you can still earmark and complete the same roads as someone else.
                      </Box>
                    </Box>
                  </Collapse>
                </>
              )}
            </>
          ) : (
            <>
              <Box letterSpacing="wide" fontSize="sm">
                This route hasn&apos;t been completed yet.
              </Box>
              <ShowWhoOwnsThisRoute prayerRoute={prayerRoute} />
              {currentUserCanModifyThisRoute(prayerRoute) ? (
                <HStack>
                  <Button
                    id="complete"
                    onClick={handleCompletePrayerRoute}
                    className="bg-primary text-white"
                    disabled={prayerWalkEventIsDisabled}
                  >
                    <FontAwesomeIcon icon={faCheck} size="sm" className="me-1" />
                    Complete
                  </Button>
                  <Button
                    id="edit"
                    onClick={handleEditPrayerRoute}
                    colorScheme="gray"
                    disabled={prayerWalkEventIsDisabled}
                    leftIcon={<FontAwesomeIcon icon={faEdit} size="sm" />}
                  >
                    Edit
                  </Button>
                  <Button
                    id="delete"
                    colorScheme="red"
                    variant="ghost"
                    onClick={handleDeletePrayerRoute}
                    disabled={prayerWalkEventIsDisabled}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" className="me-1" />
                    Delete
                  </Button>
                </HStack>
              ) : (
                <>
                  <Button variant="link" onClick={toggle}>
                    <FontAwesomeIcon icon={faQuestionCircle} size="lg" className="me-1" />
                    Why can&apos;t I complete it?
                  </Button>
                  <Collapse in={isOpen} animateOpacity={true}>
                    <Box fontSize="md">
                      You cannot complete or delete this route because someone else earmarked it. However,{' '}
                      <Box as="span" fontWeight="bold">
                        you can still earmark and complete the same roads as someone else
                      </Box>{' '}
                      by creating a new route and selecting this road.
                    </Box>
                  </Collapse>
                  {/* <p>If you&apos;ve prayer walked this street, you can mark this road as completed by */}
                  {/*  creating a new route or editing your current one, selecting this road, and then completing it.</p> */}
                </>
              )}
            </>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
