import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import MyCompletedPrayerRoutes from 'app/modules/map/layers/my-routes-geo-json-layer';
import AllPrayerRoutesVectorLayer from 'app/modules/map/layers/all-prayer-routes-vector-layer';
import { newFeatureCollection } from 'app/modules/map/utils';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import PrayerRoutesGeoJsonLayer from 'app/modules/map/layers/prayer-routes-geo-json-layer';
import IdThenGeometryComparingSet from 'app/modules/map/data-structures/id-then-geometry-comparing-set';
import { createEntity, getEntity, reset, updateEntity } from 'app/entities/prayer-need/prayer-need.reducer';
import length from '@turf/length';
import _ from 'lodash';
import { IMapSettersProps } from '../map-home';
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Select,
  Spacer,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ActionCard } from 'app/shared/components/action-card';
import { useForm } from 'react-hook-form';
import { defaultValue, IPrayerNeed } from 'app/shared/model/prayer-need.model';
import Roads from 'app/modules/map/layers/roads';
import { Places, SelectedPlace } from 'app/modules/map/layers/places';
import { IPrayerFeature } from 'app/shared/model/prayer-feature.model';
import { FormTextArea } from 'app/shared/components/form-field';
import { saveAccountSettings } from 'app/modules/account/settings/settings.reducer';

export const PrayerNeedUpdateMapLayers = (props: IMapSettersProps) => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);
  const params = useParams();
  const navigate = useNavigate();
  const [isNew] = useState(!params.id);
  const [title, setTitle] = useState();
  const prayerNeedEntity: IPrayerNeed = useAppSelector(state => state.prayerNeed.entity);
  const updateSuccess = useAppSelector(state => state.prayerNeed.updateSuccess);
  const isUpdating = useAppSelector(state => state.prayerNeed.updating);

  const handleClose = useCallback(() => {
    navigate('/map');
  }, [navigate]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity({ prayerWalkEventId: prayerWalkEvent.id, id: params.id }));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      formReset(defaultValue);
      handleClose();
    }
  }, [updateSuccess]);

  const prayerNeedDto = useMemo(() => {
    return {
      category: prayerNeedEntity.category,
      prayerNeedText: prayerNeedEntity.prayerNeedText || '',
      prayerFeature: prayerNeedEntity.prayerFeature,
    };
  }, [prayerNeedEntity]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: formReset,
    resetField,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: prayerNeedDto });

  useEffect(() => {
    formReset(prayerNeedDto);
  }, [prayerNeedDto]);

  const handleValidSubmit = handleSubmit(values => {
    const updatedPrayerNeed: IPrayerNeed = {
      ...prayerNeedEntity,
      category: values.category,
      prayerNeedText: values.prayerNeedText,
      prayerFeature: values.prayerFeature,
    };

    if (isNew) {
      dispatch(createEntity({ prayerWalkEventId: prayerWalkEvent.id, entity: updatedPrayerNeed }));
    } else {
      dispatch(updateEntity({ prayerWalkEventId: prayerWalkEvent.id, entity: updatedPrayerNeed }));
    }
  });

  useEffect(() => {
    props.addHandlerForLayerClick('places', (feature: Feature) => {
      resetField('prayerFeature');
      clearErrors('prayerFeature');
      setValue('prayerFeature', { osmId: feature.properties.osm_id, name: feature.properties.name, type: 'PLACE' });
    });
    return () => {
      props.removeHandlerForLayerClick('places');
    };
  }, []);

  useEffect(() => {
    props.setInteractiveLayerIds(['places']);
    return () => props.removeInteractiveLayerIds(['places']);
  }, []);

  const derivedTitle = useMemo(() => {
    if (title) {
      return title;
    } else {
      return isNew ? 'Adding a prayer need' : 'Edit your prayer need';
    }
  }, [title, isNew]);

  register('prayerFeature', { required: true });
  const selectedPlace = watch('prayerFeature');

  return (
    <>
      <Places tileUrl={`${tileUrl}/public.prayer_need_places/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`} />
      <SelectedPlace
        tileUrl={`${tileUrl}/public.prayer_need_places/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`}
        selectedPlacePrayerFeature={selectedPlace}
      />

      <ActionCard title={derivedTitle} handleClose={handleClose}>
        <chakra.form onSubmit={handleValidSubmit} w="full">
          <VStack spacing={4}>
            <Routes>
              <Route index element={<PrayerNeedFormStep1 errors={errors} selectedPlace={selectedPlace} setError={setError} />} />
              <Route
                path="step2"
                element={
                  <PrayerNeedFormStep2
                    errors={errors}
                    register={register}
                    isUpdating={isUpdating}
                    selectedPlace={selectedPlace}
                    setTitle={setTitle}
                  />
                }
              />
            </Routes>
          </VStack>
        </chakra.form>
      </ActionCard>
    </>
  );
};

export const PrayerNeedFormStep1 = ({ errors, selectedPlace, setError }) => {
  const navigate = useNavigate();

  const goNext = useCallback(() => {
    if (selectedPlace) {
      navigate('step2');
    } else {
      setError('prayerFeature', { type: 'required' });
    }
  }, [navigate, selectedPlace, setError]);

  return (
    <>
      <FormControl isInvalid={errors.prayerFeature !== undefined}>
        <Box fontSize="lg">
          {(selectedPlace && `Selected area: ${selectedPlace.name}`) || `Select an area on the map by tapping the area name`}
        </Box>
        {errors.prayerFeature && <FormErrorMessage>You must select an area from the map</FormErrorMessage>}
      </FormControl>
      <Button w="full" colorScheme="primary" size="lg" onClick={goNext}>
        Next
      </Button>
    </>
  );
};

export const PrayerNeedFormStep2 = ({ errors, register, isUpdating, selectedPlace, setTitle }) => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate('..');
  }, [navigate]);

  useEffect(() => {
    if (!selectedPlace) {
      goBack();
    } else {
      setTitle(`Praying for ${selectedPlace.name}`);
    }
  }, [selectedPlace, setTitle]);

  return (
    <>
      <Select placeholder="Category" {...register('category')} id="category">
        <option value="healing">Healing</option>
        <option value="salvation">Salvation</option>
        <option value="thanksgiving">Thanksgiving</option>
        <option value="blessing">Blessing</option>
        <option value="relationships">Relationships</option>
        <option value="financial concerns">Financial concerns</option>
        <option value="wellbeing">Wellbeing</option>
        <option value="healthcare">Healthcare</option>
        <option value="schools & nurseries">Schools & Nurseries</option>
        <option value="council">Council</option>
        <option value="business">Business</option>
        <option value="churches">Churches</option>
        <option value="prophetic sense">Prophetic sense</option>
        <option value="other">Other</option>
      </Select>
      <FormTextArea
        rows={9}
        placeholder="Share in a few sentences a little more about the prayer need you've discovered, to help others pray more specifically. If the need is for a person, please use only their first name, or an alias."
        {...register('prayerNeedText', {
          required: { value: true, message: 'Some text describing your prayer need is required.' },
        })}
        errors={errors}
      />
      <HStack w="full">
        <Button data-cy="submit" colorScheme="gray" size="lg" onClick={goBack}>
          Back
        </Button>
        <Button flexGrow={1} data-cy="submit" type="submit" colorScheme="primary" size="lg" isLoading={isUpdating}>
          Save
        </Button>
      </HStack>
    </>
  );
};
