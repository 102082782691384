import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { Places, PlacesWithPrayerNeeds, PrayerNeedsGroupedByOsmId, SelectedPlace } from 'app/modules/map/layers/places';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import { getEntities } from 'app/entities/prayer-need/prayer-need.reducer';
import {
  Card,
  CardBody,
  VStack,
  Text,
  CloseButton,
  Box,
  CardHeader,
  Spacer,
  Avatar,
  Flex,
  Button,
  HStack,
  Collapse,
  Heading,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { PrayedForButton, PrayedForCount } from 'app/shared/components/prayed-for-button';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTrash,
  faTimes,
  faQuestionCircle,
  faEdit,
  faPrayingHands,
  faCalendarAlt,
  faRoad,
} from '@fortawesome/free-solid-svg-icons';
import { IHeatmapSelectedInfo } from 'app/entities/map/layers/heatmap-layers';

function getMultiplicativeString(times: number) {
  switch (times) {
    case 1:
      return 'once';
    case 2:
      return 'twice';
    default:
      return `${times} times`;
  }
}

export const HeatmapSelectedCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const heatmapInfo: IHeatmapSelectedInfo = location.state as IHeatmapSelectedInfo;

  useEffect(() => {
    if (heatmapInfo === undefined || heatmapInfo === null) {
      navigate('/map');
    }
  }, [heatmapInfo]);

  const handleClosePopup = useCallback(() => {
    navigate('/map');
  }, []);

  if (heatmapInfo === null) {
    return null;
  }

  return (
    <Card bg="white" minWidth="300px">
      <CardBody>
        <VStack alignItems="start" spacing={2}>
          <Flex direction="row" alignItems="center" w="full">
            <FontAwesomeIcon icon={faRoad} />
            <Box fontSize="lg" ml={2}>
              {heatmapInfo.roadName}
            </Box>
            <Spacer />
            <CloseButton onClick={handleClosePopup} />
          </Flex>
          <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
            Prayed for {getMultiplicativeString(heatmapInfo.numOfTimesPrayedFor)}
          </Box>
          <Box color="gray.500" fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
            Last prayed for {dayjs(heatmapInfo.lastPrayedForTimestamp).fromNow()}
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};
