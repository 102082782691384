import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { MailingListSlice } from 'app/modules/organisation/reducers/mailing-list.reducer';

type PortalSession = { url?: string | undefined };
const initialState: {
  loading: boolean;
  errorMessage?: string;
  portalSession: PortalSession;
} = {
  loading: false,
  errorMessage: null,
  portalSession: {},
};

export const getPortalSession = createAsyncThunk(
  'portalSession/fetch',
  async (returnUrl: string) => {
    const requestUrl = `/api/portal-session`;
    return axios.post<PortalSession>(requestUrl, { returnUrl });
  },
  { serializeError: serializeAxiosError }
);

export const PortalSessionSlice = createSlice({
  name: 'portalSession',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getPortalSession), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getPortalSession), (state, action) => {
        return {
          ...state,
          loading: false,
          portalSession: action.payload.data,
        };
      });
  },
});

export const { reset } = PortalSessionSlice.actions;

// Reducer
export default PortalSessionSlice.reducer;
