import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isHeatmapChecked: true,
  isMyRoutesChecked: false,
  showAllPrayerNeeds: true,
  showMyPrayerNeeds: false,
};

export type CurrentMapLegendState = Readonly<typeof initialState>;

const MapLegendSlice = createSlice({
  name: 'map-legend-slice',
  initialState,
  reducers: {
    toggleIsHeatmapChecked(state) {
      state.isHeatmapChecked = !state.isHeatmapChecked;
    },
    toggleIsMyRoutesChecked(state) {
      state.isMyRoutesChecked = !state.isMyRoutesChecked;
    },
    toggleShowAllPrayerNeeds(state) {
      state.showAllPrayerNeeds = !state.showAllPrayerNeeds;
    },
    toggleShowMyPrayerNeeds(state) {
      state.showMyPrayerNeeds = !state.showMyPrayerNeeds;
    },
    set(state, action: PayloadAction<typeof initialState>) {
      state.isHeatmapChecked = action.payload.isHeatmapChecked;
      state.isMyRoutesChecked = action.payload.isHeatmapChecked;
      state.showAllPrayerNeeds = action.payload.showAllPrayerNeeds;
      state.showMyPrayerNeeds = action.payload.showMyPrayerNeeds;
    },
  },
});

export const { toggleIsHeatmapChecked, toggleIsMyRoutesChecked, set, toggleShowAllPrayerNeeds, toggleShowMyPrayerNeeds } =
  MapLegendSlice.actions;

// Reducer
export default MapLegendSlice.reducer;
