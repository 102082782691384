import React from 'react';
import {Helmet} from 'react-helmet';
import {useAppSelector} from 'app/config/store';

const convertToRGB = (hex: string) => {
  const aRgbHex = hex.replace('#', '').match(/.{1,2}/g);
  return [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16)
  ];
};

export const ApplyBranding = ({ colors }) => {
  if (colors) {
    return (
      <Helmet>
        <style>
          {`:root {
            --bs-primary: ${colors.primary};
            --bs-primary-rgb: ${convertToRGB(colors.primary).join(', ')};
          }`}
        </style>
      </Helmet>
    );
  } else return null;
};

export const EventAwareTheming = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  return (
    <ApplyBranding
      colors={{
        primary: prayerWalkEvent.settings.colours.branding.primary,
      }}
    />
  );
};

export default EventAwareTheming;
