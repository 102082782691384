import React from 'react';
import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { IPrayerEventChallengeStats } from 'app/shared/model/prayer-event-challenge-stats';

export const PrayerEventChallengeStats = ({ prayerEventChallengeStats }: { prayerEventChallengeStats: IPrayerEventChallengeStats[] }) => {
  return (
    <Box bg="white" p={4} pb={8}>
      <Table __css={{ 'table-layout': 'auto', width: 'full' }}>
        <Thead>
          <Tr>
            <Th>Prayer step</Th>
            <Th isNumeric>Completed by users</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prayerEventChallengeStats.map((prayerEventChallengeStat, index) => (
            <Tr key={index}>
              <Td>{prayerEventChallengeStat.title}</Td>
              <Td isNumeric>{prayerEventChallengeStat.numberOfUsersCompleted}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
