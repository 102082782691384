import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUserStats } from 'app/shared/model/user-stats';

const initialState: {
  loading: boolean;
  errorMessage?: string;
  entity?: IUserStats;
} = {
  loading: true,
  errorMessage: null,
  entity: null,
};

export const getUserStats = createAsyncThunk(
  'userStats/fetch',
  async (id: string | number) => {
    const requestUrl = `/api/user/prayer-walk-events/${id}/stats`;
    return axios.get<IUserStats>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const UserStatsSlice = createSlice({
  name: 'userStats',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getUserStats), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getUserStats), (state, action) => {
        return {
          ...state,
          loading: false,
          entity: action.payload.data,
        };
      });
  },
});

// Reducer
export default UserStatsSlice.reducer;
