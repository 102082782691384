import { useAppSelector } from 'app/config/store';
import { IOrganisation } from 'app/shared/model/organisation.model';
import { SubscriptionStatus } from 'app/shared/model/enumerations/subscription-status.model';
import dayjs from 'dayjs';

export const useUsersOrganisation = (): IOrganisation => useAppSelector(state => state.usersOrganisation.usersOrganisation);
export const useOrganisationHasCancelledSubscription = (): boolean =>
  useAppSelector(state => state.usersOrganisation.usersOrganisation.paymentSubscription.status === SubscriptionStatus.CANCELLED);
export const useOrganisationHasPastDueSubscription = (): boolean =>
  useAppSelector(state => state.usersOrganisation.usersOrganisation.paymentSubscription.status === SubscriptionStatus.PAST_DUE);
export const useOrganisationSubscriptionIsSelfManaged = (): boolean =>
  useAppSelector(state => state.usersOrganisation.usersOrganisation.paymentSubscription.customerId !== null);
export const useOrganisationSubscriptionIsExpired = (): boolean =>
  useAppSelector(state => dayjs(state.usersOrganisation.usersOrganisation.paymentSubscription.expiresAt).isBefore(dayjs()));
