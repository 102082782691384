import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, SerializedError } from '@reduxjs/toolkit';

import {IQueryParams, serializeAxiosError} from 'app/shared/reducers/reducer.utils';

import { IMailingListContact } from 'app/shared/model/mailing-list-contact.model';

const initialState: MailingListState = {
  loading: false,
  errorMessage: null,
  mailingList: [],
  totalItems: 0,
};

export type MailingListState = {
  loading: boolean;
  errorMessage?: SerializedError | null;
  mailingList: IMailingListContact[];
  totalItems: number;
};

export const getMailingList = createAsyncThunk(
  'mailingList/get',
  async ({ page, size, sort, id }: IQueryParams & {id: string}) =>
    axios.get<{ contacts: IMailingListContact[] }>(
      `/api/prayer-walk-events/${id}/mailing-list${sort ? 
        `?page=${page}&size=${size}&sort=${sort}&` : 
        '?'}cacheBuster=${new Date().getTime()}`),
  {
    serializeError: serializeAxiosError,
  }
);

export const MailingListSlice = createSlice({
  name: 'mailingList',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMailingList.pending, state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addCase(getMailingList.rejected, (state, payload) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addMatcher(isFulfilled(getMailingList), (state, action): MailingListState => {
        const {data, headers} = action.payload;

        return {
          ...state,
          loading: false,
          mailingList: data.contacts,
          totalItems: parseInt(headers['x-total-count'], 10),
        }
      });
  },
});

export const { reset } = MailingListSlice.actions;

// Reducer
export default MailingListSlice.reducer;
