import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getStats } from 'app/modules/participants/stats/stats.reducer';
import React, { useEffect } from 'react';
import {
  Stat as ChakraStat,
  StatLabel,
  Flex,
  StatNumber,
  Skeleton,
  Stack,
  StackDivider,
  VStack,
  Progress,
  Box,
  CircularProgress,
  CircularProgressLabel,
  StatHelpText,
} from '@chakra-ui/react';
import { ProgressBar } from 'react-toastify/dist/components';
import { distanceFormat } from 'app/shared/util/distance-format';
import { percentageFormat } from 'app/shared/util/percentage-format';

export const Stat = ({
  label,
  value,
  isLoading,
  helperText,
  size,
}: {
  label: string;
  value: number | string;
  isLoading: boolean;
  helperText?: string;
  size?: string;
}) => (
  <ChakraStat size={size || 'md'}>
    <StatLabel>{label}</StatLabel>
    {isLoading && (
      <Flex justifyContent="center" mt={1}>
        <Skeleton height="28px" width="40px" />
      </Flex>
    )}
    {!isLoading && <StatNumber>{value}</StatNumber>}
    {helperText && <StatHelpText>{helperText}</StatHelpText>}
  </ChakraStat>
);
export const ProgressStat = ({
  label,
  value,
  progressLabel,
  isLoading,
}: {
  label: string;
  value: number;
  progressLabel: string;
  isLoading: boolean;
}) => (
  <VStack flex={1}>
    <Box>{label}</Box>
    <CircularProgress isIndeterminate={isLoading} value={value} color="primary.500" display="inline-flex" alignItems="center">
      <CircularProgressLabel fontSize="xs">{progressLabel}</CircularProgressLabel>
    </CircularProgress>
  </VStack>
);

export const StatBar = () => {
  const dispatch = useAppDispatch();
  const { loading, entity: stats } = useAppSelector(state => state.stats);
  const prayerWalkEventId = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent.id);
  useEffect(() => {
    dispatch(getStats(prayerWalkEventId));
  }, [dispatch]);

  return (
    <VStack m={2} id="stat-bar">
      <Stack
        spacing="2"
        justify="space-between"
        alignItems="center"
        direction={'row'}
        divider={<StackDivider borderColor="gray.200" borderStyle="solid" />}
        width="full"
        textAlign="center"
      >
        <Stat label="Participants" isLoading={loading} value={stats && stats.numberOfParticipants} />
        <Stat label="Total distance" isLoading={loading} value={distanceFormat({ distanceInKm: stats && stats.totalKmsOfRoadsWalked })} />
        <Stat
          label="Prayer for every street"
          isLoading={loading}
          value={
            stats && stats.totalKmsOfRoadsWalkedAtLeastOnce
              ? percentageFormat({ percentage: (stats.totalKmsOfRoadsWalkedAtLeastOnce / stats.totalKmsOfRoads) * 100 })
              : '0%'
          }
        />
      </Stack>
    </VStack>
  );
};
