import { Badge, Box, Button, HStack, IconButton, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { markAsPrayedFor } from 'app/entities/prayer-need/prayer-need.reducer';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import { faPray } from '@fortawesome/free-solid-svg-icons/faPray';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrayedHandsIcon } from 'app/shared/components/icons';

export const PrayedForButton = ({ prayerNeed }: { prayerNeed: IPrayerNeed }) => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const [updating, setUpdating] = useState(false);
  const [prayedForByCurrentUser, setPrayedForByCurrentUser] = useState(false);

  const togglePrayedFor = useCallback(() => {
    setUpdating(true);
    dispatch(markAsPrayedFor({ prayerWalkEventId: prayerWalkEvent.id, id: prayerNeed.id }));
  }, []);

  useEffect(() => {
    if (updating && prayerNeed.prayedForByCurrentUser !== prayedForByCurrentUser) {
      setUpdating(false);
    }
    setPrayedForByCurrentUser(prayerNeed.prayedForByCurrentUser);
  }, [prayerNeed]);

  return (
    <Button
      size="sm"
      colorScheme="blue"
      variant={prayerNeed.prayedForByCurrentUser ? 'solid' : 'outline'}
      leftIcon={<PrayedHandsIcon />}
      onClick={togglePrayedFor}
      isLoading={updating}
    >
      I&apos;ve prayed
    </Button>
  );
};

export const PrayedForCount = ({ prayerNeed }: { prayerNeed: IPrayerNeed }) => {
  return (
    <Badge borderRadius="full" px="3" py={1} colorScheme="blue" variant="subtle">
      <HStack spacing={1}>
        <PrayedHandsIcon />
        <Box data-testid="prayerNeedPrayedForCount">{prayerNeed.prayedForCount}</Box>
      </HStack>
    </Badge>
  );
};
