import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'app/config/store';
import { onSuccessLoginWithOAuth2 } from 'app/shared/reducers/authentication';

const OAuth2Callback = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(onSuccessLoginWithOAuth2());
  }, []);

  return <Navigate to="/account/oauth2-register" />;
};

export default OAuth2Callback;
