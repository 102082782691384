import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { IMapSettersProps } from 'app/entities/map/map-home';
import { getEntitiesForUser, getEntity, reset } from 'app/entities/prayer-route/prayer-route.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faRoute } from '@fortawesome/free-solid-svg-icons/faRoute';
import { faPrayingHands } from '@fortawesome/free-solid-svg-icons/faPrayingHands';
import { Box, Heading, VStack, Text, HStack, Button, Center } from '@chakra-ui/react';
import { HeatmapMapLayers } from 'app/entities/map/layers/heatmap-layers';
import { CoverOnceMapLayers } from 'app/entities/map/layers/cover-once-layers';
import { PrayerNeedLayers } from './prayer-need-layers';
import { BottomCardScroller } from 'app/shared/components/bottom-card-scroller';
import { newFeatureCollection } from 'app/modules/map/utils';
import { MapLegend } from 'app/shared/components/map-legend';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PrayerHandsIcon } from 'app/shared/components/icons';
import { Places } from 'app/modules/map/layers/places';

const getIncompletePrayerWalk = (prayerRouteList: ReadonlyArray<IPrayerRoute>): IPrayerRoute =>
  prayerRouteList.filter(route => route.completed === false)[0];

const thereIsAnIncompletePrayerWalk = (prayerRouteList: ReadonlyArray<IPrayerRoute>): boolean =>
  prayerRouteList.some(route => route.completed === false);

const thereAreOnlyCompletePrayerWalks = (prayerRouteList: ReadonlyArray<IPrayerRoute>): boolean =>
  prayerRouteList.every(route => route.completed === true);

export const MapLinkButton = (props: { icon: React.ReactElement; url: string; children: React.ReactNode; id: string }) => (
  <Button id={props.id} size="lg" bg="white" px={6} py={4} leftIcon={props.icon} as={Link} to={props.url}>
    {props.children}
  </Button>
);
export const MapHomeActions = () => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const prayerRoutes = useAppSelector(state => state.prayerRoute.userEntities) as IPrayerRoute[];

  useEffect(() => {
    dispatch(getEntitiesForUser({ prayerWalkEventId: prayerWalkEvent.id }));
  }, []);

  const incompletePrayerWalk = getIncompletePrayerWalk(prayerRoutes);
  return (
    <>
      {thereIsAnIncompletePrayerWalk(prayerRoutes) && prayerWalkEvent.enabled && (
        <>
          <MapLinkButton
            id="complete-route"
            icon={<FontAwesomeIcon icon={faCheck} />}
            url={`prayer-route/${incompletePrayerWalk.id}/complete`}
          >
            Complete Route
          </MapLinkButton>
          {prayerWalkEvent.settings.features.prayerNeedsEnabled && (
            <MapLinkButton id="create-prayer-need" icon={<PrayerHandsIcon />} url={`prayer-need/new`}>
              Add a prayer need
            </MapLinkButton>
          )}
          <MapLinkButton id="edit-route" icon={<FontAwesomeIcon icon={faPencilAlt} />} url={`prayer-route/${incompletePrayerWalk.id}/edit`}>
            Edit Route
          </MapLinkButton>
        </>
      )}
      {thereAreOnlyCompletePrayerWalks(prayerRoutes) && prayerWalkEvent.enabled && (
        <>
          <MapLinkButton id="create-route" icon={<FontAwesomeIcon icon={faRoute} />} url="prayer-route/new">
            Add a prayer walk
          </MapLinkButton>
          {prayerWalkEvent.settings.features.prayerNeedsEnabled && (
            <MapLinkButton id="create-prayer-need" icon={<PrayerHandsIcon />} url={`prayer-need/new`}>
              Add a prayer need
            </MapLinkButton>
          )}
        </>
      )}
    </>
  );
};

export const MapHomeLayersAndOutlet = (props: IMapSettersProps): JSX.Element => {
  const prayerWalkEvent = useCurrentEvent();
  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);

  return (
    <>
      <BottomCardScroller>
        <Outlet />
      </BottomCardScroller>
      {prayerWalkEvent.settings.features.heatmapEnabled ? <HeatmapMapLayers {...props} /> : <CoverOnceMapLayers {...props} />}
      {prayerWalkEvent.settings.features.prayerNeedsEnabled ? (
        <PrayerNeedLayers {...props} />
      ) : (
        <Places tileUrl={`${tileUrl}/public.prayer_need_places/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`} />
      )}
      <MapLegend />
    </>
  );
};
