import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'app/config/store';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { Box, Flex, Image, VStack, Text } from '@chakra-ui/react';
import { reset } from 'app/modules/organisation/reducers/users-organisation.reducer';

export const OrganisationLogin = () => {
  const account = useAppSelector(state => state.authentication.account);
  const isLoading = useAppSelector(state => state.authentication.loading);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(reset());
    }
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { from } = location.state || { from: { pathname: '/organisation', search: location.search } };
  if (account && account.login) {
    return <Navigate to={from} replace />;
  }

  return (
    <>
      {account && account.login ? (
        <Navigate to="/organisation" />
      ) : (
        <>
          <Flex id="anonymous-home-container" flexGrow={1} flexDirection="column" alignItems="center">
            <VStack maxW="2xl" mx={4} py={[4, 8]}>
              <Box>
                <Image src="content/images/SVG-PrayerWalkApp-Horizontal_Colour.svg" />
              </Box>
              <Box fontSize={{ base: 'xl', md: '2xl' }} fontWeight="semibold" textAlign="center" color="gray.500">
                Management login for churches and organisations
              </Box>
            </VStack>
            <Flex width="full" justifyContent="center" px={4}>
              <Box width="full" maxW="5xl" bg={'white'} py="8" px={{ base: '6', md: '8' }} shadow="md" rounded="lg">
                <Outlet />
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default OrganisationLogin;
