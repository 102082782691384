import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUserEngagementStats } from 'app/modules/organisation/reducers/user-engagement-stats.reducer';
import { Pagination } from 'app/shared/components/pagination';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IPaginationBaseState } from 'react-jhipster';
import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr, useBreakpointValue, VStack } from '@chakra-ui/react';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { TableHeader } from 'app/modules/organisation/main/prayer-walk-event-overview/table-header';
import { IUserEngagementStat } from 'app/shared/model/user-engagement.model';

export const UserEngagementHeaderAndTable = ({
  prayerWalkEvent,
  inactiveSubscription,
}: {
  prayerWalkEvent: IPrayerWalkEvent;
  inactiveSubscription: boolean;
}) => {
  return (
    <TableHeader
      heading="User Engagement"
      subheading="Stats about all users who have logged in to view the event"
      inactiveSubscription={inactiveSubscription}
      url={`/api/prayer-walk-events/${prayerWalkEvent.id}/user-engagement.csv`}
    >
      <UserEngagementTable />
    </TableHeader>
  );
};

export const UserEngagementTable = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const userEngagementStats: IUserEngagementStat[] = useAppSelector(state => state.userEngagementStats.userEngagementStats);
  const totalItems = useAppSelector(state => state.userEngagementStats.totalItems);
  const [paginationState, setPaginationState] = useState<IPaginationBaseState>({
    itemsPerPage: 10,
    sort: 'userId',
    order: 'asc',
    activePage: 1,
  });

  useEffect(() => {
    dispatch(
      getUserEngagementStats({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        id,
      })
    );
  }, [paginationState]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const isTablet = useBreakpointValue({ base: false, lg: true });

  return (
    <VStack spacing={2} w="full">
      <Table __css={{ 'table-layout': 'auto', width: 'full' }}>
        <Thead>
          <Tr>
            <Th>Username</Th>
            {isDesktop && <Th>Church</Th>}
            {isTablet && <Th>Registered on</Th>}
            <Th isNumeric>Earmarked prayer walks</Th>
            <Th isNumeric>Completed prayer walks</Th>
            {isDesktop && <Th isNumeric>Length of completed prayer walks</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {userEngagementStats.map(userEngagementStat => (
            <Tr key={userEngagementStat.userId}>
              <Td overflowX="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {userEngagementStat.username}
              </Td>
              {isDesktop && <Td>{userEngagementStat.church}</Td>}
              {isTablet && <Td>{userEngagementStat.userRegisteredOn}</Td>}
              <Td isNumeric>{userEngagementStat.numberOfEarmarkedPrayerWalks}</Td>
              <Td isNumeric>{userEngagementStat.numberOfCompletedPrayerWalks}</Td>
              {isDesktop && <Td isNumeric>{userEngagementStat.lengthOfCompletedPrayerWalks}</Td>}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        activePage={paginationState.activePage}
        onSelect={handlePagination}
        maxButtons={5}
        itemsPerPage={paginationState.itemsPerPage}
        totalItems={totalItems}
      />
    </VStack>
  );
};
