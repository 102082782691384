import React, { useEffect, PropsWithChildren } from 'react';
import { getPrayerWalkEventFromDomain } from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AppRoutesForOrganisation from 'app/modules/organisation/routes';
import { Navigate } from 'react-router-dom';
import { defaultValue, IOrganisation } from 'app/shared/model/organisation.model';
import { getUsersOrganisation, reset } from 'app/modules/organisation/reducers/users-organisation.reducer';
import { getSession } from 'app/shared/reducers/authentication';

const OrganisationOnboardStepNavigator = (props: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const organisation: IOrganisation = useAppSelector(state => state.usersOrganisation.usersOrganisation);
  const errorMessage = useAppSelector(state => state.usersOrganisation.errorMessage);
  const loading = useAppSelector(state => state.usersOrganisation.loading);
  const { children } = props;

  useEffect(() => {
    dispatch(getUsersOrganisation());
    return () => {
      dispatch(reset());
    };
  }, []);

  if (
    errorMessage &&
    errorMessage.response &&
    errorMessage.response.data &&
    errorMessage.response.data.type === 'https://www.jhipster.tech/problem/organisation-not-found'
  ) {
    // no org yet
    return <Navigate to="/organisation-onboard/organisation-details" />;
  }
  if (organisation && organisation.paymentSubscription && !organisation.paymentSubscription.expiresAt) {
    // subscription not yet set up, if it has been set up, but has expired, users should still be able to go to the home page
    return <Navigate to="/organisation-onboard/choose-subscription" />;
  }

  if (!loading && organisation !== defaultValue) {
    // show org home page
    return <>{children}</>;
  } else {
    if (loading) {
      return <LoadingSpinner />;
    }
  }
};

export default OrganisationOnboardStepNavigator;
