import React from 'react';
// import './help-popover.scss';
import { Box, Checkbox, CheckboxGroup, HStack, IconButton, ScaleFade, Text, Switch, useDisclosure, VStack } from '@chakra-ui/react';
import { LegendItem } from 'app/shared/components/legend-item';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toggleIsHeatmapChecked, toggleIsMyRoutesChecked, toggleShowAllPrayerNeeds } from 'app/shared/components/map-legend.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import sortBy from 'lodash/sortBy';

export const MapLegend = () => {
  const prayerWalkEvent = useCurrentEvent();
  const mapLegendState = useAppSelector(state => state.mapLegendState);
  const dispatch = useAppDispatch();
  const { isOpen, onToggle } = useDisclosure();
  const heatmapEnabled = prayerWalkEvent.settings.features.heatmapEnabled;
  const prayerNeedsEnabled = prayerWalkEvent.settings.features.prayerNeedsEnabled;
  const completedColour = prayerWalkEvent.settings.colours.completedColour.code;
  const earmarkedColour = prayerWalkEvent.settings.colours.earmarkedColour.code;

  const colourStops = heatmapEnabled && sortBy(prayerWalkEvent.settings.mapStyle.colourStops, ['decimalPercentage']);

  return (
    <Box position="absolute" top={3} right={3} zIndex={1000}>
      <VStack alignItems="flex-end">
        <IconButton
          aria-label="show layers"
          // position="absolute"
          // top={2}
          // right={2}
          icon={<FontAwesomeIcon icon="layer-group" />}
          boxShadow="md"
          borderRadius="full"
          bg="white"
          onClick={onToggle}
          size="lg"
          id="menu-button-layers"
        />
        <Box display={isOpen ? 'block' : 'none'} bg="white" rounded="md" shadow="md" p={6}>
          <VStack alignItems="flex-start" spacing={4} id="menu-list-layers">
            {prayerNeedsEnabled && (
              <>
                <HStack w="full">
                  <Switch
                    size="lg"
                    isChecked={mapLegendState.showAllPrayerNeeds}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                    onChange={() => {
                      dispatch(toggleShowAllPrayerNeeds());
                    }}
                  />
                  <Box fontSize="md" textAlign="center" w="full">
                    Show prayer needs
                  </Box>
                </HStack>
              </>
            )}
            {heatmapEnabled ? (
              <>
                <SwitchedLegendItem
                  isChecked={mapLegendState.isHeatmapChecked}
                  onChange={() => dispatch(toggleIsHeatmapChecked())}
                  gradient={`linear(to-r, ${colourStops[0].colour}, ${colourStops[colourStops.length - 1].colour})`}
                  description="Heatmap"
                />
                <SwitchedLegendItem
                  isChecked={mapLegendState.isMyRoutesChecked}
                  onChange={() => dispatch(toggleIsMyRoutesChecked())}
                  colour={completedColour}
                  description="My routes"
                />
              </>
            ) : (
              <>
                <HStack>
                  <Switch
                    size="lg"
                    isChecked={mapLegendState.isHeatmapChecked}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                    onChange={() => {
                      dispatch(toggleIsHeatmapChecked());
                    }}
                  />
                  <Box fontSize="md">Show all walks</Box>
                </HStack>
                <HStack>
                  <Switch
                    size="lg"
                    isChecked={mapLegendState.isMyRoutesChecked}
                    onClick={event => {
                      event.stopPropagation();
                    }}
                    onChange={() => {
                      dispatch(toggleIsMyRoutesChecked());
                    }}
                  />
                  <Box fontSize="md">Show my walks</Box>
                </HStack>
              </>
            )}
            {heatmapEnabled ? (
              <SwitchedLegendItem
                isChecked={true}
                onChange={() => {}}
                colour={earmarkedColour}
                description="Your earmarked route"
                disabled={true}
              />
            ) : (
              <>
                <LegendItem colour={completedColour} description="Completed" />
                <LegendItem colour={earmarkedColour} description="Earmarked" />
              </>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

interface ISwitchedLegendItemProps {
  isChecked: boolean;
  onChange: () => void;
  disabled?: boolean;
  colour?: string;
  gradient?: string;
  description: string;
}

export const SwitchedLegendItem = (props: ISwitchedLegendItemProps) => (
  <HStack w="full" spacing={4}>
    <Switch
      size="lg"
      isChecked={props.isChecked}
      onChange={props.onChange}
      disabled={props.disabled}
      onClick={event => {
        event.stopPropagation();
      }}
    />
    <VStack w="full" spacing={1}>
      <Box fontSize="md">{props.description}</Box>
      <Box w="full" h="5px" bgGradient={props.gradient} bg={props.colour} />
    </VStack>
  </HStack>
);
