import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { IRoad } from 'app/shared/model/road.model';
import { v4 as uuidv4 } from 'uuid';

export interface IViewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
}

export interface IIdentifiableViewport extends IViewport {
  id: string;
}

export const defaultValue: Readonly<IIdentifiableViewport> = {
  id: uuidv4(),
  latitude: 1,
  longitude: 52,
  zoom: 9,
  bearing: 0,
  pitch: 0
};
