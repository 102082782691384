import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, SerializedError } from '@reduxjs/toolkit';

import { IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

import { IUserEngagementStat } from 'app/shared/model/user-engagement.model';

const initialState: UserEngagementStatsState = {
  loading: false,
  errorMessage: null,
  userEngagementStats: [],
  totalItems: 0,
};

export type UserEngagementStatsState = {
  loading: boolean;
  errorMessage?: SerializedError | null;
  userEngagementStats: IUserEngagementStat[];
  totalItems: number;
};

export const getUserEngagementStats = createAsyncThunk(
  'userEngagementStats/get',
  async ({ page, size, sort, id }: IQueryParams & { id: string }) =>
    axios.get<IUserEngagementStat[]>(
      `/api/prayer-walk-events/${id}/user-engagement${
        sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'
      }cacheBuster=${new Date().getTime()}`
    ),
  {
    serializeError: serializeAxiosError,
  }
);

export const UserEngagementStatsSlice = createSlice({
  name: 'userEngagementStats',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserEngagementStats.pending, state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addCase(getUserEngagementStats.rejected, (state, payload) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addMatcher(isFulfilled(getUserEngagementStats), (state, action): UserEngagementStatsState => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          userEngagementStats: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      });
  },
});

export const { reset } = UserEngagementStatsSlice.actions;

// Reducer
export default UserEngagementStatsSlice.reducer;
