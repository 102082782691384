import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk, isRejected } from '@reduxjs/toolkit';

import { isRejectedAction, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { MailingListSlice } from 'app/modules/organisation/reducers/mailing-list.reducer';

type CheckoutSession = { url?: string | undefined };
const initialState: {
  loading: boolean;
  errorMessage?: string;
  checkoutSession: CheckoutSession;
} = {
  loading: false,
  errorMessage: null,
  checkoutSession: {},
};

export const getCheckoutSession = createAsyncThunk(
  'checkoutSession/fetch',
  async ({ subscriptionLevel, billingPeriod }: { subscriptionLevel: string; billingPeriod: string }) => {
    const requestUrl = `/api/checkout-session`;
    return axios.post<CheckoutSession>(requestUrl, {
      subscriptionLevel,
      subscriptionPaymentPeriod: billingPeriod.toUpperCase(),
      successUrl: `${window.location.origin}/organisation`,
      cancelUrl: `${window.location.origin}/organisation`,
    });
  },
  { serializeError: serializeAxiosError }
);

export const CheckoutSessionSlice = createSlice({
  name: 'checkoutSession',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getCheckoutSession), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getCheckoutSession), (state, action) => {
        return {
          ...state,
          loading: false,
          checkoutSession: action.payload.data,
        };
      })
      .addMatcher(isRejected(getCheckoutSession), (state, action) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export const { reset } = CheckoutSessionSlice.actions;

// Reducer
export default CheckoutSessionSlice.reducer;
