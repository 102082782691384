import React from 'react';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

interface IErrorBoundaryProps {
  readonly children: React.ReactNode;
}

interface IErrorBoundaryState {
  readonly error: any;
  readonly errorInfo: any;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  readonly state: IErrorBoundaryState = { error: undefined, errorInfo: undefined };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    Sentry.captureException(error, {
      extra: {
        errorInfo,
        componentStack: errorInfo.componentStack,
      },
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      ReactGA.exception({
        description: error ? error.toString() : errorInfo,
        fatal: true,
      });
      const errorDetails = DEVELOPMENT ? (
        <details className="preserve-space">
          {error && error.toString()}
          <br />
          {errorInfo.componentStack}
        </details>
      ) : undefined;
      return (
        <div>
          <h2 className="error">An unexpected error has occurred.</h2>
          <p>Please refresh this page</p>
          {errorDetails}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
