import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState } from 'react-jhipster';
import { useOrganisationHasCancelledSubscription, useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  VStack,
  Text,
  chakra,
  Link as ChakraLink,
  Badge,
  HStack,
  LinkBox,
  IconButton,
  LinkOverlay,
  StackDivider,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { ChevronRightIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  deleteEntity,
  getEntities,
  IUserAuthorityResourceQueryParams,
} from 'app/entities/user-authority-resource/user-authority-resource.reducer';
import { IUserAuthorityResource } from 'app/shared/model/user-authority-resource.model';
import { AUTHORITIES } from 'app/config/constants';
import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IQueryParams } from 'app/shared/reducers/reducer.utils';
import { authorityToUIFriendlyName } from 'app/modules/organisation/auth/permissions';
import { ButtonLink, IconButtonLink } from 'app/shared/components/button-link';
import { Pagination } from 'app/shared/components/pagination';

const NoUserPermissions = () => (
  <Flex alignItems="center" justifyContent="center" width="full" grow={1} fontSize="lg">
    No user permissions
  </Flex>
);

export const OrganisationUserPermissions = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const organisation = useUsersOrganisation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const userPermissions = useAppSelector(state => state.userAuthorityResource.entities);
  const isLoading = useAppSelector(state => state.userAuthorityResource.loading);
  const totalItems = useAppSelector(state => state.userAuthorityResource.totalItems);
  const inactiveSubscription = useOrganisationHasCancelledSubscription();

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        organisationId: organisation.id,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const hasPrayerWalkEvents = !isLoading && userPermissions.length > 0;
  const hasNoPrayerWalkEvents = !isLoading && userPermissions.length === 0;

  return (
    <VStack spacing={4} w="full" h="full" flex={1}>
      <Flex w="full" id="userPermissionsHeader">
        <Heading>User permissions</Heading>
        <Spacer />
        <ButtonLink isDisabled={inactiveSubscription} colorScheme="primary" to="new">
          Grant permission to user
        </ButtonLink>
      </Flex>
      <Flex grow={1} bg="white" w="full" boxShadow="md">
        {hasPrayerWalkEvents && <UserPermissionTable userPermissions={userPermissions} getAllEntities={getAllEntities} />}
        {hasNoPrayerWalkEvents && <NoUserPermissions />}
        {isLoading && <LoadingSpinner />}
      </Flex>
      <Pagination
        activePage={paginationState.activePage}
        onSelect={handlePagination}
        maxButtons={5}
        itemsPerPage={paginationState.itemsPerPage}
        totalItems={totalItems}
      />
    </VStack>
  );
};

interface IUserPermissionTableProps {
  userPermissions: readonly IUserAuthorityResource[];
  getAllEntities: () => void;
}

const UserPermissionTable = ({ userPermissions, getAllEntities }: IUserPermissionTableProps) => {
  return (
    <VStack w="full" id="userPermissionsTable">
      {userPermissions.map((userPermission, index) => {
        return (
          <UserPermissionTableRow key={userPermission.id} userPermission={userPermission} index={index} getAllEntities={getAllEntities} />
        );
      })}
    </VStack>
  );
};

interface IUserPermissionTableRowProps {
  userPermission: IUserAuthorityResource;
  getAllEntities: () => void;
  index: number;
}

const UserPermissionTableRow = ({ userPermission, getAllEntities, index }: IUserPermissionTableRowProps) => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const updateSuccess = useAppSelector(state => state.userAuthorityResource.updateSuccess);
  const loggedInUserEmail = useAppSelector(state => state.authentication.account.email);
  const inactiveSubscription = useOrganisationHasCancelledSubscription();

  const deletePermission = (id: number) => {
    dispatch(deleteEntity(id));
  };

  useEffect(() => {
    if (updateSuccess) {
      getAllEntities();
    }
  }, [updateSuccess]);

  return (
    <Flex p={4} w="full" direction="column" bg={index % 2 === 0 ? 'white' : 'gray.50'}>
      <Flex w="full" direction="row" alignItems="center" columnGap={2}>
        <Box flex={1}>
          {userPermission.userEmail}
        </Box>
        <Box flex={1}>
          {authorityToUIFriendlyName[userPermission.authorityName]}
        </Box>
        <HStack>
          <IconButtonLink
            isDisabled={loggedInUserEmail === userPermission.userEmail || inactiveSubscription}
            to={`${userPermission.id}/edit`}
            variant="ghost"
            aria-label="Edit permission"
            icon={<EditIcon />}
          />
          <IconButton
            isDisabled={loggedInUserEmail === userPermission.userEmail || inactiveSubscription}
            onClick={onOpen}
            variant="ghost"
            aria-label="Delete permission"
            icon={<DeleteIcon />}
          />
        </HStack>
      </Flex>
      <Collapse in={isOpen}>
        <HStack justifyContent="end" w="full" pt={4}>
          <Box>Are you sure you want to delete this permission?</Box>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="red" onClick={() => deletePermission(userPermission.id)}>
            Delete
          </Button>
        </HStack>
      </Collapse>
    </Flex>
  );
};
