import React, { useCallback, useEffect, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { MapboxGeoJSONFeature, Popup } from 'react-map-gl';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import EarmarkedPrayerRoutesGeoJsonLayer from 'app/modules/map/layers/earmarked-routes-geo-json-layer';
import AllPrayerRoutesHeatmapVectorLayer from 'app/modules/map/layers/all-prayer-routes-heatmap-vector-layer';
import MyCompletedPrayerRoutes from 'app/modules/map/layers/my-routes-geo-json-layer';
import { newFeatureCollection } from 'app/modules/map/utils';
import { IMapSettersProps } from 'app/entities/map/map-home';
import { useNavigate } from 'react-router-dom';
import { useSelectedPrayerRouteId } from 'app/shared/hooks/useSelectedPrayerRouteId';

export interface IHeatmapSelectedInfo {
  longitude: number;
  latitude: number;
  numOfTimesPrayedFor: number;
  lastPrayedForTimestamp: string;
  roadName: string;
}

export const HeatmapMapLayers = ({
  setInteractiveLayerIds,
  removeInteractiveLayerIds,
  addHandlerForLayerClick,
  removeHandlerForLayerClick,
}: IMapSettersProps) => {
  const prayerWalkEvent = useCurrentEvent();
  const mapLegendState = useAppSelector(state => state.mapLegendState);
  const prayerRoutes = useAppSelector(state => state.prayerRoute.userEntities) as IPrayerRoute[];
  const selectedPrayerRouteId = useSelectedPrayerRouteId();
  const navigate = useNavigate();

  useEffect(() => {
    const interactiveLayerIds = [];
    const earmarkedRoutesPresent = prayerRoutes.length > 0 && prayerRoutes.some(prayerRoute => prayerRoute.completed === false);
    const completedRoutesPresent = prayerRoutes.length > 0 && prayerRoutes.some(prayerRoute => prayerRoute.completed === true);
    if (mapLegendState.isMyRoutesChecked) {
      completedRoutesPresent && interactiveLayerIds.push('highlighted-roads-my-completed-routes');
    }
    if (mapLegendState.isHeatmapChecked) {
      interactiveLayerIds.push('highlighted-roads-heatmap-solid');
    }
    earmarkedRoutesPresent && interactiveLayerIds.push('highlighted-roads-my-earmarked-routes');
    setInteractiveLayerIds(interactiveLayerIds);
    return () => removeInteractiveLayerIds(interactiveLayerIds);
  }, [prayerRoutes, setInteractiveLayerIds, mapLegendState]);

  useEffect(() => {
    addHandlerForLayerClick('highlighted-roads-heatmap-solid', (feature: MapboxGeoJSONFeature, lngLat: mapboxgl.LngLat) => {
      navigate('heatmap-selected', {
        state: {
          longitude: lngLat.lng,
          latitude: lngLat.lat,
          numOfTimesPrayedFor: feature.properties.num_times_prayed,
          lastPrayedForTimestamp: feature.properties.last_prayed_for,
          roadName: feature.properties.road_name,
        },
      });
    });
    const clickOnMyRouteHandler = (feature: MapboxGeoJSONFeature, lngLat: mapboxgl.LngLat) => {
      let route = prayerRoutes.find(r => {
        return feature.properties.prayer_route_id === r.id;
      });
      if (!route) {
        // when using vector tiles create prayer route
        route = {
          id: feature.properties.prayer_route_id,
          route: newFeatureCollection([feature]),
          completed: feature.properties.completed,
          completedAt: feature.properties.completed_at,
        };
      }
      navigate(`prayer-route-selected/${route.id}`, {
        state: { longitude: lngLat.lng, latitude: lngLat.lat, roadName: feature.properties.name },
      });
    };
    addHandlerForLayerClick('highlighted-roads-my-earmarked-routes', clickOnMyRouteHandler);
    addHandlerForLayerClick('highlighted-roads-my-completed-routes', clickOnMyRouteHandler);
    return () => {
      removeHandlerForLayerClick('highlighted-roads-heatmap-solid');
      removeHandlerForLayerClick('highlighted-roads-my-completed-routes');
      removeHandlerForLayerClick('highlighted-roads-my-earmarked-routes');
    };
  }, [prayerRoutes, addHandlerForLayerClick, removeHandlerForLayerClick]);

  return (
    <>
      <EarmarkedPrayerRoutesGeoJsonLayer
        prayerRoutes={prayerRoutes}
        earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
        selectedPrayerRouteId={selectedPrayerRouteId}
        beforeLayerId="z4"
      />
      {mapLegendState.isHeatmapChecked && <AllPrayerRoutesHeatmapVectorLayer beforeLayerId="z2" />}
      {mapLegendState.isMyRoutesChecked && (
        <MyCompletedPrayerRoutes
          prayerRoutes={prayerRoutes}
          routeColour={prayerWalkEvent.settings.colours.completedColour.code}
          selectedPrayerRouteId={selectedPrayerRouteId}
          beforeLayerId="z3"
        />
      )}
    </>
  );
};
