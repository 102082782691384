import React from 'react';
import HowToUsePage from 'app/modules/home/how-to-use';
import { useAppSelector } from 'app/config/store';

export const EventAwareHowToUsePage = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  return (
    <HowToUsePage
      preHowDoIGetStartedMarkdown={prayerWalkEvent.settings.content.preHowDoIGetStartedMarkdown}
      postHowDoIGetStartedMarkdown={prayerWalkEvent.settings.content.postHowDoIGetStartedMarkdown}
      postConfirmingYourRouteIsCompletedMarkdown={prayerWalkEvent.settings.content.postConfirmingYourRouteIsCompletedMarkdown}
      completedColourName={prayerWalkEvent.settings.colours.completedColour.name}
      earmarkedColourName={prayerWalkEvent.settings.colours.earmarkedColour.name}
    />
  );
};

export default EventAwareHowToUsePage;
