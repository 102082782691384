import React, { useEffect } from 'react';
import { useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import PrayerRoutesGeoJsonLayer from 'app/modules/map/layers/prayer-routes-geo-json-layer';
import { useMap, ViewState } from 'react-map-gl';
import bbox from '@turf/bbox';
import HomeScreenBackground from 'app/modules/home/home-screen-background';

export const PrayerRouteSingleMapLayers = () => {
  const prayerWalkEvent = useCurrentEvent();
  const prayerRouteEntity = useAppSelector(state => state.prayerRoute.entity);
  const mapRef = useMap();

  useEffect(() => {
    const map = mapRef.roadsMap;
    if (prayerRouteEntity.route && prayerRouteEntity.route.features.length > 0 && map) {
      const previousViewState: ViewState = {
        longitude: map.getCenter().lng,
        latitude: map.getCenter().lat,
        zoom: map.getZoom(),
        bearing: 0,
        pitch: 0,
        padding: map.getPadding(),
      };

      const [minLng, minLat, maxLng, maxLat] = bbox(prayerRouteEntity.route);
      try {
        map.fitBounds([minLng, minLat, maxLng, maxLat], {
          padding: {
            top: 100,
            left: 100,
            bottom: 350,
            right: 100,
          },
        });
      } catch (e) {
        console.log('Caught error when fitting bounds', [minLng, minLat, maxLng, maxLat], e);
        return;
      }

      return () => {
        map.flyTo({
          center: { lat: previousViewState.latitude, lng: previousViewState.longitude },
          zoom: previousViewState.zoom,
          padding: previousViewState.padding,
        });
      };
    }
  }, [prayerRouteEntity, mapRef]);

  return (
    <PrayerRoutesGeoJsonLayer
      name="completion-candidate"
      prayerRoutes={[prayerRouteEntity]}
      completedRouteColour={prayerWalkEvent.settings.colours.completedColour.code}
      earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
    />
  );
};
