import dayjs from 'dayjs';
import { SubscriptionLevel } from 'app/shared/model/enumerations/subscription-level.model';
import { SubscriptionStatus } from 'app/shared/model/enumerations/subscription-status.model';

export interface IPaymentSubscription {
  id?: number;
  expiresAt?: string | null;
  customerId?: string | null;
  subscriptionLevel?: SubscriptionLevel | null;
  status?: SubscriptionStatus | null;
}

export const defaultValue: Readonly<IPaymentSubscription> = {};
