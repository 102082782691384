import React from 'react';
import { Helmet } from 'react-helmet';
import { useAppSelector } from 'app/config/store';

export const ManagementFavicon = () => {
  return (
    <Helmet>
      <title>Prayer walk app management</title>
      <meta name="description" content={`For churches and organisations to manage prayer walking`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/management%2Fapple-touch-icon.png?alt=media`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/management%2Ffavicon-32x32.png?alt=media`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/management%2Ffavicon-16x16.png?alt=media`}
      />
      <link
        rel="mask-icon"
        href={`https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/management%2Fsafari-pinned-tab.svg?alt=media`}
        color="#4fada0"
      />
      <link
        rel="shortcut icon"
        href={`https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/management%2Ffavicon.ico?alt=media`}
      />
      <meta name="msapplication-TileColor" content="#2f70ff" />
      <meta
        name="msapplication-config"
        content={`https://firebasestorage.googleapis.com/v0/b/prayer-walk-d05d2.appspot.com/o/management%2Fbrowserconfig.xml?alt=media`}
      />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};
