import {Environments} from "app/config/constants";

export const getEnv = (): string | null => {
  const host = window.location.host;
  if (host.indexOf('localhost') > -1) {
    return Environments.LOCAL;
  } else if (host.indexOf('stg') > -1) {
    return Environments.STAGING;
  } else {
    return Environments.PRODUCTION;
  }
}