import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import {
  handlePasswordResetFinish,
  handlePasswordResetInit,
  reset as passwordResetReset,
} from 'app/modules/account/password-reset/password-reset.reducer';
import { FormField, FormPassword } from 'app/shared/components/form-field';
import { Alert, AlertIcon, Button, chakra, HStack, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getUrlParameter, isEmail } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export const ForgottenPasswordForm = () => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { email: '' } });
  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  const onSubmit = handleSubmit(data => {
    dispatch(handlePasswordResetInit({ email: data.email, prayerWalkEvent }));
  });

  return (
    <chakra.form onSubmit={onSubmit}>
      {successMessage && (
        <Alert status="success" rounded="md" mb={4}>
          <AlertIcon />
          {successMessage}
        </Alert>
      )}
      <Stack spacing="8">
        <FormField
          label="Email"
          errors={errors}
          {...register('email', {
            required: { value: true, message: 'Your email is required.' },
            minLength: { value: 5, message: 'Your email is required to be at least 5 characters.' },
            maxLength: { value: 254, message: 'Your email cannot be longer than 50 characters.' },
            validate: v => isEmail(v) || 'Your email is invalid.',
          })}
          helperText="Enter the email address you used to register"
          data-cy="emailResetPassword"
        />
        <HStack>
          <Button size="lg" type="button" as={Link} to={'/'}>
            Cancel
          </Button>
          <Button flexGrow={1} data-cy="submit" type="submit" colorScheme="primary" size="lg" isLoading={isSubmitting}>
            Send password reset
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  );
};

export const ForgottenPasswordFinishForm = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { newPassword: '', confirmPassword: '' } });
  const successMessage = useAppSelector(state => state.passwordReset.successMessage);
  const errorMessage = useAppSelector(state => state.passwordReset.errorMessage);

  const onSubmit = handleSubmit(({ newPassword }) => dispatch(handlePasswordResetFinish({ key, newPassword })));
  const [key] = useState(getUrlParameter('key', location.search));

  useEffect(
    () => () => {
      dispatch(passwordResetReset());
    },
    []
  );

  const password = watch('newPassword');

  if (successMessage) {
    return (
      <Stack>
        <Alert status="success" rounded="md" mb={4}>
          <AlertIcon />
          {successMessage}
        </Alert>
        <Button size="lg" type="button" as={Link} to={'/'}>
          Go to sign in
        </Button>
      </Stack>
    );
  }

  return (
    <chakra.form onSubmit={onSubmit}>
      {errorMessage && (
        <Alert status="error" rounded="md" mb={4}>
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
      <Stack spacing="8">
        <FormPassword
          label="Password"
          errors={errors}
          isRequired={true}
          placeholder="password"
          {...register('newPassword', {
            required: { value: true, message: 'Your password is required.' },
            minLength: { value: 4, message: 'Your password is required to be at least 4 characters.' },
            maxLength: { value: 50, message: 'Your password cannot be longer than 50 characters.' },
          })}
          data-cy="password"
        />

        <FormPassword
          label="New password confirmation"
          errors={errors}
          isRequired={true}
          placeholder="Confirm the new password"
          {...register('confirmPassword', {
            required: { value: true, message: 'Your confirmation password is required.' },
            minLength: { value: 4, message: 'Your confirmation password is required to be at least 4 characters.' },
            maxLength: { value: 50, message: 'Your confirmation password cannot be longer than 50 characters.' },
            validate: v => v === password || 'The password and its confirmation do not match!',
          })}
          data-cy="password"
        />
        <HStack>
          <Button size="lg" type="button" as={Link} to={'/'}>
            Cancel
          </Button>
          <Button flexGrow={1} data-cy="submit" type="submit" colorScheme="primary" size="lg" isLoading={isSubmitting}>
            Save new password
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  );
};
