import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

import getStore, { useAppSelector } from './config/store';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';
import * as Sentry from '@sentry/browser';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PrayerWalkEventAgnosticRoutes } from 'app/routes';
import PrayerWalkEventDetector from 'app/modules/prayer-walk-event/prayer-walk-event-detector';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';

Sentry.init({
  dsn: 'https://4610575c0427410e83c9ea45951c6134@o537368.ingest.sentry.io/5655470',
});

const store = getStore();

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();

dayjs.extend(RelativeTime);

const rootEl = document.getElementById('root');
const root = createRoot(rootEl); // createRoot(container!) if you use TypeScript

const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ErrorBoundary>
          <PrayerWalkEventDetector ComponentIfNoEventFound={PrayerWalkEventAgnosticRoutes}>
            <EventAwareChakraProvider>
              <Component />
            </EventAwareChakraProvider>
          </PrayerWalkEventDetector>
        </ErrorBoundary>
      </Provider>
    </ErrorBoundary>
  );

const EventAwareChakraProvider = ({ children }) => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);

  return (
    <ChakraProvider
      resetCSS={true}
      theme={extendTheme({
        colors: {
          primary: {
            500: prayerWalkEvent.settings.colours.branding.primary,
          },
          dark: {
            500: '#444549',
          },
        },
      })}
    >
      {children}
    </ChakraProvider>
  );
};
render(AppComponent);
