import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { IMapSettersProps } from 'app/entities/map/map-home';
import { Places, PlacesWithPrayerNeeds, PrayerNeedsGroupedByOsmId, SelectedPlace } from 'app/modules/map/layers/places';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import { getEntities } from 'app/entities/prayer-need/prayer-need.reducer';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';

export const PrayerNeedLayers = (props: IMapSettersProps) => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);
  const mapLegendState = useAppSelector(state => state.mapLegendState);
  const prayerNeeds = useAppSelector(state => state.prayerNeed.entities) as IPrayerNeed[];
  const resolved = useResolvedPath('/prayer-need-places/:id');
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();
  const { setInteractiveLayerIds, removeInteractiveLayerIds, addHandlerForLayerClick, removeHandlerForLayerClick } = props;

  useEffect(() => {
    dispatch(getEntities({ prayerWalkEventId: prayerWalkEvent.id }));
  }, []);

  const handleClosePopup = useCallback(() => {
    navigate('/map');
  }, []);

  useEffect(() => {
    const interactiveLayerIds = [];
    if (mapLegendState.showAllPrayerNeeds) {
      interactiveLayerIds.push('prayer-need-place-and-count');
    }
    setInteractiveLayerIds(interactiveLayerIds);
    return () => removeInteractiveLayerIds(interactiveLayerIds);
  }, [setInteractiveLayerIds, mapLegendState]);

  useEffect(() => {
    const handler = (feature: any, lngLat: mapboxgl.LngLat) => {
      console.log('Prayer need layers', feature);
      navigate(`prayer-need-places/${feature.properties.osm_id}`);
    };
    addHandlerForLayerClick('prayer-need-place-and-count', handler);
    return () => {
      removeHandlerForLayerClick('prayer-need-place-and-count');
    };
  }, [addHandlerForLayerClick]);

  const prayerNeedsGroupedByOsmId: PrayerNeedsGroupedByOsmId = useMemo(() => {
    return prayerNeeds.reduce((acc: PrayerNeedsGroupedByOsmId, curr: IPrayerNeed) => {
      if (acc[curr.prayerFeature.osmId]) {
        acc[curr.prayerFeature.osmId].push(curr);
      } else {
        acc[curr.prayerFeature.osmId] = [curr];
      }
      return acc;
    }, {});
  }, [prayerNeeds]);

  return (
    <>
      {mapLegendState.showAllPrayerNeeds ? (
        <PlacesWithPrayerNeeds
          tileUrl={`${tileUrl}/public.prayer_need_places/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`}
          prayerNeeds={prayerNeedsGroupedByOsmId}
          selectedPrayerNeedPlaceOsmId={match && match.params.id}
        />
      ) : (
        <Places tileUrl={`${tileUrl}/public.prayer_need_places/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`} />
      )}
    </>
  );
};
