import { TranslatorContext } from 'react-jhipster';

export interface IDistanceFormat {
  distanceInKm?: number;
  locale?: string;
}

type DistanceUnit = 'Kilometres' | 'Miles';

const formattedDistance = (distance: number, desiredUnit: DistanceUnit, locale: string) => {
  if (desiredUnit === 'Kilometres') {
    return formatKms(distance, locale);
  } else {
    return formatMiles(distance / 1.609);
  }
};

function formatKms(walkedKms: number, locale: any = navigator.language) {
  try {
    if (walkedKms < 1) {
      const meters = walkedKms * 1000;
      return new Intl.NumberFormat(locale, {
        style: 'unit',
        unit: 'meter',
        unitDisplay: 'narrow',
        notation: 'compact',
        maximumFractionDigits: 0,
      }).format(meters);
    } else {
      return new Intl.NumberFormat(locale, {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'narrow',
        maximumFractionDigits: 1,
      }).format(walkedKms);
    }
  } catch (e) {
    if (walkedKms < 1) {
      return `${(walkedKms * 1000).toFixed(0)} m`;
    } else {
      return `${walkedKms.toFixed(1)} km`;
    }
  }
}

function formatMiles(walkedMiles: number) {
  try {
    if (walkedMiles < 1) {
      const yards = walkedMiles * 1760;
      return new Intl.NumberFormat('en-US', {
        style: 'unit',
        unit: 'yard',
        unitDisplay: 'narrow',
        maximumFractionDigits: 0,
      }).format(yards);
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'unit',
        unit: 'mile',
        unitDisplay: 'narrow',
        maximumFractionDigits: 1,
      }).format(walkedMiles);
    }
  } catch (e) {
    if (walkedMiles < 1) {
      return `${(walkedMiles * 1760).toFixed(0)} yd`;
    } else {
      return `${walkedMiles.toFixed(1)} mi`;
    }
  }
}

export const distanceFormat = ({ distanceInKm, locale }: IDistanceFormat): string => {
  if (!locale) {
    if (navigator.language) {
      locale = navigator.language;
    } else {
      locale = 'en-GB';
    }
  }

  let distanceUnit: DistanceUnit;
  if (locale === 'en-US') {
    distanceUnit = 'Miles';
  } else {
    distanceUnit = 'Kilometres';
  }

  if (!distanceInKm) {
    return formattedDistance(0, distanceUnit, locale);
  }

  return formattedDistance(distanceInKm, distanceUnit, locale);
};
