import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Heading, ListItem, List, ListIcon } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ActionNavbarV2 } from 'app/shared/route/actions-navbar';

interface IHowToUseProps {
  preHowDoIGetStartedMarkdown: string;
  postHowDoIGetStartedMarkdown: string;
  postConfirmingYourRouteIsCompletedMarkdown: string;
  completedColourName: string;
  earmarkedColourName: string;
}

const markdownConfig = {
  h1: (p: any) => <Heading as="h1" size="lg" mt={8} mb={1} {...p} />,
  h2: (p: any) => <Heading as="h2" size="md" mt={6} mb={1} {...p} />,
  h3: (p: any) => <Heading as="h3" size="md" mt={4} mb={1} {...p} />,
  h4: (p: any) => <Heading as="h4" size="sm" mt={2} mb={1} {...p} />,
  h5: (p: any) => <Heading as="h5" size="sm" mt={2} mb={1} {...p} />,
  hr: (p: any) => <Box as="hr" my={4} {...p} />,
  p: (p: any) => <Text as="p" mt={2} {...p} />,
};

export const HowToUsePage = (props: IHowToUseProps) => {
  const howToGetStarted = [
    `Once in the App, you will see the map. The ${props.completedColourName} lines are the roads other people have already prayer-walked. The ${props.earmarkedColourName} lines are roads that have been earmarked by someone else, but not yet completed.`,
    'Click the "Add a prayer walk" button at the bottom to start selecting your route.',
    'To zoom in or out on the map, pinch the screen on a phone or tablet or use the +/- controls in the top left-hand corner if you are using a computer.',
    'If you are planning to walk this route, select "Planning to" to create an earmarked walk which you can later mark as completed.',
    'If you\'ve already done the prayer walk, select "Already Prayed" to create a completed route.',
    'Click on the streets to select your route. Simply click a second time to deselect a street.',
    'Once you have selected your route, click the "Save" button at the bottom to confirm, and that route will be saved.',
    'Please note – you must confirm the completion of your route (described below) before you can earmark a second route.',
  ];

  const routeConfirmation = [
    'When you have prayer-walked your route and it is completed, please go back into the App.',
    'Now click the "complete" button on the bottom right hand corner of the screen, and then the "yes, it’s been walked" button. This step is really important for us to see collectively how much of the map has been covered.',
    'If you now want to prayer walk again, please click the "Add a prayer walk" button at the bottom of the map, and then proceed again as in the previous section above - "Selecting your route".',
  ];

  return (
    <Flex direction="column" grow={1}>
      <ActionNavbarV2 navigateBackTo="/account" />
      <Box px={8}>
        {props.preHowDoIGetStartedMarkdown && (
          <ReactMarkdown components={markdownConfig}>{props.preHowDoIGetStartedMarkdown}</ReactMarkdown>
        )}
        <Heading as="h2" size="md" mt={6} mb={1}>
          How do I get started?
        </Heading>

        <List spacing={2} px={2} py={2}>
          {howToGetStarted.map(item => (
            <ListItem key={item}>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              {item}
            </ListItem>
          ))}
        </List>

        {props.postHowDoIGetStartedMarkdown && (
          <ReactMarkdown components={markdownConfig}>{props.postHowDoIGetStartedMarkdown}</ReactMarkdown>
        )}
        <Heading as="h2" size="md" mt={6} mb={1}>
          Confirming Your Route is Completed
        </Heading>

        <List spacing={2} px={2} py={2}>
          {routeConfirmation.map(item => (
            <ListItem key={item}>
              <ListIcon as={CheckCircleIcon} color="blue.500" />
              {item}
            </ListItem>
          ))}
        </List>
        {props.postConfirmingYourRouteIsCompletedMarkdown && (
          <ReactMarkdown components={markdownConfig}>{props.postConfirmingYourRouteIsCompletedMarkdown}</ReactMarkdown>
        )}
      </Box>
    </Flex>
  );
};

export default HowToUsePage;
