import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled, SerializedError } from '@reduxjs/toolkit';

import { IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

import { IPrayerNeedDownload } from 'app/shared/model/prayer-need-download.model';

const initialState: PrayerNeedTableState = {
  loading: false,
  errorMessage: null,
  prayerNeeds: [],
  totalItems: 0,
};

export type PrayerNeedTableState = {
  loading: boolean;
  errorMessage?: SerializedError | null;
  prayerNeeds: IPrayerNeedDownload[];
  totalItems: number;
};

export const getPrayerNeedTable = createAsyncThunk(
  'prayerNeedTable/get',
  async ({ page, size, sort, id }: IQueryParams & { id: string | number }) =>
    axios.get<IPrayerNeedDownload[]>(
      `/api/prayer-walk-events/${id}/prayer-needs-for-download${
        sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'
      }cacheBuster=${new Date().getTime()}`
    ),
  {
    serializeError: serializeAxiosError,
  }
);

export const PrayerNeedTableSlice = createSlice({
  name: 'prayerNeedTable',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPrayerNeedTable.pending, state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addCase(getPrayerNeedTable.rejected, (state, payload) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addMatcher(isFulfilled(getPrayerNeedTable), (state, action): PrayerNeedTableState => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          prayerNeeds: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      });
  },
});

export const { reset } = PrayerNeedTableSlice.actions;

// Reducer
export default PrayerNeedTableSlice.reducer;
