import React, { useLayoutEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';

export const Logout = () => {
  const oauthLogin = useAppSelector(state => state.authentication.oauthLogin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (oauthLogin) {
      window.location.href = `/oauth2/logout?redirectTo=${window.location.origin}`;
    } else {
      dispatch(logout());
      navigate('/');
    }
  });

  return null;
};

export default Logout;
