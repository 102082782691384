import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';

import { NavDropdown } from './menu-components';
import { HeaderNavItem } from 'app/shared/layout/menus/header-nav-item';

const accountMenuItemsAuthenticated = () => (
  <>
    <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
      Settings
    </MenuItem>
    <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
      Password
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
      Sign out
    </MenuItem>
  </>
);

const accountMenuItems = () => (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
      Sign in
    </MenuItem>
    <MenuItem icon="user-plus" to="/account/register" data-cy="register">
      Register
    </MenuItem>
  </>
);

export const WebAccountMenu = ({ isAuthenticated = false }) => (
  <NavDropdown icon="user" name="Account" id="account-menu" data-cy="accountMenu">
    {isAuthenticated ? accountMenuItemsAuthenticated() : accountMenuItems()}
  </NavDropdown>
);

export const MoreMenu = ({ isAuthenticated = false }) =>
  // tslint:disable-next-line:jsx-self-close
  isAuthenticated && <HeaderNavItem id="more" title="More" href="/account" icon={<FontAwesomeIcon icon={faBars} size="lg" />} />;

export const SignOutMenu = ({ isAuthenticated = false }) =>
  // tslint:disable-next-line:jsx-self-close
  isAuthenticated && (
    <NavItem>
      <NavLink id="logout" tag={Link} to="/logout" activeClassName="active" replace className="d-flex align-items-center flex-column">
        <FontAwesomeIcon icon={faSignOutAlt} size="2x" />
        <span>Logout</span>
      </NavLink>
    </NavItem>
  );

export default MoreMenu;
