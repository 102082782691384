import React, { useCallback, useMemo } from 'react';
import { Layer, Source, LayerProps } from 'react-map-gl';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import {
  combineFeaturesIntoTwoSetsOfHighlightedRoutes,
  transformToHighlightedRoad,
  combineFeaturesIntoOneFeatureCollection,
} from 'app/modules/map/utils';
import { IHighlightedRoad } from 'app/modules/map/types';

export interface IPrayerRoutesGeoJsonLayerProps {
  prayerRoutes: IPrayerRoute[];
  routeColour: string;
  selectedPrayerRouteId?: string;
  beforeLayerId?: string;
}

const MyPrayerRoutesGeoJsonLayer = (props: IPrayerRoutesGeoJsonLayerProps) => {
  const routes = props.prayerRoutes.filter(route => route.completed === true);
  const featureCollection = combineFeaturesIntoOneFeatureCollection(routes);
  const getLayerProps = useCallback((routeColour: string, selectedPrayerRouteId?: string): LayerProps => {
    return {
      id: `highlighted-roads-my-completed-routes`,
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': routeColour, // default,
        'line-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 14, 3, 18, 6],
        'line-opacity': selectedPrayerRouteId
          ? ['case', ['==', parseInt(selectedPrayerRouteId, 10), ['get', 'prayer_route_id']], 1, 0.2]
          : 1,
      },
    };
  }, []);

  return (
    <Source id={`highlighted-roads-my-completed-routes`} type="geojson" data={featureCollection}>
      <Layer {...getLayerProps(props.routeColour, props.selectedPrayerRouteId)} beforeId={props.beforeLayerId} />
    </Source>
  );
};

export default MyPrayerRoutesGeoJsonLayer;
