import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { LngLat, MapboxGeoJSONFeature, MapProvider, ViewState } from 'react-map-gl';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Box, Flex, Card, CardHeader, CardBody, Text, HStack, Button, Heading, VStack } from '@chakra-ui/react';
import { MapWithRoads } from './map-with-roads';
import { PrayerRouteDeleteMapOverlay } from './overlay/prayer-route-delete-map-overlay';
import { PrayerRouteUncompleteMapOverlay } from './overlay/prayer-route-uncomplete-map-overlay';
import { PrayerRouteCompleteMapOverlay } from 'app/entities/map/overlay/prayer-route-complete-map-overlay';
import { MapHomeActions, MapHomeLayersAndOutlet } from 'app/entities/map/layers/map-home-layers-and-outlet';
import { PrayerRouteUpdateMapLayers } from 'app/entities/map/layers/prayer-route-update-map-layers';
import { PrayerNeedUpdateMapLayers } from 'app/entities/map/layers/prayer-need-update-map-layers';
import { PrayerNeedCardsForPlace } from 'app/entities/map/layers/prayer-need-cards-for-place';
import { PrayerRouteCard } from 'app/entities/map/layers/prayer-route-cards';
import { HeatmapSelectedCard } from 'app/entities/map/layers/heatmap-selected-cards';
import { useMapPageTour } from 'app/shared/components/feature-tour';

export type MapboxClickHandler = (features: MapboxGeoJSONFeature, point: LngLat) => void;
export interface IMapSettersProps {
  addHandlerForLayerClick?: (layerId: string, handler: MapboxClickHandler) => void;
  removeHandlerForLayerClick?: (layerId: string) => void;
  setInteractiveLayerIds?: Dispatch<SetStateAction<string[]>>;
  removeInteractiveLayerIds?: Dispatch<SetStateAction<string[]>>;
}
export const MapHome = () => {
  const prayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const [mapOnClick, setMapOnClick] = useState(() => () => {});
  const [mapClickHandlers, setMapClickHandlers] = useState<Record<string, MapboxClickHandler>>({});
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<Set<string>>(new Set<string>());
  useMapPageTour();

  const addInteractiveLayerIds = useCallback(
    (layerIds: string[]) => {
      setInteractiveLayerIds(prev => {
        const existingLayerIds = new Set(prev);
        layerIds.forEach(layerId => existingLayerIds.add(layerId));
        return existingLayerIds;
      });
    },
    [setInteractiveLayerIds]
  );

  const removeInteractiveLayerIds = useCallback(
    (layerIds: string[]) => {
      setInteractiveLayerIds(prev => {
        const existingLayerIds = new Set(prev);
        layerIds.forEach(layerId => existingLayerIds.delete(layerId));
        return existingLayerIds;
      });
    },
    [setInteractiveLayerIds]
  );

  const addHandlerForLayerClick = useCallback(
    (layerId: string, handler: MapboxClickHandler) => {
      setMapClickHandlers(prev => {
        prev[layerId] = handler;
        return prev;
      });
    },
    [setMapClickHandlers]
  );

  const removeHandlerForLayerClick = useCallback(
    (layerId: string) => {
      setMapClickHandlers(prev => {
        delete prev[layerId];
        return prev;
      });
    },
    [setMapClickHandlers]
  );

  useEffect(() => {
    setMapOnClick(() => (feature: any, lngLat: mapboxgl.LngLat) => {
      Object.entries(mapClickHandlers).forEach(([layerId, handler]) => {
        if (layerId === feature.layer.id) {
          handler(feature, lngLat);
        }
      });
    });
  }, [mapClickHandlers, setMapOnClick]);

  return (
    <Flex grow={1} direction="column">
      {!prayerWalkEvent.enabled && (
        <Alert
          color="warning"
          style={{
            marginBottom: 0,
            borderRadius: 0,
          }}
        >
          {prayerWalkEvent.settings.content.disabledMessage}
        </Alert>
      )}
      <Flex grow={1}>
        <MapProvider>
          <MapWithRoads interactiveLayerIds={Array.from(interactiveLayerIds)} onClick={mapOnClick}>
            <Routes>
              {/* Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function. */}
              {/* making earmarked and completed layers interactive - but not knowing if any are present, does it matter on a tile source? Maybe only geojson sources have this error */}
              <Route
                path={`prayer-route/new`}
                element={
                  <PrayerRouteUpdateMapLayers
                    addHandlerForLayerClick={addHandlerForLayerClick}
                    removeHandlerForLayerClick={removeHandlerForLayerClick}
                    setInteractiveLayerIds={addInteractiveLayerIds}
                    removeInteractiveLayerIds={removeInteractiveLayerIds}
                  />
                }
              />
              <Route
                path={`prayer-need/new/*`}
                element={
                  <PrayerNeedUpdateMapLayers
                    addHandlerForLayerClick={addHandlerForLayerClick}
                    removeHandlerForLayerClick={removeHandlerForLayerClick}
                    setInteractiveLayerIds={addInteractiveLayerIds}
                    removeInteractiveLayerIds={removeInteractiveLayerIds}
                  />
                }
              />
              <Route
                path={`prayer-need/:id/edit/*`}
                element={
                  <PrayerNeedUpdateMapLayers
                    addHandlerForLayerClick={addHandlerForLayerClick}
                    removeHandlerForLayerClick={removeHandlerForLayerClick}
                    setInteractiveLayerIds={addInteractiveLayerIds}
                    removeInteractiveLayerIds={removeInteractiveLayerIds}
                  />
                }
              />
              <Route
                path={`prayer-route/:id/edit`}
                element={
                  <PrayerRouteUpdateMapLayers
                    addHandlerForLayerClick={addHandlerForLayerClick}
                    removeHandlerForLayerClick={removeHandlerForLayerClick}
                    setInteractiveLayerIds={addInteractiveLayerIds}
                    removeInteractiveLayerIds={removeInteractiveLayerIds}
                  />
                }
              />
              <Route path={`prayer-route/:id/complete`} element={<PrayerRouteCompleteMapOverlay />} />
              <Route path={`prayer-route/:id/uncomplete`} element={<PrayerRouteUncompleteMapOverlay />} />
              <Route path={`prayer-route/:id/delete`} element={<PrayerRouteDeleteMapOverlay />} />
              <Route
                path={`/`}
                element={
                  <MapHomeLayersAndOutlet
                    addHandlerForLayerClick={addHandlerForLayerClick}
                    removeHandlerForLayerClick={removeHandlerForLayerClick}
                    setInteractiveLayerIds={addInteractiveLayerIds}
                    removeInteractiveLayerIds={removeInteractiveLayerIds}
                  />
                }
              >
                <Route index element={<MapHomeActions />} />
                <Route path={`prayer-need-places/:id`} element={<PrayerNeedCardsForPlace />} />
                <Route path={`prayer-route-selected/:prayerRouteId`} element={<PrayerRouteCard />} />
                <Route path={`heatmap-selected`} element={<HeatmapSelectedCard />} />
              </Route>
            </Routes>
          </MapWithRoads>
        </MapProvider>
      </Flex>
    </Flex>
  );
};
