import React from 'react';

import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMap } from '@fortawesome/free-solid-svg-icons';
import { HeaderNavItem } from 'app/shared/layout/menus/header-nav-item';

export const PrayerRouteMenu = props => (
  <HeaderNavItem id="map" title="Map" href="/map" icon={<FontAwesomeIcon icon={faMap} size="lg" />} />
);
