import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VStack, Box } from '@chakra-ui/react';

export const HeaderNavItem = ({ title, href, icon, id }: { title: string; href: string; icon: React.ReactElement; id: string }) => (
  <Link id={id} className={({ isActive }) => (isActive ? 'active' : '')} to={href}>
    <VStack
      color="gray.400"
      p={4}
      _focus={{ color: 'gray.50' }}
      _hover={{ color: 'gray.50' }}
      sx={{
        '.active &': {
          color: 'white',
        },
      }}
      alignItems="center"
    >
      {icon}
      <Box textAlign="center">{title}</Box>
    </VStack>
  </Link>
);
