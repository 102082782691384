import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPrayerNeed, defaultValue } from 'app/shared/model/prayer-need.model';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';

const initialState: EntityState<IPrayerNeed> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = prayerWalkEventId => `api/prayer-walk-events/${prayerWalkEventId}/prayer-needs`;

// Actions

export const getEntities = createAsyncThunk(
  'prayerNeed/fetch_entity_list',
  async ({ prayerWalkEventId }: { prayerWalkEventId: string | number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}?cacheBuster=${new Date().getTime()}`;
    return axios.get<IPrayerNeed[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'prayerNeed/fetch_entity',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: string | number; id: string | number }) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}/${id}`;
    return axios.get<IPrayerNeed>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'prayerNeed/create_entity',
  async ({ prayerWalkEventId, entity }: { prayerWalkEventId: string | number; entity: IPrayerNeed }, thunkAPI) => {
    const result = await axios.post<IPrayerNeed>(apiUrl(prayerWalkEventId), cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'prayerNeed/update_entity',
  async ({ prayerWalkEventId, entity }: { prayerWalkEventId: string | number; entity: IPrayerNeed }, thunkAPI) => {
    const result = await axios.put<IPrayerNeed>(`${apiUrl(prayerWalkEventId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'prayerNeed/partial_update_entity',
  async ({ prayerWalkEventId, entity }: { prayerWalkEventId: string | number; entity: IPrayerNeed }, thunkAPI) => {
    const result = await axios.patch<IPrayerNeed>(`${apiUrl(prayerWalkEventId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'prayerNeed/delete_entity',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: string | number; id: string | number }, thunkAPI) => {
    const requestUrl = `${apiUrl(prayerWalkEventId)}/${id}`;
    const result = await axios.delete<IPrayerNeed>(requestUrl);
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const markAsPrayedFor = createAsyncThunk(
  'prayerNeed/mark_as_prayed_for',
  async ({ prayerWalkEventId, id }: { prayerWalkEventId: string | number; id: string | number }, thunkAPI) => {
    const result = await axios.post<IPrayerNeed>(`${apiUrl(prayerWalkEventId)}/${id}/prayed-for`);
    thunkAPI.dispatch(getEntities({ prayerWalkEventId }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const PrayerNeedSlice = createEntitySlice({
  name: 'prayerNeed',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(markAsPrayedFor.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, markAsPrayedFor), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = PrayerNeedSlice.actions;

// Reducer
export default PrayerNeedSlice.reducer;
