import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk, isRejected } from '@reduxjs/toolkit';

import { isRejectedAction, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { MailingListSlice } from 'app/modules/organisation/reducers/mailing-list.reducer';
import { IUser } from 'app/shared/model/user.model';

type CheckoutSession = { url?: string | undefined };
const initialState: {
  updating: boolean;
  successMessage?: string;
  updateSuccess: boolean;
  updateFailure: boolean;
  errorMessage?: string;
} = {
  updating: false,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
  errorMessage: null,
};

export const createUserAndInviteToOrganisation = createAsyncThunk(
  'createUserAndInviteToOrganisation/fetch',
  async ({ user, authorityName }: { user: IUser; authorityName: string }) => {
    const requestUrl = `/api/user/organisation/invitation`;
    return axios.post<CheckoutSession>(requestUrl, {
      user,
      authorityName,
    });
  },
  { serializeError: serializeAxiosError }
);

export const CreateUserAndInviteToOrganisationSlice = createSlice({
  name: 'createUserAndInviteToOrganisation',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(createUserAndInviteToOrganisation), state => {
        state.errorMessage = null;
        state.updating = true;
      })
      .addMatcher(isFulfilled(createUserAndInviteToOrganisation), (state, action) => {
        return {
          ...state,
          updating: false,
          updateSuccess: true,
        };
      })
      .addMatcher(isRejected(createUserAndInviteToOrganisation), (state, action) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export const { reset } = CreateUserAndInviteToOrganisationSlice.actions;

// Reducer
export default CreateUserAndInviteToOrganisationSlice.reducer;
