import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'app/config/dayjs.ts';

import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession, onSuccessLoginWithOAuth2 } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route-2';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes, { PrayerWalkEventAgnosticRoutes } from 'app/routes';
import PrayerWalkEventDetector from 'app/modules/prayer-walk-event/prayer-walk-event-detector';
import EventAwareFavicon from 'app/modules/prayer-walk-event/event-aware-favicon';
import EventAwareTheming from 'app/modules/prayer-walk-event/event-aware-theming';
import { InstallPWA } from './shared/components/install-pwa';
import { isEventManagerForCurrentEvent } from 'app/shared/auth/event-manager';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { PrayerWalkingRouter } from 'app/shared/route/PrayerWalkingRouter';
import { FeatureTour, FeatureTourContext, FeatureTourProvider } from 'app/shared/components/feature-tour';

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const isEventManager = useAppSelector(state => isEventManagerForCurrentEvent(state.authentication, state.currentPrayerWalkEvent));
  const prayerWalkEventSettings = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent.settings);
  const isNavbarVisible = useAppSelector(state => state.appLayout.navIsVisible);

  return (
    <PrayerWalkingRouter>
      <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
      <div
        id="app-view-container"
        className="flex-grow-1 d-flex contain"
        style={{
          overflowY: 'scroll',
          minHeight: '0',
        }}
      >
        <ErrorBoundary>
          <EventAwareTheming />
          <EventAwareFavicon />
          <InstallPWA />
          <FeatureTourProvider>
            <FeatureTour />
            <AppRoutes />
          </FeatureTourProvider>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Header
          isAuthenticated={isAuthenticated}
          isAdmin={isAdmin}
          ribbonEnv={ribbonEnv}
          isInProduction={isInProduction}
          isOpenAPIEnabled={isOpenAPIEnabled}
          isEventManager={isEventManager}
          isVisible={isNavbarVisible}
          prayerWalkEventSettings={prayerWalkEventSettings}
        />
      </ErrorBoundary>
    </PrayerWalkingRouter>
  );
};

export default App;
