import { Box, Button, Flex, keyframes, Spacer } from '@chakra-ui/react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, forwardRef, useRef } from 'react';
import Joyride, { CallBackProps, STATUS, EVENTS, Step, BeaconRenderProps, TooltipRenderProps, StoreHelpers } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { useCurrentEventFeatures } from 'app/shared/hooks/useCurrentEvent';

const pulse = keyframes({
  '0%': {
    transform: 'translate3d(0, 0, 0) scale(1)',
  },
  '50%': {
    transform: 'translate3d(0, 0, 0) scale(1.3)',
  },
  '100%': {
    transform: 'translate3d(0, 0, 0) scale(1)',
  },
});

const BeaconComponent = forwardRef<HTMLButtonElement, BeaconRenderProps>(({ size, ...props }, ref) => {
  return (
    <Button
      borderRadius="50%"
      border={0}
      colorScheme="primary"
      animation={`${pulse} 1s ease-in-out infinite`}
      display="inline-block"
      height={4}
      width={4}
      ref={ref}
      p={0}
      m={0}
      minW={0}
      {...props}
    />
  );
});

const Tooltip = ({ backProps, continuous, index, isLastStep, primaryProps, skipProps, step, tooltipProps }: TooltipRenderProps) => {
  return (
    <Box {...tooltipProps} bg="white" border={0} maxWidth={420} minWidth={290} overflow="hidden" borderRadius="md">
      <Box padding="8">
        {step.title && (
          <Box color="primary" mb="md">
            {step.title}
          </Box>
        )}
        {step.content && <Box>{step.content}</Box>}
      </Box>
      <Box bg="primary.100" padding="2">
        <Flex>
          {!isLastStep && (
            <Button {...skipProps} size="sm">
              Skip tour
            </Button>
          )}
          <Spacer />
          <Flex gap={2}>
            {index > 0 && (
              <Button {...backProps} colorScheme="dark" size="sm">
                Back
              </Button>
            )}
            <Button {...primaryProps} colorScheme="primary" size="sm">
              {continuous ? 'Next' : 'Ok '}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

type StepAndAction = Step & { actionOnStepShown?: () => void };

export interface FeatureTourState {
  name?: string;
  complete: boolean;
  run: boolean;
  steps: StepAndAction[];
}

const defaultFeatureTourState: FeatureTourState = {
  name: undefined,
  complete: false,
  run: false,
  steps: [
    // Showing that you can log the same route twice and it will glow brighter
    // add a layer with heatmap colours to demo it?
    // showing that you can now log pedestrianised routes
    // query for pedestrianised roads? zoom in on them? Feels like it has no longevity
    // showing that you can filter and sort prayer needs, and click 'i've prayed'
    // needs fake data
    // showing the more tab, how you can update your details, give to Arise, find help (ideally there's also a button here to let people access the tour again if they want a refresher?)
    // I'm not sure where the checklist is going - perhaps somewhere on the progress tab? showing that, how it works, how you can check things off when you do them for the first time, and how that shows in footsteps next to your name on the participant list.
    // An invitation to share the app with friends and pointers for how best to do that.
  ],
};

export type FeatureTourContextProps = {
  featureTourState: FeatureTourState;
  setFeatureTourState: (patch: Partial<FeatureTourState> | ((previousState: FeatureTourState) => Partial<FeatureTourState>)) => void;
  reset: () => void;
  resetTourSeen: () => void;
};

export const FeatureTourContext = createContext<FeatureTourContextProps>({
  featureTourState: defaultFeatureTourState,
  setFeatureTourState() {},
  reset() {},
  resetTourSeen() {},
});
FeatureTourContext.displayName = 'FeatureTourContext';

// copy this in for now, it's not used anywhere else, can't be bothered to try make the tour context work with individual state values
const useSetState = <T extends object>(initialState: T = {} as T): [T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void] => {
  const [state, set] = useState<T>(initialState);
  const setState = useCallback(patch => {
    set(prevState => Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch));
  }, []);

  return [state, setState];
};

export function FeatureTourProvider(props: any) {
  const [state, setFeatureTourState] = useSetState(defaultFeatureTourState);

  const value = useMemo(
    () => ({
      featureTourState: state,
      setFeatureTourState,
      reset() {
        setFeatureTourState(defaultFeatureTourState);
      },
      resetTourSeen() {
        localStorage.removeItem('tourSkipped');
        localStorage.removeItem('mapPageTourComplete');
        localStorage.removeItem('progressPageTourComplete');
        localStorage.removeItem('prayerNeedsPageTourComplete');
      },
    }),
    [setFeatureTourState, state, defaultFeatureTourState]
  );

  return <FeatureTourContext.Provider value={value} {...props} />;
}

export function useFeatureTourContext(): FeatureTourContextProps {
  const context = useContext(FeatureTourContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}

export function useMapPageTour() {
  const { setFeatureTourState, reset } = useContext(FeatureTourContext);

  useEffect(() => {
    setFeatureTourState({
      name: 'mapPage',
      steps: [
        //     The features that are new for this year: highlight them as new in each section as you go?
        //     Navigate using the bottom bar: Toggling between Map, Prayer needs, Progress, and More, and you can do in each section
        {
          target: '#header-tabs',
          content: 'You can navigate between pages using this bottom bar, click on each icon to change page.',
        },
        //   Layers control: Toggling between the views on the map (my prayer-walks only, all prayerwalks)
        // What the colours on the map mean (how many times a road has been prayer-walked)
        {
          target: '#menu-button-layers',
          content: 'Here you can choose whats shown on the map. You can also see what the coloured lines on the map mean.',
        },
        // showing how you log a prayer-walk
        {
          target: '#create-route',
          content: 'Log a prayer walk by tapping the "Add a prayer walk" button',
        },

        // showing how you log a prayer need, and the different categories etc
        {
          target: '#create-prayer-need',
          content: 'You can add a prayer request for an area by tapping this button and following the instructions in the card.',
        },
      ],
    });

    return () => reset();
  }, []);
}

export function useProgressPageTour() {
  const { setFeatureTourState, reset } = useContext(FeatureTourContext);
  const prayerWalkEventFeatures = useCurrentEventFeatures();
  const navigate = useNavigate();

  useEffect(() => {
    const steps: StepAndAction[] = [
      {
        target: '#stat-bar',
        content:
          'Participants shows the number of people who have logged into this prayer walk event.\n\n' +
          'Total distance is the distance of all the completed prayer walks.\n\n' +
          'Prayer for every street is the percentage of streets in the boundary prayed for at least once.',
      },
      {
        target: '#participants-list',
        content:
          'These are all the people who are taking part in the prayer walk, ' +
          'who have prayed at least once and who have set their profile to publicly viewable.',
      },
    ];

    if (prayerWalkEventFeatures.prayerEventChallengesEnabled) {
      steps.push({
        target: '#myself-progress-link',
        content:
          "Tap here to show your progress. The myself area shows only what you've done. Here you can also see the prayer steps and mark each as complete.",
        actionOnStepShown() {
          navigate('myself');
        },
      });
    }

    setFeatureTourState({
      name: 'progressPage',
      steps,
    });
    return () => reset();
  }, []);
}

export function usePrayerNeedsPageTour() {
  const { setFeatureTourState, reset } = useContext(FeatureTourContext);

  useEffect(() => {
    setFeatureTourState({
      name: 'prayerNeedsPage',
      steps: [
        {
          target: '#prayer-need-list',
          content:
            'This is a list of the prayer needs submitted by other users. ' +
            "You can submit your own, or say you've prayed for someone else's by tapping the prayed for button.",
        },
      ],
    });
    return () => reset();
  }, []);
}

export const FeatureTour = () => {
  const {
    setFeatureTourState,
    featureTourState: { name, run, complete, steps },
  } = useFeatureTourContext();
  const [targetNotFound, setTargetNotFound] = useState(false);
  const { featureTourEnabled } = useCurrentEventFeatures();

  useEffect(() => {
    if (!name) {
      return;
    }
    const localTourComplete = localStorage.getItem(name + 'TourComplete');
    const tourSkipped = localStorage.getItem('tourSkipped');
    if (localTourComplete === null && tourSkipped === null) {
      setFeatureTourState({ run: true });
    }
  }, [name, steps]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;

    if (type === EVENTS.TARGET_NOT_FOUND) {
      setTargetNotFound(true);
    }
    if (type === EVENTS.TOUR_STATUS && status === STATUS.READY) {
      setTargetNotFound(false);
    }
    if (status === STATUS.FINISHED && targetNotFound === false) {
      setFeatureTourState({ complete: true, run: false });
      localStorage.setItem(name + 'TourComplete', 'true');
    }
    if (status === STATUS.SKIPPED && targetNotFound === false) {
      setFeatureTourState({ complete: true, run: false });
      localStorage.setItem('tourSkipped', 'true');
    }
    if (type === EVENTS.TOOLTIP && steps[index].actionOnStepShown !== undefined) {
      steps[index].actionOnStepShown();
    }
  };

  const helpers = useRef<StoreHelpers>();

  const setHelpers = (storeHelpers: StoreHelpers) => {
    helpers.current = storeHelpers;
  };

  if (!featureTourEnabled) {
    return null;
  }

  return (
    <Joyride
      beaconComponent={BeaconComponent}
      callback={handleJoyrideCallback}
      getHelpers={setHelpers}
      run={run}
      showSkipButton
      disableScrollParentFix
      steps={steps}
      styles={{
        options: {
          zIndex: 2000000,
        },
        overlay: {
          backgroundColor: 'rgba(23, 15, 8, 0.5)',
        },
      }}
      tooltipComponent={Tooltip}
    />
  );
};
