import React, { useEffect, PropsWithChildren } from 'react';
import { getPrayerWalkEventFromDomain } from 'app/modules/prayer-walk-event/current-prayer-walk-event.reducer';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { defaultValue } from 'app/shared/model/prayer-walk-event.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AppRoutesForOrganisation from 'app/modules/organisation/routes';

export interface IPrayerWalkEventDetectorProps {
  children: React.ReactNode;
  ComponentIfNoEventFound: () => JSX.Element;
}

const PrayerWalkEventDetector = (props: PropsWithChildren<IPrayerWalkEventDetectorProps>) => {
  const dispatch = useAppDispatch();
  const currentPrayerWalkEvent = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent);
  const loading = useAppSelector(state => state.currentPrayerWalkEvent.loading);
  const { children, ComponentIfNoEventFound } = props;

  useEffect(() => {
    if (window.location.host.indexOf('management') !== 0) {
      dispatch(getPrayerWalkEventFromDomain());
    }
  }, []);

  if (window.location.host.indexOf('management') === 0) {
    return <AppRoutesForOrganisation />;
  }

  if (!loading && currentPrayerWalkEvent !== defaultValue) {
    return <>{children}</>;
  } else {
    if (loading) {
      return <LoadingSpinner />;
    } else {
      return <ComponentIfNoEventFound />;
    }
  }
};

export default PrayerWalkEventDetector;
