import React from 'react';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@chakra-ui/react';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo-jhipster.png" alt="Logo" />
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon />
    <span className="brand-title">Prayerwalk</span>
    <span className="navbar-version">{VERSION}</span>
  </NavbarBrand>
);

export const Home = () => (
  <NavItem>
    <NavLink id="home" tag={Link} to="/" activeClassName="active" exact replace className="d-flex align-items-center flex-column">
      <FontAwesomeIcon icon={faHome} size="2x" />
      <span>Home</span>
    </NavLink>
  </NavItem>
);

export const BackButton = props => {
  const navigate = useNavigate();
  let go: () => void;
  if (props.navigateTo) {
    go = () => navigate(props.navigateTo);
  } else {
    go = () => navigate(-1);
  }
  return (
    <NavItem className={props.className}>
      <NavLink id="back-button" onClick={go}>
        <FontAwesomeIcon icon={faChevronLeft} size="2x" />
      </NavLink>
    </NavItem>
  );
};

export const BackButtonV2 = props => {
  const navigate = useNavigate();
  let go: () => void;
  if (props.navigateTo) {
    go = () => navigate(props.navigateTo);
  } else {
    go = () => navigate(-1);
  }
  return (
    <IconButton
      variant="ghost"
      colorScheme="transparent"
      aria-label="Go back"
      icon={<FontAwesomeIcon icon={faChevronLeft} size="2x" />}
      onClick={go}
    />
  );
};
