export interface IUserAuthorityResource {
  id?: number | null;
  resourceId?: number | null;
  resourceType?: string | null;
  userEmail?: string | null;
  userId?: number | null;
  authorityName?: string | null;
}

export const defaultValue: Readonly<IUserAuthorityResource> = {};
