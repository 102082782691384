import React from 'react';
import { Box, SimpleGrid, Stack, Button, Heading, Flex, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RegistrationSuccessPage = (props: IRegistrationSuccessProps) => (
  <Stack spacing="6">
    <Heading fontWeight="semibold" fontSize="3xl" textAlign="center">
      You&apos;ve got mail!
    </Heading>

    {props.emailUsed && (
      <Box id="email-used" fontWeight="semibold" fontSize="2xl" textAlign="center" color="gray.600">
        {props.emailUsed}
      </Box>
    )}

    <Flex bg="gray.100" borderRadius="md" p={4} alignItems="center">
      <InfoOutlineIcon fontSize="xl" mr={3} />
      We need to verify your email before you can start using the app. Check your junk if you can&apos;t see anything
    </Flex>
    <SimpleGrid mt="6" columns={2} spacing="3">
      <Button as="a" href="https://outlook.com" target="_blank" color="currentColor" variant="outline" size="lg">
        <Box mr={2}>
          <FontAwesomeIcon icon="envelope-open-text" />
        </Box>{' '}
        Outlook
      </Button>
      <Button as="a" href="https://mail.google.com" target="_blank" color="currentColor" variant="outline" size="lg">
        <Box mr={2}>
          <FontAwesomeIcon icon={['fab', 'google']} />
        </Box>
        Mail
      </Button>
    </SimpleGrid>
  </Stack>
);

interface IRegistrationSuccessProps {
  emailUsed?: string;
}
