import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { loadMoreDataWhenScrolled, parseHeaderForLinks } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUserAuthorityResource, defaultValue } from 'app/shared/model/user-authority-resource.model';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';

const initialState: EntityState<IUserAuthorityResource> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/user-authority-resources';

export interface IUserAuthorityResourceQueryParams {
  organisationId?: number;
}

// Actions
export const getEntities = createAsyncThunk(
  'userAuthorityResource/fetch_entity_list',
  async ({ page, size, sort, organisationId }: IQueryParams & IUserAuthorityResourceQueryParams) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}${
      organisationId ? `&organisationId=${organisationId}` : ''
    }`;
    return axios.get<IUserAuthorityResource[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'userAuthorityResource/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUserAuthorityResource>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'userAuthorityResource/create_entity',
  async (entity: IUserAuthorityResource, thunkAPI) => {
    return axios.post<IUserAuthorityResource>(apiUrl, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'userAuthorityResource/update_entity',
  async (entity: IUserAuthorityResource, thunkAPI) => {
    return axios.put<IUserAuthorityResource>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'userAuthorityResource/partial_update_entity',
  async (entity: IUserAuthorityResource, thunkAPI) => {
    return axios.patch<IUserAuthorityResource>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'userAuthorityResource/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<IUserAuthorityResource>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const UserAuthorityResourceSlice = createEntitySlice({
  name: 'userAuthorityResource',
  initialState,
  skipRejectionHandling: true,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const links = parseHeaderForLinks(action.payload.headers.link);

        return {
          ...state,
          loading: false,
          links,
          entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(createEntity, updateEntity, partialUpdateEntity, deleteEntity), (state, action) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.errorMessage = action.error.response.data.title;
        state.updateSuccess = false;
        state.updating = false;
      })
      .addMatcher(isRejected(getEntity, getEntities), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset } = UserAuthorityResourceSlice.actions;

// Reducer
export default UserAuthorityResourceSlice.reducer;
