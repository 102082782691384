import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState } from 'react-jhipster';
import { useOrganisationHasCancelledSubscription, useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  VStack,
  Text,
  chakra,
  Link as ChakraLink,
  Badge,
  HStack,
  LinkBox,
  IconButton,
  LinkOverlay,
} from '@chakra-ui/react';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { ChevronRightIcon, EditIcon } from '@chakra-ui/icons';
import { ButtonLink } from 'app/shared/components/button-link';
import { Pagination } from 'app/shared/components/pagination';
import { isEventManagerInUsersOrganisation } from 'app/modules/organisation/auth/permissions';

const NoPrayerWalkEvents = () => (
  <Flex alignItems="center" justifyContent="center" width="full" grow={1} fontSize="lg">
    No prayer walk events
  </Flex>
);

export const OrganisationPrayerWalkEvents = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const organisation = useUsersOrganisation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, 10, 'id'), location.search)
  );

  const prayerWalkEvents = useAppSelector(state => state.prayerWalkEvent.entities);
  const isLoading = useAppSelector(state => state.prayerWalkEvent.loading);
  const totalItems = useAppSelector(state => state.prayerWalkEvent.totalItems);
  const isOrgEventManager = useAppSelector(state => isEventManagerInUsersOrganisation(state.authentication, state.usersOrganisation));
  const inactiveSubscription = useOrganisationHasCancelledSubscription();

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        organisationId: organisation.id,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const hasPrayerWalkEvents = !isLoading && prayerWalkEvents.length > 0;
  const hasNoPrayerWalkEvents = !isLoading && prayerWalkEvents.length === 0;

  return (
    <VStack spacing={4} w="full" h="full" flex={1}>
      <Flex w="full">
        <Heading>Your prayer walk events</Heading>
        <Spacer />
        {isOrgEventManager && (
          <ButtonLink isDisabled={inactiveSubscription} colorScheme="primary" to="new" id="newPrayerWalkEvent">
            Create new
          </ButtonLink>
        )}
      </Flex>
      <Flex grow={1} bg="white" w="full" boxShadow="md">
        {hasPrayerWalkEvents && <PrayerWalkEventList prayerWalkEvents={prayerWalkEvents} isOrgEventManager={isOrgEventManager} />}
        {hasNoPrayerWalkEvents && <NoPrayerWalkEvents />}
        {isLoading && <LoadingSpinner />}
      </Flex>
      <Pagination
        activePage={paginationState.activePage}
        onSelect={handlePagination}
        maxButtons={5}
        itemsPerPage={paginationState.itemsPerPage}
        totalItems={totalItems}
      />
    </VStack>
  );
};

interface IPrayerWalkEventListProps {
  prayerWalkEvents: readonly IPrayerWalkEvent[];
  isOrgEventManager: boolean;
}

const PrayerWalkEventList = ({ prayerWalkEvents, isOrgEventManager }: IPrayerWalkEventListProps) => {
  return (
    <VStack w="full">
      {isOrgEventManager
        ? prayerWalkEvents.map(prayerWalkEvent => {
            return (
              <LinkBox as={Flex} key={prayerWalkEvent.id} id={prayerWalkEvent.subdomain} p={4} w="full" alignItems="center">
                <PrayerWalkEventHeadline prayerWalkEvent={prayerWalkEvent} />
                <LinkOverlay as={Link} to={`${prayerWalkEvent.id}`}>
                  <IconButton variant="ghost" aria-label="Go to overview" icon={<ChevronRightIcon />} />
                </LinkOverlay>
              </LinkBox>
            );
          })
        : prayerWalkEvents.map(prayerWalkEvent => {
            return (
              <Flex key={prayerWalkEvent.id} id={prayerWalkEvent.subdomain} p={4} w="full" alignItems="center">
                <PrayerWalkEventHeadline prayerWalkEvent={prayerWalkEvent} />
              </Flex>
            );
          })}
    </VStack>
  );
};

export const PrayerWalkEventHeadline = ({ prayerWalkEvent }: { prayerWalkEvent: IPrayerWalkEvent }) => {
  const baseHost = window.location.host.replace('management', '');

  return (
    <VStack flexGrow={1} w="full" alignItems="flex-start">
      <HStack>
        <Heading>{prayerWalkEvent.name}</Heading>
        <Badge colorScheme={prayerWalkEvent.enabled ? 'green' : 'gray'}>{prayerWalkEvent.enabled ? 'active' : 'inactive'}</Badge>
      </HStack>
      <ChakraLink href={`${window.location.protocol}//${prayerWalkEvent.subdomain}${baseHost}`} id={prayerWalkEvent.subdomain + '-link'}>
        {`${window.location.protocol}//${prayerWalkEvent.subdomain}${baseHost}`}
      </ChakraLink>
    </VStack>
  );
};
