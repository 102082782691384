import {useAppDispatch, useAppSelector} from 'app/config/store';
import React, {useEffect} from 'react';
import {Stack, StackDivider, VStack, Avatar, Wrap, SimpleGrid, Box, HStack} from '@chakra-ui/react';
import {Stat} from 'app/modules/participants/stats/stat-bar';
import {getStats} from 'app/modules/participants/stats/stats.reducer';
import {getUserStats} from 'app/modules/participants/stats/user-stats.reducer';

export const UserStatBar = () => {
  const dispatch = useAppDispatch();
  const {loading, entity: stats} = useAppSelector(state => state.userStats);
  const {account} = useAppSelector(state => state.authentication);
  const prayerWalkEventId = useAppSelector(state => state.currentPrayerWalkEvent.currentPrayerWalkEvent.id);
  useEffect(() => {
    dispatch(getUserStats(prayerWalkEventId));
  }, [dispatch]);

  return (
    <VStack w="full">
      <SimpleGrid m={2} columns={{ base: 4, sm: 4, md: 4, lg: 6 }} spacing={2} alignItems="flex-end">
        <VStack w="full" spacing={1} mb={5}>
          <Avatar name={account.firstName + ' ' + account.lastName} src={account.imageUrl} size="lg"/>
          <Box flex={1} fontSize="sm">{account.firstName + ' ' + account.lastName}</Box>
        </VStack>
        <Stat label="Prayer walks" isLoading={loading} value={stats && stats.numberOfPrayerWalks} size="md"/>
        <Stat label="Prayer needs" isLoading={loading} value={stats && stats.numberOfPrayerNeeds} size="md"/>
        <Stat label="Prayer needs prayed" isLoading={loading} value={stats && stats.numberOfPrayerNeedsPrayedFor} size="md"/>
      </SimpleGrid>
    </VStack>
  );
};
