import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSession } from 'app/shared/reducers/authentication';
import { AppThunk } from 'app/config/store';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  updateSuccess: false,
  updateFailure: false,
};

export type ProfilePictureState = Readonly<typeof initialState>;

// Actions
const apiUrl = 'api/account/profile-picture';

export const saveProfilePicture: (profilePicture: FormData) => AppThunk = profilePicture => async dispatch => {
  await dispatch(updateProfilePicture(profilePicture));

  dispatch(getSession());
};

export const updateProfilePicture = createAsyncThunk('settings/update_profile_picture', async (profilePicture: FormData) => axios.post<any>(apiUrl, profilePicture, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}), {
  serializeError: serializeAxiosError,
});

export const ProfilePictureSlice = createSlice({
  name: 'profilePicture',
  initialState: initialState as ProfilePictureState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateProfilePicture.pending, state => {
        state.loading = true;
        state.errorMessage = null;
        state.updateSuccess = false;
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.updateSuccess = false;
        state.updateFailure = true;
      })
      .addCase(updateProfilePicture.fulfilled, state => {
        state.loading = false;
        state.updateSuccess = true;
        state.updateFailure = false;
        state.successMessage = 'Profile picture saved!';
      });
  },
});

export const { reset } = ProfilePictureSlice.actions;

// Reducer
export default ProfilePictureSlice.reducer;
