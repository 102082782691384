import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  navIsVisible: true,
};

export type AppLayoutState = Readonly<typeof initialState>;

export const AppLayoutSlice = createSlice({
  name: 'counter',
  initialState: initialState as AppLayoutState,
  reducers: {
    hideNav(state) {
      state.navIsVisible = false;
    },
    showNav(state) {
      state.navIsVisible = true;
    },
  },
});

export const { hideNav, showNav } = AppLayoutSlice.actions;

export default AppLayoutSlice.reducer;
