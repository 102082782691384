import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Checkbox,
  InputGroup,
  InputRightElement,
  Button,
  Textarea,
  useDisclosure,
  useMergeRefs,
  IconButton,
  chakra,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export const FormField = React.forwardRef(({ helperText, leftAddon, rightAddon, rightElement, ...props }: any, ref) => {
  return (
    <FormControl isInvalid={props.errors[props.name]} isRequired={props.isRequired}>
      <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      <InputGroup size="lg">
        {leftAddon}
        <Input name={props.name} id={props.name} {...props} ref={ref} />
        {rightElement}
        {rightAddon}
      </InputGroup>
      <FormErrorMessage>{props.errors[props.name] && props.errors[props.name].message}</FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
export const FormFileUpload = React.forwardRef(({ helperText, isUploading, width, children, ...props }: any, ref) => {
  return (
    <FormControl isInvalid={props.errors[props.name]} width="max-content">
      <chakra.input type="file" style={{ display: 'none' }} id={props.name} {...props} />
      <chakra.label htmlFor={props.name} w="full">
        {children}
      </chakra.label>
      <FormErrorMessage>{props.errors[props.name] && props.errors[props.name].message}</FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});
export const FormTextArea = React.forwardRef(({ helperText, ...props }: any, ref) => {
  return (
    <FormControl isInvalid={props.errors[props.name]} isRequired={props.isRequired}>
      {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
      <Textarea size="lg" name={props.name} id={props.name} {...props} ref={ref} />
      <FormErrorMessage>{props.errors[props.name] && props.errors[props.name].message}</FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

export const FormCheckbox = React.forwardRef(({ helperText, ...props }: any, ref) => {
  return (
    <FormControl isInvalid={props.errors[props.name]} isRequired={props.isRequired || false}>
      <Checkbox size="lg" name={props.name} id={props.name} {...props} ref={ref}>
        {props.children}
      </Checkbox>
      <FormErrorMessage>{props.errors[props.name] && props.errors[props.name].message}</FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

export const FormPassword = React.forwardRef((props: any, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const mergeRef = useMergeRefs(inputRef, ref);

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <FormControl isInvalid={props.errors[props.name]} isRequired={props.isRequired}>
      <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      <InputGroup>
        <Input
          size="lg"
          ref={mergeRef}
          name={props.name}
          id={props.name}
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          placeholder={props.placeholder || 'Enter password'}
          required
          {...props}
        />
        <InputRightElement>
          <IconButton
            mt={2}
            mr={2}
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon="eye" />}
            onClick={onClickReveal}
          />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{props.errors[props.name] && props.errors[props.name].message}</FormErrorMessage>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
});
