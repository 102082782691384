interface IPercentageFormat {
  percentage?: number;
  locale?: string;
}

export const percentageFormat = ({ percentage = 0, locale }: IPercentageFormat): string => {
  if (!locale) {
    if (navigator.language) {
      locale = navigator.language;
    } else {
      locale = 'en-GB';
    }
  }

  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'percent',
    unitDisplay: 'narrow',
    maximumFractionDigits: 3,
  }).format(percentage);
};
