import React from 'react';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { Box, Flex, Image } from '@chakra-ui/react';

export interface IEventAwareLogoProps {
  prayerWalkEvent: IPrayerWalkEvent;
  transparentBackground: boolean;
}

export const EventAwareLogo = (props: IEventAwareLogoProps) => {
  return <Logo logoUrl={props.prayerWalkEvent.logoUrl} prayerWalkEventName={props.prayerWalkEvent.name} />;
};

export interface ILogoProps {
  logoUrl: string;
  prayerWalkEventName: string;
}

export const Logo = (props: ILogoProps) => {
  return (
    <Flex alignItems="center" justifyContent="center" w="full">
      <Image id="logo" maxH={72} src={props.logoUrl} alt={`${props.prayerWalkEventName} logo`} />
    </Flex>
  );
};
