import { IPrayerRouteSelectedInfo } from 'app/entities/map/layers/prayer-route-cards';
import { useLocation, useParams, useResolvedPath } from 'react-router-dom';

export const useSelectedPrayerRouteId = (): string | undefined => {
  const location = useLocation();
  const path = useResolvedPath('prayer-route-selected');
  const selected = location.pathname.startsWith(path.pathname);
  const { prayerRouteId } = useParams();
  return selected && prayerRouteId;
};
