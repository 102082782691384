import { Feature } from 'geojson';
import { MultipleFieldEqualityTogglingSet, ITogglingSet } from './multiple-field-comparing-toggling-set';

export default class IdThenGeometryComparingSet implements ITogglingSet<Feature> {
  set: MultipleFieldEqualityTogglingSet<Feature>;

  constructor(list: Feature[]) {
    this.set = new MultipleFieldEqualityTogglingSet(
      list,
      feature => ({
        id: feature.id,
        geometry: feature.geometry
      }),
      feature => ({
        id: feature.id
      })
    );
  }

  addOrRemove(feature: Feature): Feature[] {
    return this.set.addOrRemove(feature);
  }
}
