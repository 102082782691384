import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getPortalSession, reset } from 'app/modules/organisation/reducers/portal-session.reducer';
import LoadingSpinner from 'app/shared/components/loading-spinner';

export const BillingRedirect = () => {
  const dispatch = useAppDispatch();
  const billingPortalUrl = useAppSelector(state => state.portalSession.portalSession.url);
  const billingPortalUrlLoading = useAppSelector(state => state.portalSession.loading);

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  }, [billingPortalUrl]);

  useEffect(() => {
    dispatch(getPortalSession(`${window.location.origin}/organisation`));
  }, []);

  return billingPortalUrlLoading && <LoadingSpinner />;
};
