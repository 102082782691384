import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import { FormField } from 'app/shared/components/form-field';
import { PasswordField } from 'app/shared/layout/password/password-field';
import { DividerWithText } from 'app/shared/layout/DividerWithText';
import {
  Button,
  chakra,
  Stack,
  SimpleGrid,
  VisuallyHidden,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  Alert,
  Divider,
  Heading,
  VStack,
  useBreakpointValue,
  StackDivider,
  Flex,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export const OrganisationLoginForm = () => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(state => state.authentication.loginError);
  const handleLogin = (username, password, rememberMe = false) => dispatch(login(username.trim(), password, rememberMe));

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data, e) => {
    e.preventDefault();
    handleLogin(data.username, data.password, true);
  });

  return (
    <chakra.form onSubmit={onSubmit} noValidate flex={1} w="full">
      <Stack spacing="8">
        {loginError && (
          <Alert status="error" variant="subtle">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Login failed!</AlertTitle>
              <AlertDescription>
                Please check your credentials and try again.
                <br />
                Please make sure you have activated your account by clicking on the link in the email we sent you.
              </AlertDescription>
            </Box>
          </Alert>
        )}
        <FormField
          label="Your work email"
          errors={errors}
          {...register('username', {
            required: 'Email cannot be empty',
          })}
        />
        <PasswordField
          {...register('password', {
            required: 'Password cannot be empty',
          })}
        />
        <Button type="submit" colorScheme="primary" size="lg" isLoading={isSubmitting}>
          Sign in
        </Button>
      </Stack>
    </chakra.form>
  );
};
export const OrganisationLoginOrCreateAccount = () => {
  return (
    <Stack
      h="full"
      direction={{ base: 'column', md: 'row' }}
      divider={<StackDivider borderColor="gray.200" />}
      spacing={{ base: 4, md: 16 }}
      alignItems="center"
    >
      <OrganisationLoginForm />
      <VStack flex={1} h="full">
        <Flex flex={1} alignItems="center" justifyContent="center" direction="column" rowGap={2}>
          <Heading textAlign="center" size={{ base: 'lg', md: 'xl' }} lineHeight="3">
            Start using The Prayer Walk App in your organisation
          </Heading>
          <Box fontSize={{ base: 'md', md: 'lg' }} textAlign="center" color="gray.500">
            7 days to try it out, free!
          </Box>
        </Flex>
        <Button
          w="full"
          id="register"
          as={Link}
          to="/organisation-onboard/create-account"
          size="lg"
          variant="outline"
          colorScheme="primary"
        >
          Create Account
        </Button>
      </VStack>
    </Stack>
  );
};
