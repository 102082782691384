import { IPaymentSubscription } from 'app/shared/model/payment-subscription.model';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';

export interface IOrganisation {
  id?: number;
  name?: string | null;
  paymentSubscription?: IPaymentSubscription | null;
  prayerWalkEvents?: IPrayerWalkEvent[] | null;
}

export const defaultValue: Readonly<IOrganisation> = {};
