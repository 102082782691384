import React, { PropsWithChildren } from 'react';
import { Box, Card, CloseButton, Flex, Heading, CardBody, Spacer, VStack } from '@chakra-ui/react';

export interface IActionCardProps {
  title: string;
  handleClose: () => void;
}

export const ActionCard = (props: PropsWithChildren<IActionCardProps>) => {
  return (
    <Box pos="absolute" bottom="0" w="full" p={2}>
      <Card bg="white" w="full">
        <CardBody w="full">
          <VStack w="full">
            <Flex w="full" alignItems="center" gap="2">
              <Heading flexGrow={1} size="md">
                {props.title}
              </Heading>
              <Spacer />
              <CloseButton onClick={props.handleClose} />
            </Flex>
            {props.children}
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
};
