import React, { useCallback } from 'react';
import { Layer, Source, LayerProps } from 'react-map-gl';

export interface IAllPrayerRoutesVectorLayerProps {
  id: string;
  sourceLayer: string;
  tileUrl: string;
  completedRouteColour: string;
  earmarkedRouteColour: string;
  selectedPrayerRouteId?: string;
  excludePrayerRouteId?: number;
  beforeLayerId?: string;
}

const AllPrayerRoutesVectorLayer = (props: IAllPrayerRoutesVectorLayerProps) => {
  const getLayerProps = useCallback(
    (showCompletedRoutes: boolean): LayerProps => {
      const layer: LayerProps = {
        id: `highlighted-roads-${showCompletedRoutes ? 'completed' : 'earmarked'}-routes-${props.id}`,
        type: 'line',
        source: 'highlighted-roads',
        'source-layer': props.sourceLayer,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': [
            'case',
            ['boolean', ['get', 'completed'], false],
            props.completedRouteColour, // case 1
            props.earmarkedRouteColour, // default
          ],
          'line-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 14, 3, 18, 6],
          'line-opacity': props.selectedPrayerRouteId
            ? ['case', ['==', parseInt(props.selectedPrayerRouteId, 10), ['get', 'prayer_route_id']], 1, 0.2]
            : 1,
        },
        filter: ['==', showCompletedRoutes, ['get', 'completed']],
        beforeId: showCompletedRoutes ? props.beforeLayerId : `highlighted-roads-completed-routes-${props.id}`,
      };
      if (props.excludePrayerRouteId) {
        layer.filter = ['all', layer.filter, ['!=', props.excludePrayerRouteId, ['get', 'prayer_route_id']]];
      }
      return layer;
    },
    [props.completedRouteColour, props.earmarkedRouteColour, props.selectedPrayerRouteId, props.excludePrayerRouteId, props.beforeLayerId]
  );

  return (
    <Source id={`highlighted-roads-${props.id}`} type="vector" tiles={[props.tileUrl]} minzoom={0} maxzoom={22}>
      <Layer {...getLayerProps(true)} />
      <Layer {...getLayerProps(false)} />
    </Source>
  );
};

export default AllPrayerRoutesVectorLayer;
