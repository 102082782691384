import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  VStack,
  Text,
  HStack,
  Avatar,
  Button,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { EditIcon, SearchIcon, TimeIcon } from '@chakra-ui/icons';
import { Index } from 'flexsearch';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, updateEntity } from 'app/entities/prayer-need/prayer-need.reducer';
import { IPrayerNeed } from 'app/shared/model/prayer-need.model';
import dayjs from 'dayjs';
import { hasAnyAuthority } from 'app/shared/auth/private-route-2';
import { AUTHORITIES } from 'app/config/constants';
import { isEventManagerForCurrentEvent } from 'app/shared/auth/event-manager';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons/faMapLocationDot';
import { PrayedForButton, PrayedForCount } from 'app/shared/components/prayed-for-button';
import { PrayerHandsIcon } from 'app/shared/components/icons';
import { isModeratorForOrganisation } from 'app/modules/organisation/auth/permissions';
import { usePrayerNeedsPageTour } from 'app/shared/components/feature-tour';

type SortDirections = 'MOST_RECENT' | 'PLACE';

interface SearchBarProps extends InputProps {
  onToggleSort: () => void;
  sortBy: SortDirections;
}

const sorts = {
  PLACE: {
    text: 'Sort by place name',
    icon: <FontAwesomeIcon icon={faMapLocationDot} />,
    process: (a, b) =>
      a.prayerFeature && a.prayerFeature.name && b.prayerFeature ? a.prayerFeature.name.localeCompare(b.prayerFeature.name) : 1,
  },
  MOST_RECENT: {
    text: 'Sort by most recent',
    icon: <TimeIcon />,
    process: (a, b) => (dayjs(a.createdDate).isAfter(dayjs(b.createdDate)) ? -1 : 1),
  },
};

const SearchBar = ({ sortBy, onToggleSort, ...other }: SearchBarProps) => (
  <HStack px={6}>
    <IconButton aria-label={sorts[sortBy].text} title={sorts[sortBy].text} size="lg" icon={sorts[sortBy].icon} onClick={onToggleSort} />
    <InputGroup size="lg">
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input type="search" placeholder="Search by place" {...other} />
    </InputGroup>
  </HStack>
);

const PrayerNeeds = ({ prayerNeeds }: { prayerNeeds: readonly IPrayerNeed[] }) => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isEventManager = useAppSelector(state => isEventManagerForCurrentEvent(state.authentication, state.currentPrayerWalkEvent));
  const isModeratorForOrg = useAppSelector(
    state =>
      state.currentPrayerWalkEvent.currentPrayerWalkEvent.organisation &&
      state.currentPrayerWalkEvent.currentPrayerWalkEvent.organisation.id &&
      isModeratorForOrganisation(state.authentication, state.currentPrayerWalkEvent.currentPrayerWalkEvent.organisation)
  );
  const currentUserLogin = useAppSelector(state => state.authentication.account.login);

  return (
    <Box w="full" h="fit-content">
      {prayerNeeds.map((prayerNeed, index) => {
        return (
          <Box key={prayerNeed.id} bgColor={index % 2 ? 'gray.50' : 'white'} h="full">
            <PrayerNeedDetails
              prayerNeed={prayerNeed}
              isModerator={isEventManager || isModeratorForOrg || isAdmin}
              currentUserLogin={currentUserLogin}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const NoPrayerNeeds = () => (
  <Flex alignItems="center" justifyContent="center" width="full" grow={1} fontSize="lg">
    No prayer needs found
  </Flex>
);

interface IPrayerNeedDetailsProps {
  prayerNeed?: IPrayerNeed;
  isModerator: boolean;
  currentUserLogin: string;
}

const PrayerNeedDetails = ({ prayerNeed, isModerator, currentUserLogin }: IPrayerNeedDetailsProps) => {
  const dispatch = useAppDispatch();
  const currentPrayerWalkEvent = useCurrentEvent();
  const navigate = useNavigate();
  const updating = useAppSelector(state => state.prayerNeed.updating);

  const handleChangeModerationStatus = useCallback(
    intendedModerationStatus => () => {
      dispatch(updateEntity({ prayerWalkEventId: currentPrayerWalkEvent.id, entity: { id, moderationStatus: intendedModerationStatus } }));
    },
    []
  );
  const { id, prayerNeedText, category, moderationStatus, prayerFeature, user, createdDate } = prayerNeed;

  return (
    <Flex px={4} py={4} justifyContent="space-between" w="full" alignItems="center" h="full" data-testid="prayerNeedListItem">
      <Flex direction="row" alignItems="center" w="full" h="full">
        <HStack h="full" w="full">
          <VStack alignItems="center" h="full" justifyContent="space-between">
            <Avatar name={user.firstName} src={user.imageUrl} />
          </VStack>
          <VStack direction="column" alignItems="flex-start" spacing={1} flexGrow={1}>
            <HStack spacing={2}>
              <Box
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
                data-testid="prayerNeedPlace"
              >
                {prayerFeature.name || ''}
              </Box>
              <PrayedForCount prayerNeed={prayerNeed} />
            </HStack>
            <Box color="gray.500" fontSize="xs" data-testid="category">
              {category || 'General'} &bull; {dayjs(createdDate).fromNow()}
            </Box>
            <Box fontWeight="medium" data-testid="prayerNeedText">
              {prayerNeedText || ''}
            </Box>
            <HStack pt={2}>
              <PrayedForButton prayerNeed={prayerNeed} />
              {isModerator &&
                currentPrayerWalkEvent.enabled &&
                (moderationStatus === 'APPROVED' ? (
                  <Button colorScheme="green" size="sm" variant="ghost">
                    Approved
                  </Button>
                ) : (
                  <Button colorScheme="green" size="sm" isLoading={updating} onClick={handleChangeModerationStatus('APPROVED')}>
                    Approve
                  </Button>
                ))}
              {isModerator &&
                currentPrayerWalkEvent.enabled &&
                (moderationStatus === 'REJECTED' ? (
                  <Button colorScheme="red" size="sm" variant="ghost">
                    Rejected
                  </Button>
                ) : (
                  <Button colorScheme="red" size="sm" isLoading={updating} onClick={handleChangeModerationStatus('REJECTED')}>
                    Reject
                  </Button>
                ))}
              {user.login === currentUserLogin && currentPrayerWalkEvent.enabled && (
                <Button leftIcon={<EditIcon />} bgColor="gray.100" size="sm" onClick={() => navigate(`/map/prayer-need/${id}/edit`)}>
                  Edit
                </Button>
              )}
            </HStack>
          </VStack>
        </HStack>
      </Flex>
    </Flex>
  );
};

export const PrayerNeedsPage = () => {
  const dispatch = useAppDispatch();
  const { loading: isLoading, errorMessage, entities: rawPrayerNeeds } = useAppSelector(state => state.prayerNeed);
  const prayerWalkEvent = useCurrentEvent();
  usePrayerNeedsPageTour();
  const prayerWalkEventId = prayerWalkEvent.id;
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<SortDirections>('MOST_RECENT');

  useEffect(() => {
    if (prayerWalkEventId) {
      dispatch(getEntities({ prayerWalkEventId }));
    }
  }, [prayerWalkEventId]);

  let prayerNeeds: IPrayerNeed[] = [...rawPrayerNeeds];

  const searchIndex = useMemo(() => {
    const ind = new Index({ tokenize: 'full' });
    prayerNeeds.forEach((prayerNeed, index) => {
      ind.add(index, prayerNeed.prayerFeature.name);
    });
    return ind;
    //  There is an assumption here that the list will not change without the length of it changing
  }, [prayerNeeds.length]);

  if (searchQuery) {
    const results = searchIndex.search(searchQuery);
    prayerNeeds = results.map(resultId => {
      return rawPrayerNeeds[resultId];
    });
  }

  prayerNeeds.sort(sorts[sortBy].process);

  const hasPrayerNeeds = prayerNeeds.length > 0;
  const hasNoPrayerNeeds = prayerNeeds.length === 0;

  return (
    <>
      <Flex direction="column" width="full" h="full">
        <Flex direction="column" width="full" py={4} bg="gray.50" shadow="md" zIndex="1">
          <SearchBar
            sortBy={sortBy}
            onToggleSort={() => {
              setSortBy(sortBy === 'MOST_RECENT' ? 'PLACE' : 'MOST_RECENT');
            }}
            onChange={event => setSearchQuery(event.target.value)}
          />
        </Flex>
        <Flex grow={1} overflowY="scroll" h="full" id="prayer-need-list">
          {hasPrayerNeeds && <PrayerNeeds prayerNeeds={prayerNeeds} />}
          {hasNoPrayerNeeds && <NoPrayerNeeds />}
        </Flex>
        {prayerWalkEvent.enabled && (
          <Box width="full" p={2} bg="gray.50" zIndex="1" shadow="md">
            <Button w="full" colorScheme="primary" leftIcon={<PrayerHandsIcon />} as={Link} to="/map/prayer-need/new">
              Add a prayer need
            </Button>
          </Box>
        )}
      </Flex>
    </>
  );
};
