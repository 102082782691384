import axios from 'axios';
import { createAsyncThunk, createSlice, isFulfilled } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

import { defaultValue, IOrganisation } from 'app/shared/model/organisation.model';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { getEntities } from 'app/entities/organisation/organisation.reducer';
import { getSession } from 'app/shared/reducers/authentication';

const initialState = {
  loading: false,
  updating: false,
  updateSuccess: false,
  errorMessage: null,
  usersOrganisation: defaultValue,
};

export type UsersOrganisationState = Readonly<typeof initialState>;

export const getUsersOrganisation = createAsyncThunk(
  'usersOrganisation/get',
  async () => axios.get<IOrganisation>(`/api/user/organisation`),
  {
    serializeError: serializeAxiosError,
  }
);

export const saveUsersOrganisation = createAsyncThunk(
  'usersOrganisation/upsert_entity',
  async (entity: IOrganisation, thunkAPI) => {
    const result = await axios.put<IOrganisation>(`/api/user/organisation`, cleanEntity(entity));
    thunkAPI.dispatch(getUsersOrganisation());
    thunkAPI.dispatch(getSession());
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const UsersOrganisationSlice = createSlice({
  name: 'usersOrganisation',
  initialState: initialState as UsersOrganisationState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsersOrganisation.pending, state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addCase(getUsersOrganisation.rejected, (state, payload) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(getUsersOrganisation.fulfilled, (state, payload) => {
        state.loading = false;
        state.usersOrganisation = payload.payload.data;
      })
      .addCase(saveUsersOrganisation.pending, state => {
        state.errorMessage = null;
        state.updating = true;
      })
      .addCase(saveUsersOrganisation.rejected, (state, payload) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = payload.error;
      })
      .addCase(saveUsersOrganisation.fulfilled, (state, payload) => {
        state.updating = false;
        state.updateSuccess = true;
        state.usersOrganisation = payload.payload.data;
      });
  },
});

export const { reset } = UsersOrganisationSlice.actions;

// Reducer
export default UsersOrganisationSlice.reducer;
