import React, { useCallback, useEffect, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import MyCompletedPrayerRoutes from 'app/modules/map/layers/my-routes-geo-json-layer';
import AllPrayerRoutesVectorLayer from 'app/modules/map/layers/all-prayer-routes-vector-layer';
import { newFeatureCollection } from 'app/modules/map/utils';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import PrayerRoutesGeoJsonLayer from 'app/modules/map/layers/prayer-routes-geo-json-layer';
import IdThenGeometryComparingSet from 'app/modules/map/data-structures/id-then-geometry-comparing-set';
import { createEntity, getEntitiesForUser, getEntity, reset, updateEntity } from 'app/entities/prayer-route/prayer-route.reducer';
import length from '@turf/length';
import _ from 'lodash';
import { IMapSettersProps } from '../map-home';
import { Button, CloseButton, Flex, Heading, HStack, Spacer, Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ActionCard } from 'app/shared/components/action-card';
import AllPrayerRoutesHeatmapVectorLayer from 'app/modules/map/layers/all-prayer-routes-heatmap-vector-layer';
import { MapLegend } from 'app/shared/components/map-legend';

const maxDistance = 20; // duplicated

export const PrayerRouteUpdateMapLayers = (props: IMapSettersProps) => {
  const dispatch = useAppDispatch();
  const prayerWalkEvent = useCurrentEvent();
  const params = useParams();
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState(newFeatureCollection());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alreadyPrayedFor, setAlreadyPrayedFor] = useState<boolean | undefined>(undefined);
  const [isNew] = useState(!params.id);
  const currentUsersPrayerRoutes = useAppSelector(state => state.prayerRoute.userEntities);
  const prayerRouteEntity: IPrayerRoute = useAppSelector(state => state.prayerRoute.entity);
  const updateSuccess = useAppSelector(state => state.prayerRoute.updateSuccess);
  const isUpdating = useAppSelector(state => state.prayerRoute.updating);

  const handleClose = useCallback(() => {
    navigate('/map');
  }, [navigate]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity({ prayerWalkEventId: prayerWalkEvent.id, id: params.id }));
    }

    dispatch(getEntitiesForUser({ prayerWalkEventId: prayerWalkEvent.id }));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setSelectedRoute(prayerRouteEntity.route);
  }, [prayerRouteEntity]);

  const saveEntity = useCallback(() => {
    const currentDistance = length(selectedRoute || prayerRouteEntity.route);
    // if (currentDistance > maxDistance) {
    //   setIsModalOpen(true);
    //   return;
    // }
    const entity = {
      ...prayerRouteEntity,
      completed: alreadyPrayedFor === undefined ? prayerRouteEntity.completed : alreadyPrayedFor,
      completedAt: alreadyPrayedFor === false ? prayerRouteEntity.completedAt : dayjs().toDate().toISOString(),
      route: selectedRoute || prayerRouteEntity.route,
    };

    if (isNew) {
      dispatch(createEntity({ prayerWalkEventId: prayerWalkEvent.id, entity }));
    } else {
      dispatch(updateEntity({ prayerWalkEventId: prayerWalkEvent.id, entity }));
    }
  }, [prayerRouteEntity, selectedRoute, alreadyPrayedFor]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const currentUsersPrayerRoutesExceptTheOneBeingEdited = isNew
    ? currentUsersPrayerRoutes
    : _.reject(currentUsersPrayerRoutes, { id: prayerRouteEntity.id });
  const currentDistance = length(selectedRoute || prayerRouteEntity.route);

  useEffect(() => {
    props.addHandlerForLayerClick('routing-segments-tappable', (feature: Feature) => {
      const features: Feature[] = new IdThenGeometryComparingSet(selectedRoute.features).addOrRemove(feature);
      const filteredFeatureCollection: FeatureCollection<Geometry> = getFeatureCollectionFromFeatures(features);
      setSelectedRoute(filteredFeatureCollection);
    });
    return () => {
      props.removeHandlerForLayerClick('routing-segments-tappable');
    };
  }, [selectedRoute]);

  useEffect(() => {
    props.setInteractiveLayerIds(['routing-segments-tappable']);
    return () => props.removeInteractiveLayerIds(['routing-segments-tappable']);
  }, []);

  const prayerRoute = {
    ...prayerRouteEntity,
    route: selectedRoute,
  };

  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);
  const mapLegendState = useAppSelector(state => state.mapLegendState);

  const editablePrayerRouteName = `${params.id ? params.id : 'new'}`;

  return (
    <>
      {/* <DistanceMonitor maxDistance={5} currentDistance={currentDistance} /> */}
      {/* <DistanceProblemModal currentDistance={currentDistance} isOpen={isModalOpen} maxDistance={maxDistance} onClose={handleModalClose} /> */}
      <PrayerRoutesGeoJsonLayer
        name={editablePrayerRouteName}
        prayerRoutes={[prayerRoute]}
        completedRouteColour={prayerWalkEvent.settings.colours.completedColour.code}
        earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
        beforeId="z4"
      />
      {prayerWalkEvent.settings.features.heatmapEnabled ? (
        <>
          {mapLegendState.isHeatmapChecked && <AllPrayerRoutesHeatmapVectorLayer beforeLayerId="z2" />}
          {mapLegendState.isMyRoutesChecked && (
            <MyCompletedPrayerRoutes
              prayerRoutes={currentUsersPrayerRoutesExceptTheOneBeingEdited as IPrayerRoute[]}
              routeColour={prayerWalkEvent.settings.colours.completedColour.code}
              beforeLayerId="z3"
            />
          )}
        </>
      ) : (
        <>
          {mapLegendState.isHeatmapChecked && (
            <AllPrayerRoutesVectorLayer
              id="update-page"
              sourceLayer="public.prayer_routes_v5"
              tileUrl={`${tileUrl}/public.prayer_routes_v5/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`}
              completedRouteColour={prayerWalkEvent.settings.colours.completedColour.code}
              earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
              excludePrayerRouteId={prayerRoute.id}
              beforeLayerId="z2"
            />
          )}
          {mapLegendState.isMyRoutesChecked && (
            <MyCompletedPrayerRoutes
              prayerRoutes={currentUsersPrayerRoutesExceptTheOneBeingEdited as IPrayerRoute[]}
              routeColour={prayerWalkEvent.settings.colours.completedColour.code}
              beforeLayerId="z3"
            />
          )}
        </>
      )}
      {/* todo add distance monitor and feedback into the below */}
      {isNew ? (
        <ActionCard title="Adding a prayer route" handleClose={handleClose}>
          {alreadyPrayedFor === undefined ? (
            <>
              <Box fontSize="lg">Have you already prayed this route or are you planning to?</Box>
              <HStack spacing={4} w="full">
                <Button id="planning-to-button" flexGrow={1} size="lg" onClick={() => setAlreadyPrayedFor(false)}>
                  Planning to
                </Button>
                <Button id="already-prayed-button" flexGrow={1} colorScheme="primary" size="lg" onClick={() => setAlreadyPrayedFor(true)}>
                  Already prayed
                </Button>
              </HStack>
            </>
          ) : (
            <>
              {alreadyPrayedFor ? (
                <Box fontSize="lg">Tap on the roads you&apos;ve prayed for, tap again to unselect them.</Box>
              ) : (
                <Box fontSize="lg">Tap on the roads you want to pray for, tap again to unselect them.</Box>
              )}
              <HStack spacing={4} w="full">
                <Button size="lg" onClick={() => setAlreadyPrayedFor(undefined)}>
                  Back
                </Button>
                <Button
                  id="save"
                  flexGrow={1}
                  data-cy="submit"
                  type="submit"
                  colorScheme="primary"
                  size="lg"
                  onClick={saveEntity}
                  isLoading={isUpdating}
                >
                  Save
                </Button>
              </HStack>
            </>
          )}
        </ActionCard>
      ) : (
        <ActionCard title="Edit your prayer route" handleClose={handleClose}>
          <Box fontSize="lg">Tap on the roads you want to pray for, tap again to unselect them.</Box>
          <Button
            id="save"
            w="full"
            data-cy="submit"
            type="submit"
            colorScheme="primary"
            size="lg"
            onClick={saveEntity}
            isLoading={isUpdating}
          >
            Save
          </Button>
        </ActionCard>
      )}
      <MapLegend />
    </>
  );
};

// TODO can use turf for this
function getFeatureCollectionFromFeatures(features: Feature[]): FeatureCollection<Geometry> {
  return {
    type: 'FeatureCollection',
    features: features.map(
      (f): Feature<Geometry> => ({
        type: 'Feature',
        id: f.id,
        geometry: f.geometry,
        properties: f.properties,
      })
    ),
  };
}
