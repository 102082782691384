import React, { useCallback, useMemo } from 'react';
import { Layer, Source, LayerProps } from 'react-map-gl';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import {
  combineFeaturesIntoTwoSetsOfHighlightedRoutes,
  transformToHighlightedRoad,
  combineFeaturesIntoOneFeatureCollection,
} from 'app/modules/map/utils';
import { IHighlightedRoad } from 'app/modules/map/types';

export interface IPrayerRoutesGeoJsonLayerProps {
  name: string;
  prayerRoutes: IPrayerRoute[];
  completedRouteColour: string;
  earmarkedRouteColour: string;
  selectedPrayerRoute?: IPrayerRoute;
  beforeId?: string;
}

const PrayerRoutesGeoJsonLayer = (props: IPrayerRoutesGeoJsonLayerProps) => {
  const featureCollection = combineFeaturesIntoOneFeatureCollection(props.prayerRoutes);
  const getLayerProps = useCallback(
    (
      completedRouteColour: string,
      earmarkedRouteColour: string,
      name: string,
      selectedPrayerRoute?: IPrayerRoute,
      beforeId?: string
    ): LayerProps => {
      return {
        id: `highlighted-roads-${name}`,
        type: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': [
            'case',
            ['boolean', ['get', 'completed'], false],
            completedRouteColour, // case 1
            earmarkedRouteColour, // default
          ],
          'line-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 14, 3, 18, 6],
          'line-opacity': selectedPrayerRoute ? ['case', ['==', selectedPrayerRoute.id, ['get', 'prayer_route_id']], 1, 0.2] : 1,
        },
        beforeId: beforeId || 'z3',
      };
    },
    []
  );

  return (
    <Source id={`highlighted-roads-${props.name}`} type="geojson" data={featureCollection}>
      <Layer
        {...getLayerProps(props.completedRouteColour, props.earmarkedRouteColour, props.name, props.selectedPrayerRoute, props.beforeId)}
      />
    </Source>
  );
};

export default PrayerRoutesGeoJsonLayer;
