import React from 'react';
import { Layer, LineLayer, Source } from 'react-map-gl';
import ROAD_STYLE from './road-style.json';

export interface IRoadsProps {
  tileUrl: string;
}

const Roads = (props: IRoadsProps) => {
  return (
    <Source id="roads" type="vector" tiles={[props.tileUrl]} minzoom={0} maxzoom={22}>
      {ROAD_STYLE.layers.map(layer => {
        let beforeId;
        if (layer.id.startsWith('tunnel')) {
          beforeId = 'ferry';
        }
        if (layer.id.startsWith('road') || layer.id.startsWith('turning')) {
          beforeId = 'aerialway';
        }
        if (layer.id.startsWith('bridge')) {
          beforeId = 'aerialway';
        }
        delete layer.source;
        return (
          <React.Fragment key={layer.id}>
            <Layer beforeId={beforeId} source="roads" {...layer} />
            {/* {layer.type === 'line' && */}
            {/*   <Layer */}
            {/*          beforeId={beforeId} */}
            {/*          {...layer} */}
            {/*          id={layer.id + '-tappable'} */}
            {/*          source="roads" */}
            {/*          paint={{...layer.paint, 'line-width': 12, 'line-opacity': 0.0001}} */}
            {/*   /> */}
            {/* } */}
          </React.Fragment>
        );
      })}
    </Source>
  );
};

export const RoutingSegments = (props: IRoadsProps) => {
  return (
    <Source id="routing-segments" type="vector" tiles={[props.tileUrl]} minzoom={0} maxzoom={22}>
      <Layer
        source="routing-segments"
        source-layer="public.routing_segments_in_prayer_walk_event"
        type={'line'}
        id={'routing-segments-tappable'}
        paint={{ 'line-color': '#fff', 'line-width': 12, 'line-opacity': 0.0001 }}
      />
    </Source>
  );
};

export default Roads;
