import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/prayer-walk-event/prayer-walk-event.reducer';
import { useOrganisationHasCancelledSubscription, useUsersOrganisation } from 'app/shared/hooks/useUsersOrganisation';
import { Flex, Heading, VStack, Box, Alert, AlertIcon, Wrap } from '@chakra-ui/react';
import LoadingSpinner from 'app/shared/components/loading-spinner';
import { ButtonLink } from 'app/shared/components/button-link';
import { Stat } from 'app/modules/participants/stats/stat-bar';
import { isEventManagerInUsersOrganisation } from 'app/modules/organisation/auth/permissions';

export const OrganisationMainIndex = () => {
  const dispatch = useAppDispatch();
  const organisation = useUsersOrganisation();

  const prayerWalkEvents = useAppSelector(state => state.prayerWalkEvent.entities);
  const isLoading = useAppSelector(state => state.prayerWalkEvent.loading);
  const isOrgEventManager = useAppSelector(state => isEventManagerInUsersOrganisation(state.authentication, state.usersOrganisation));

  useEffect(() => {
    if (isOrgEventManager) {
      dispatch(
        getEntities({
          page: 0,
          size: 10,
          sort: `id,desc`,
          organisationId: organisation.id,
        })
      );
    }
  }, []);

  const hasNoPrayerWalkEvents = !isLoading && prayerWalkEvents.length === 0;

  return (
    <VStack spacing={4} w="full" h="full" flex={1}>
      <Flex w="full" rowGap={2} direction="column">
        <Heading id="organisationName">{organisation.name}</Heading>
        <Box>Welcome to your Prayer Walk App management portal</Box>
      </Flex>
      {isOrgEventManager && (
        <Flex bg="white" w="full" boxShadow="md">
          {hasNoPrayerWalkEvents && <PrayerWalkEventCreationPrompt />}
          {isLoading && <LoadingSpinner />}
        </Flex>
      )}
    </VStack>
  );
};

const PrayerWalkEventCreationPrompt = () => {
  const inactiveSubscription = useOrganisationHasCancelledSubscription();
  return (
    <Flex width="full" minH={40} direction="column" p={4} rowGap={4}>
      <Alert status="info">
        <AlertIcon />
        You don&apos;t have any prayer walk events set up.
      </Alert>
      <Box>
        The Prayer Walk App is made up of lots of different prayer walk events set up by churches and organisations, like yours. You give it
        a name, a unique url, upload a logo and configure the map. You can then share the url with your prayer walkers so they can track
        their praying and collaborate.
      </Box>
      <ButtonLink isDisabled={inactiveSubscription} to="prayer-walk-events/new" colorScheme="primary">
        Create your first prayer walk event
      </ButtonLink>
    </Flex>
  );
};
const PrayerWalkEventStats = props => {
  return (
    <Flex width="full" p={4}>
      <Wrap>
        <Stat label="Prayer walk events created" value={props.prayerWalkEventCount} isLoading={false} />
      </Wrap>
    </Flex>
  );
};
