import axios from 'axios';

import { createSlice, isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPrayerWalkEventDashboardStats } from 'app/shared/model/prayer-walk-event-dashboard-stats';
import { IPrayerEventChallenge } from 'app/shared/model/prayer-event-challenge.model';
import { IPrayerEventChallengeStats } from 'app/shared/model/prayer-event-challenge-stats';

const initialState: {
  loading: boolean;
  errorMessage?: string;
  stats?: IPrayerEventChallengeStats[];
} = {
  loading: true,
  errorMessage: null,
  stats: [],
};

export const getPrayerEventChallengeStats = createAsyncThunk(
  'prayerEventChallengeStats/fetch',
  async (id: string | number) => {
    const requestUrl = `/api/prayer-walk-events/${id}/prayer-event-challenge-stats`;
    return axios.get<IPrayerEventChallengeStats[]>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const PrayerEventChallengeStatsSlice = createSlice({
  name: 'prayerEventChallengeStats',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getPrayerEventChallengeStats), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getPrayerEventChallengeStats), (state, action) => {
        return {
          ...state,
          loading: false,
          stats: action.payload.data,
        };
      });
  },
});

// Reducer
export default PrayerEventChallengeStatsSlice.reducer;
