import { Icon } from '@chakra-ui/react';
import React from 'react';

export const PrayerHandsIcon = props => {
  return (
    <Icon viewBox="0 0 1200 1200" {...props}>
      <g fill="currentColor">
        <path d="m600 205.38-23.078 171.92c9.2305 4.6172 17.309 12.691 23.078 20.77 5.7695-9.2305 13.848-16.152 23.078-20.77z" />
        <path d="m887.31 888.46c-33.461-43.848-50.77-98.078-49.617-153.46 2.3086-95.77-2.3086-275.77-72.691-410.77v-1.1523l-99.23-255c-5.7695-15-23.078-23.078-39.23-17.309-6.9219 2.3086-11.539 6.9219-15 12.691v51.922l33.461 253.85c3.4609-1.1523 8.0781-1.1523 11.539-1.1523 36.922 0 68.078 30 68.078 68.078v258.46c0 6.9219-4.6172 11.539-11.539 11.539s-11.539-4.6172-11.539-11.539v-258.46c1.1523-24.23-17.309-45-42.691-47.309-24.23-1.1523-45 17.309-47.309 42.691v4.6172 458.08s10.383 99.23 77.309 150l1.1523 1.1523 109.62 106.15 180-144.23z" />
        <path d="m561.92 394.62h-1.1523c-4.6172-2.3086-10.383-3.4609-16.152-3.4609-24.23 0-45 19.617-43.848 45v257.31c0 6.9219-4.6172 11.539-11.539 11.539s-11.539-4.6172-11.539-11.539v-257.31c0-36.922 30-66.922 66.922-68.078 3.4609 0 8.0781 0 11.539 1.1523l32.309-253.84v-53.074c-3.4609-5.7695-9.2305-10.383-16.152-12.691-15-5.7695-32.309 2.3086-38.078 17.309l-98.078 256.15v1.1523c-70.383 135-76.152 315-72.691 410.77 1.1523 55.383-16.152 109.62-49.617 153.46l-92.309 118.85 181.15 144.23 109.62-106.15 1.1523-1.1523c66.922-51.922 77.309-151.15 77.309-151.15l0.003906-456.93c-2.3086-18.461-12.691-34.613-28.848-41.535z" />
      </g>
    </Icon>
  );
};
export const PrayedHandsIcon = props => {
  return (
    <Icon viewBox="0 0 1200 1200" {...props}>
      <g fill="currentColor">
        <path d="m492 946.37v157.63h-252v-143.99l-107.5-120.57c-24.719-27.73-37.777-63.961-36.422-101.09l12.445-339.83c-0.22656-25.727 19.066-47.438 44.641-50.219 26.004-2.832 50.398 14.711 54.648 40.523 6.5391 39.73 14.172 144.31 18.109 202.39-19.645 28.906-25.477 66.504-12.109 100.89l31.766 81.602c5.293 13.586 20.688 20.82 34.945 15.359 13.906-5.4102 20.773-21.059 15.359-34.98 1.9922 5.1133-29.219-75.086-31.738-81.562-9.9609-25.691 1.1641-56.746 27.203-69.434 26.699-13.055 60.551-1.332 73.586 25.273l101.41 207.21c16.879 34.488 25.652 72.387 25.652 110.79z" />
        <path d="m733.66 835.59 101.41-207.21c13.02-26.617 46.883-38.34 73.586-25.273 24.793 12.07 36.602 41.125 27.887 67.02-2.5078 7.4648-33.828 87.59-32.426 83.977-5.4102 13.922 1.4648 29.566 15.359 34.98 14.27 5.4609 29.652-1.7773 34.945-15.359l31.766-81.602c13.355-34.391 7.5352-71.988-12.109-100.89 3.9375-58.078 11.566-162.65 18.109-202.39 4.2461-25.812 28.645-43.355 54.648-40.523 25.57 2.7969 44.867 24.504 44.641 50.219l12.434 339.82c1.3555 37.129-11.688 73.355-36.422 101.09l-107.48 120.58v143.99h-252v-157.63c0-38.398 8.7734-76.297 25.656-110.78z" />
        <path d="m762 96c-62.914 0-119.02 38.23-146.51 87.254-6.9102 12.312-24.086 12.312-30.996 0-27.48-49.023-83.582-87.254-146.5-87.254-89.473 0-162 74.207-162 165.74 0 243.24 304.27 446.26 324 446.26s324-203.02 324-446.26c0-91.535-72.527-165.74-162-165.74zm-54 306c0 9.9375-8.0625 18-18 18h-54v126c0 9.9375-8.0625 18-18 18h-36c-9.9375 0-18-8.0625-18-18v-126h-54c-9.9375 0-18-8.0625-18-18v-36c0-9.9492 8.0625-18 18-18h54v-54c0-9.9492 8.0625-18 18-18h36c9.9375 0 18 8.0508 18 18v54h54c9.9375 0 18 8.0508 18 18z" />
      </g>
    </Icon>
  );
};
