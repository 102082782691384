import React, { useCallback, useEffect, useState } from 'react';
import { IPrayerRoute } from 'app/shared/model/prayer-route.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import EarmarkedPrayerRoutesGeoJsonLayer from 'app/modules/map/layers/earmarked-routes-geo-json-layer';
import MyCompletedPrayerRoutes from 'app/modules/map/layers/my-routes-geo-json-layer';
import AllPrayerRoutesVectorLayer from 'app/modules/map/layers/all-prayer-routes-vector-layer';
import { newFeatureCollection } from 'app/modules/map/utils';
import { Feature } from 'geojson';
import { IMapSettersProps } from 'app/entities/map/map-home';
import { IPopupInfo, PrayerRoutePopup } from 'app/entities/map/layers/prayer-route-popup';
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { useSelectedPrayerRouteId } from 'app/shared/hooks/useSelectedPrayerRouteId';

export const CoverOnceMapLayers = (props: IMapSettersProps) => {
  const prayerWalkEvent = useCurrentEvent();
  const tileUrl = useAppSelector(state => state.applicationProfile.tilesEndpoint);
  const mapLegendState = useAppSelector(state => state.mapLegendState);
  const prayerRoutes = useAppSelector(state => state.prayerRoute.userEntities) as IPrayerRoute[];
  const [localInteractiveLayers, setLocalInteractiveLayers] = useState<string[]>([]);
  const navigate = useNavigate();
  const selectedPrayerRouteId = useSelectedPrayerRouteId();
  const { setInteractiveLayerIds, removeInteractiveLayerIds, addHandlerForLayerClick, removeHandlerForLayerClick } = props;

  const earmarkedRoutesPresent = prayerRoutes.length > 0 && prayerRoutes.some(prayerRoute => prayerRoute.completed === false);

  useEffect(() => {
    const interactiveLayerIds = [];
    const completedRoutesPresent = prayerRoutes.length > 0 && prayerRoutes.some(prayerRoute => prayerRoute.completed === true);
    if (mapLegendState.isMyRoutesChecked) {
      completedRoutesPresent && interactiveLayerIds.push('highlighted-roads-my-completed-routes');
    }
    if (mapLegendState.isHeatmapChecked) {
      interactiveLayerIds.push('highlighted-roads-earmarked-routes-main-page-v5');
      interactiveLayerIds.push('highlighted-roads-completed-routes-main-page-v5');
    }
    earmarkedRoutesPresent && interactiveLayerIds.push('highlighted-roads-my-earmarked-routes');
    setLocalInteractiveLayers(interactiveLayerIds);
    setInteractiveLayerIds(interactiveLayerIds);
    return () => removeInteractiveLayerIds(interactiveLayerIds);
  }, [prayerRoutes, setInteractiveLayerIds, mapLegendState, earmarkedRoutesPresent]);

  useEffect(() => {
    const handler = (feature: any, lngLat: mapboxgl.LngLat) => {
      let route = prayerRoutes.find(r => {
        return feature.properties.prayer_route_id === r.id;
      });
      if (!route) {
        // when using vector tiles create prayer route
        route = {
          id: feature.properties.prayer_route_id,
          route: newFeatureCollection([feature]),
          completed: feature.properties.completed,
          completedAt: feature.properties.completed_at,
        };
      }
      navigate(`prayer-route-selected/${route.id}`, {
        state: { longitude: lngLat.lng, latitude: lngLat.lat, roadName: feature.properties.name },
      });
    };
    localInteractiveLayers.forEach(layerId => addHandlerForLayerClick(layerId, handler));
    return () => {
      localInteractiveLayers.forEach(layerId => removeHandlerForLayerClick(layerId));
    };
  }, [prayerRoutes, localInteractiveLayers]);

  return (
    <>
      <EarmarkedPrayerRoutesGeoJsonLayer
        prayerRoutes={prayerRoutes}
        earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
        selectedPrayerRouteId={selectedPrayerRouteId}
        beforeLayerId="z4"
      />
      {mapLegendState.isMyRoutesChecked && (
        <MyCompletedPrayerRoutes
          prayerRoutes={prayerRoutes}
          routeColour={prayerWalkEvent.settings.colours.completedColour.code}
          selectedPrayerRouteId={selectedPrayerRouteId}
          beforeLayerId="z3"
        />
      )}
      {mapLegendState.isHeatmapChecked && (
        <>
          <AllPrayerRoutesVectorLayer
            id="main-page-v5"
            sourceLayer="public.prayer_routes_v5"
            tileUrl={`${tileUrl}/public.prayer_routes_v5/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`}
            selectedPrayerRouteId={selectedPrayerRouteId}
            completedRouteColour={prayerWalkEvent.settings.colours.completedColour.code}
            earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
            beforeLayerId="z2"
          />
          {/* to continue showing all the old maps */}
          <AllPrayerRoutesVectorLayer
            id="main-page"
            sourceLayer="public.prayer_routes"
            tileUrl={`${tileUrl}/public.prayer_routes/{z}/{x}/{y}.pbf?prayer_walk_event_identifier=${prayerWalkEvent.id}`}
            selectedPrayerRouteId={selectedPrayerRouteId}
            completedRouteColour={prayerWalkEvent.settings.colours.completedColour.code}
            earmarkedRouteColour={prayerWalkEvent.settings.colours.earmarkedColour.code}
            beforeLayerId="z2"
          />
        </>
      )}
    </>
  );
};
