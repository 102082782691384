import { Box, HStack } from '@chakra-ui/react';
import React from 'react';

export const BottomCardScroller = props => {
  return (
    <Box id="bottom-card-scroller" bg="transparent" pos="absolute" bottom="0" left="0" w="full" p={2} zIndex={10} overflowX="scroll">
      <HStack spacing={2} w="fit-content" alignItems="end">
        {props.children}
      </HStack>
    </Box>
  );
};
